import {FC, PropsWithChildren} from 'react'
import {AppMenuDrawer} from './AppMenuDrawer'
import {AppMenuContextProvider} from '../../context/AppMenuContext'
import {AppMenuContent} from './AppMenuContent'

export const AppMenuWrapper: FC<PropsWithChildren> = ({
    children
}) => (
    <AppMenuContextProvider>
        <AppMenuDrawer/>
        <AppMenuContent>
            {children}
        </AppMenuContent>
    </AppMenuContextProvider>
)