import {FC, ReactElement} from 'react'
import {AppMenuItem} from '../../types/AppMenu'
import {useAppMenuContext} from '../../context/AppMenuContext'
import {Tooltip} from '@mui/material'

type Props = {
    item: AppMenuItem
    children: ReactElement
}

export const AppMenuTooltipWrapper: FC<Props> = ({
    children,
    item
}) => {
    const { isOpen } = useAppMenuContext()

    return isOpen 
        ? <>{children}</>
        : <Tooltip title={item.title}>{children}</Tooltip>
}