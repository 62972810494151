import {ChangeEvent, useState, MouseEvent} from 'react'
import {Box, Button, TextField, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import './AiModelsControlEdit.scss'
import {AIModel, OPEN_SOURCE_MODELS} from '../../../../types/AiModel'
import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {useFeedbackContext} from '../../../../context/FeedbackContext'

type AiModelsControlEditProps = {
    aiModel: AIModel
}

export const AiModelsControlEdit = ({aiModel}: AiModelsControlEditProps) => {

    const {toggleDrawer} = useToggleDrawerContext()
    const {updateAiModelConfig} = useAiModelsContext()
    const {showFeedback} = useFeedbackContext()

    const [apiKeyEditModel, setApiKeyEditModel] = useState<string>('')
    const [editNameModel, setEditNameModel] = useState<string>(aiModel.name)

    const updateAiModelEditHandler = (event: MouseEvent<HTMLAnchorElement> | MouseEvent<HTMLButtonElement>) => {
        if (editNameModel) {
            updateAiModelConfig(apiKeyEditModel, editNameModel, true, aiModel!.id).then(() => {
                showFeedback('Success', 'The AI model has been successfully updated.', 'success')
            }).catch(() => {
                showFeedback('Error', 'The AI model couldn\'t be updated, please try again.', 'error')
            }).finally(() => {
                toggleDrawer(false, 'EDIT_AI_MODEL_DRAWER')(event)
            })
        }
    }

    const isOpenSourceSelectedModel = aiModel && OPEN_SOURCE_MODELS.map(model => model.id).includes(aiModel.id)

    return <Box className='aiModelConfig'>
        <Box className='aiModelConfigHeader'>
            <Typography variant='h3'>Configuration</Typography>
            <CloseIcon onClick={toggleDrawer(false, 'EDIT_AI_MODEL_DRAWER')}/>
        </Box>
        <Typography variant='body2'>Complete the fields to connect the AI model</Typography>
        <TextField fullWidth label='Name' variant='outlined' className='textFieldInput configDrawer'
                   value={editNameModel}
                   onChange={(event: ChangeEvent<HTMLInputElement>) => setEditNameModel(event?.target?.value ?? '')}/>
        {!isOpenSourceSelectedModel && <TextField fullWidth label='API KEY' variant='outlined' className='textFieldInput configDrawer'
                   onChange={(event: ChangeEvent<HTMLInputElement>) => setApiKeyEditModel(event?.target?.value ?? '')}/>}
        <Box className='saveAiModelButtonContainer'>
            <Button onClick={(event) => updateAiModelEditHandler(event)}>Save</Button>
        </Box>
    </Box>
}