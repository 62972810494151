import {useCallback, useEffect, useMemo, useState} from 'react'
import {Prompt} from '../types/Prompt'
import {PromptsContextValue} from '../context/PromptsContext'
import {getFavouritesPromptsByUser, getLabels, getPrompt, getPrompts, getPromptsByUser, getTeams, listRecentlyUsedPromptByUser} from '../service/persistenceService'
import {useUserContext} from '../context/UserContext'
import {SelectOption} from '../types/SelectOption'
import {ListPromptsResponse} from '../types/ListPromptsResponse'
import {useErrorBoundary} from 'react-error-boundary'
import {createSelectOption} from '../helpers/LabelHelpers'
import {PROMPT_TEMPLATES} from '../constants/PromptTemplates'

export const usePrompts = (): PromptsContextValue => {

    const {token} = useUserContext()
    const {showBoundary} = useErrorBoundary()

	const [prompts, setPrompts] = useState<Prompt[]>([])
	const [lastEvaluatedKey, setLastEvaluatedKey] = useState<string | undefined>(undefined)
	const [labels, setLabels] = useState<SelectOption[]>([])
	const [teams, setTeams] = useState<SelectOption[]>([])
	const [promptsLoading, setPromptsLoading] = useState(false)
	const [initLoadLaunched, setInitLoadLaunched] = useState(false)
	const [recentlyUsedPrompts, setRecentlyUsedPrompts] = useState<Prompt[]>([])
	const [favourites, setFavourites] = useState<Prompt[]>([])
	const [userPrompts, setUserPrompts] = useState<Prompt[]>([])

	const collectLabels = useCallback((labels: { label: string }[]) => {
		if (labels && labels.length > 0) {
			const uniqueLabels = [...new Set(labels.map(labelInfo => labelInfo.label))]
			const labelSelectOptions = uniqueLabels
				.filter(label => label?.length > 0)
				.map(createSelectOption)
			setLabels(labelSelectOptions)
		}
	}, [])

	const collectTeams = useCallback((teams: { team: string }[]) => {
		if (teams && teams.length > 0) {
			const uniqueTeams = [...new Set(teams.map(teamInfo => teamInfo.team))]
			const teamsSelectOptions = uniqueTeams
				.filter(team => team?.length > 0)
				.map(createSelectOption)
			setTeams(teamsSelectOptions)
		}
	}, [])

	const getNextPrompts = useCallback(() => {
		if (!token) return
		setPromptsLoading(true)
		getPrompts(token, lastEvaluatedKey).then(({prompts, lastEvaluatedKey}: ListPromptsResponse) => {
			setPrompts(previousPrompts => [...previousPrompts, ...prompts])
			setLastEvaluatedKey(lastEvaluatedKey)
			setPromptsLoading(false)
		}).catch(showBoundary)
	}, [token, lastEvaluatedKey, showBoundary])

	useEffect(() => {
		if (prompts.length === 0 && !initLoadLaunched && token) {
			setInitLoadLaunched(true)
			getNextPrompts()
			getLabels(token).then(collectLabels).catch(showBoundary)
			getTeams(token).then(collectTeams).catch(showBoundary)
			listRecentlyUsedPromptByUser(token).then(setRecentlyUsedPrompts).catch(showBoundary)
			getFavouritesPromptsByUser(token).then(setFavourites).catch(showBoundary)
			getPromptsByUser(token).then(setUserPrompts).catch(showBoundary)
		}
	}, [token, prompts, initLoadLaunched, getNextPrompts, setFavourites, showBoundary, collectLabels, collectTeams])

	const getPromptById = useCallback(async (promptId: string): Promise<Prompt | undefined> => {
        try {
            const prompt = await getPrompt(promptId, token)
            if (prompt) {
                setPrompts(previousPrompts => [...new Set([...previousPrompts, prompt])])
            }
            return prompt
        } catch (error) {
            showBoundary(error)
        }
	}, [token, showBoundary])

	const getPromptsBySelectedLibrary = useCallback((library: 'My prompts' | 'Public prompts' | 'Templates'): Prompt[] => {
		switch (library) {
			case 'My prompts':
				return userPrompts
			case 'Public prompts':
				return prompts.filter(prompt => prompt.isPublic)
			case 'Templates':
				return PROMPT_TEMPLATES
			default:
				return []
		}
	}, [prompts, userPrompts])

	return useMemo(() => ({
		prompts, setPrompts,
		favourites, setFavourites,
		userPrompts, setUserPrompts,
		recentlyUsedPrompts, setRecentlyUsedPrompts,
		promptsLoading, setPromptsLoading,
		labels, setLabels,
		teams, setTeams,
		lastEvaluatedKey, setLastEvaluatedKey,
		getNextPrompts,
		getPromptById,
		getPromptsBySelectedLibrary
	}), [
		prompts,
		favourites,
		userPrompts,
		recentlyUsedPrompts,
		promptsLoading,
		labels,
		teams,
		lastEvaluatedKey,
		getNextPrompts,
		getPromptById,
		getPromptsBySelectedLibrary
	])
}