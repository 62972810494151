import {FC} from 'react'
import {Box, Typography} from '@mui/material'
import {AuditWarning} from '../../../../cdk/src/types/AuditPromptInfoStored'
import './AuditingLogDetailPromptWarnings.scss'

type PromptWarningByLevelProps = {
	warnings: AuditWarning[]
}

export const AuditingLogDetailPromptWarnings: FC<PromptWarningByLevelProps> = ({warnings}) => {
	const highWarnings = warnings.filter(warning => warning.level === 'high')
	const mediumWarnings = warnings.filter(warning => warning.level === 'medium')
	const lowWarnings = warnings.filter(warning => warning.level === 'low')

	return <Box className='AuditingLogDetailPromptWarnings'>
		<Typography className='AuditingLogDetailPromptWarnings_Title'>
			<span>Alert reason:</span> { warnings.length === 1 ? <span className='AuditingLogDetailPromptWarnings_Reason'>{warnings[0].name}</span> : <></>}
		</Typography>
		{ warnings.length !== 1 ? <Box className='AuditingLogDetailPromptWarnings_WarningContainer'>
			{ highWarnings.length ? <Box>
				<span className='AuditingLogDetailPromptWarnings_WarningTitle high'>High:</span> <span className='AuditingLogDetailPromptWarnings_Reason'>{highWarnings.map(warning => warning.name).join(', ')}</span>
			</Box> : <></> }
			{ mediumWarnings.length ? <Box>
				<span className='AuditingLogDetailPromptWarnings_WarningTitle medium'>Medium:</span> <span className='AuditingLogDetailPromptWarnings_Reason'>{mediumWarnings.map(warning => warning.name).join(', ')}</span>
			</Box> : <></> }
			{ lowWarnings.length ? <Box>
				<span className='AuditingLogDetailPromptWarnings_WarningTitle low'>Low:</span> <span className='AuditingLogDetailPromptWarnings_Reason'>{warnings.filter(warning => warning.level === 'low').map(warning => warning.name).join(', ')}</span>
			</Box> : <></> }
		</Box> : <></> }
	</Box>		
}