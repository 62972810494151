import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useMemo} from 'react'
import {useChatMessages} from '../hooks/useChatMessages'
import {ChatMessageStored} from '../types/ChatMessageStored'
import {ChatMessage} from '../types/ChatMessage'

interface ChatMessagesContextProviderProps {
    children: ReactNode
}

export interface ChatMessagesContextValue {
	loading: boolean
	chatId: string | undefined
	chatMessages: ChatMessageStored[]
	setChatId: Dispatch<SetStateAction<string | undefined>>
	setChatMessages: Dispatch<SetStateAction<ChatMessageStored[]>>
	getGroupedMessagesProcessed: (groupedMessagesByOrder: {[p: string]: ChatMessageStored[]}) => ChatMessage[]
	groupMessagesByOrder: (chatMessages: ChatMessageStored[]) => {[x: string]: ChatMessageStored[]}
}

const DEFAULT_CHAT_MESSAGES_CONTEXT: ChatMessagesContextValue = {
    loading: false,
    chatId: undefined,
    setChatId: () => {},
    chatMessages: [],
	setChatMessages: () => {},
	getGroupedMessagesProcessed: (groupedMessagesByOrder: {[p: string]: ChatMessageStored[]}) => [],
	groupMessagesByOrder: (chatMessages: ChatMessageStored[]) => ({x: []})
}

export const ChatMessagesContext = createContext<ChatMessagesContextValue>(DEFAULT_CHAT_MESSAGES_CONTEXT)

export const useChatMessagesContext = () => useContext(ChatMessagesContext)

export const ChatMessagesContextProvider = ({children}: ChatMessagesContextProviderProps) => {
	const {
		chatId,
		setChatId,
		chatMessages,
		loading,
		setChatMessages,
		getGroupedMessagesProcessed,
		groupMessagesByOrder
	} = useChatMessages()

	const value: ChatMessagesContextValue = useMemo(() => ({
		chatId, setChatId, chatMessages, loading, setChatMessages, getGroupedMessagesProcessed, groupMessagesByOrder
	}), [chatId, setChatId, chatMessages, loading, setChatMessages, getGroupedMessagesProcessed, groupMessagesByOrder])


    return <ChatMessagesContext.Provider value={value}>
        {children}
    </ChatMessagesContext.Provider>
}