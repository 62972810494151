import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {NAVIGATION_CONFIG_BASES, NavigationConfig} from '../types/AdminNavigation'
import {useLocation, useNavigate} from 'react-router'
import {isBaseConfig} from '../utils/adminNavigationUtils'
import {ADMIN_NAVIGATION_BASE_ROUTER_MAP, ADMIN_NAVIGATION_TAB_MAP} from '../constants/AdminNavigationConstants'

type AdminNavigationContextValue = {
	selectedTab: number
	navigate: (value: NavigationConfig) => void
}

const DEFAULT_ADMIN_NAVIGATION_CONTEXT: AdminNavigationContextValue = {
	selectedTab: 0,
	navigate: () => {}
}

export const AdminNavigationContext = createContext<AdminNavigationContextValue>(DEFAULT_ADMIN_NAVIGATION_CONTEXT)

export const useAdminNavigationContext = () => useContext(AdminNavigationContext)

export const AdminNavigationContextProvider: FC<PropsWithChildren> = ({children}) => {
	const [selectedTab, setSelectedTab] = useState(0)
	const navigate = useNavigate()
	const {pathname} = useLocation()

	const customNavigate = useCallback((config: NavigationConfig) => {
		if (isBaseConfig(config)) {
			setSelectedTab(ADMIN_NAVIGATION_TAB_MAP[config])
			navigate(`/admin${ADMIN_NAVIGATION_BASE_ROUTER_MAP[config]}`)
		} else {
			setSelectedTab(ADMIN_NAVIGATION_TAB_MAP[config.tab])
			navigate(`/admin${ADMIN_NAVIGATION_BASE_ROUTER_MAP[config.tab]}/${config.section}/${config.value}`)
		}
	}, [navigate])

	const value: AdminNavigationContextValue = useMemo(() => ({
		selectedTab,
		navigate: customNavigate
	}), [selectedTab, customNavigate])

	/**
	 * This effect ensures that after browser native back navigation,
	 * then SelectedTab is correctly updated
	 */
	useEffect(() => {
		NAVIGATION_CONFIG_BASES.forEach(baseConfig => {
			if (pathname.startsWith(`/admin${ADMIN_NAVIGATION_BASE_ROUTER_MAP[baseConfig]}`)) {
				const configTab = ADMIN_NAVIGATION_TAB_MAP[baseConfig]
				setSelectedTab(prev => prev === configTab ? prev : configTab)
			}
		})
	}, [pathname])

	return <AdminNavigationContext.Provider value={value}>
		{children}
	</AdminNavigationContext.Provider>
}