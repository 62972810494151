import {
    createContext,
    useContext,
    ReactNode,
    Dispatch,
    SetStateAction,
    FC,
    useMemo,
    useState
} from 'react'
import {DataGridState} from '../types/DataGridState'

interface DataGridContextProviderProps {
    children: ReactNode
}

export interface DataGridContextValue {
    dataGridState: DataGridState
    setDataGridState: Dispatch<SetStateAction<DataGridState>>
}

const DEFAULT_DATA_GRID_STATE: DataGridState = {
    sortModel: [],
    paginationModel: { page: 0, pageSize: 5 },
    filterModel: { items: [] },
}

const DEFAULT_DATA_GRID_CONTEXT: DataGridContextValue = {
    dataGridState: {
        sortModel: [],
        paginationModel: {page: 0, pageSize: 5},
        filterModel: {items: []}
    },
    setDataGridState: (dataGridState: SetStateAction<DataGridState>) => {}
}

export const DataGridContext = createContext<DataGridContextValue>(DEFAULT_DATA_GRID_CONTEXT)

export const useDataGridContext = () => useContext(DataGridContext)

export const DataGridContextProvider: FC<DataGridContextProviderProps> = ({children}) => {

    const [dataGridState, setDataGridState] = useState<DataGridState>(DEFAULT_DATA_GRID_STATE)

    const value: DataGridContextValue = useMemo(() => ({
            dataGridState,
            setDataGridState
        }),
        [dataGridState])

    return <DataGridContext.Provider value={value}>
        {children}
    </DataGridContext.Provider>
}