import {AwsMarketplaceCustomerData} from '../types/AwsMarketplaceCustomerData'
import { jwtDecode } from 'jwt-decode'
export const validateAwsMarketplaceCustomerSignature = (token: string): AwsMarketplaceCustomerData => {
	if (!token) return {}
	try {
		return jwtDecode(token) as AwsMarketplaceCustomerData
	} catch (error) {
		console.error('error verifying the token', error)
		return {}
	}
}