import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {Budget, ClosedBudget} from '../types/Budget'
import {closeBudget, createBudget, deleteBudget, getClosedBudgets, getOverallBudget, updateBudget} from '../service/persistenceService'
import {useUserContext} from './UserContext'
import {useFeedbackContext} from './FeedbackContext'

export interface BudgetsContextValue {
	loading: boolean
	loadingClosedBudgets: boolean
	isOverallBudgetFinished: boolean
	overallBudget: Budget | undefined
	teamBudgets: Budget[]
	closedBudgets: ClosedBudget[]
	saveBudget: (budget: Partial<Budget>) => Promise<Budget | undefined>,
	updateBudgetAmount: (budget: Budget, amount: number) => Promise<Budget | undefined>
	closeCurrentBudget : () => Promise<Budget | undefined>
	removeBudget: (budget: Budget) => void
}

const DEFAULT_BUDGETS_CONTEXT: BudgetsContextValue = {
	loading: false,
	loadingClosedBudgets: false,
	isOverallBudgetFinished: false,
	overallBudget: undefined,
	teamBudgets: [],
	closedBudgets: [],
	saveBudget: () => Promise.resolve(undefined),
	updateBudgetAmount: () => Promise.resolve(undefined),
	removeBudget: () => {},
	closeCurrentBudget: () => Promise.resolve(undefined),
}

export const BudgetsContext = createContext<BudgetsContextValue>(DEFAULT_BUDGETS_CONTEXT)

export const useBudgetsContext = () => useContext(BudgetsContext)

export const BudgetsContextProvider: FC<PropsWithChildren> = ({children}) => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [overallBudget, setOverallBudget] = useState<Budget>()
	const [teamBudgets, setTeamBudgets] = useState<Budget[]>([])
	const [closedBudgets, setClosedBudgets] = useState<ClosedBudget[]>([])
	const [loading, setLoading] = useState(false)
	const [loadingClosedBudgets, setLoadingClosedBudgets] = useState(false)
	const [isOverallBudgetFinished, setIsOverallBudgetFinished] = useState(false)

	const fetchClosedBudgets = useCallback(() => {
		setLoadingClosedBudgets(true)
		getClosedBudgets(token)
			.then(budgets => {
				setClosedBudgets(budgets)
			})
			.finally(() => setLoadingClosedBudgets(false))
	}, [token])

	const saveBudget = useCallback((budget: Partial<Budget>): Promise<Budget | undefined> => {
		return createBudget(token, budget).then(budget => {
			if (budget.type === 'Organization') setOverallBudget(budget)
			else setTeamBudgets(previousTeamBudgets => [...previousTeamBudgets, budget])
			showFeedback('Budget created', '', 'success')
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to create a budget. Try again reloading the page', 'error')
				return undefined
			})
	}, [token, showFeedback])

	const updateBudgetAmount = useCallback(({hashKey}: Budget, amount: number): Promise<Budget | undefined> => {
		return updateBudget(token, hashKey, amount).then(budget => {
			if (budget.type === 'Organization') setOverallBudget(budget)
			else setTeamBudgets(previousTeamBudgets => [...previousTeamBudgets, budget])
			showFeedback('Budget updated', '', 'success')
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to update the budget. Try again reloading the page', 'error')
				return undefined
			})
	}, [token, showFeedback])

	const closeCurrentBudget = useCallback( (): Promise<Budget | undefined> => {
		if (!overallBudget) return Promise.resolve(undefined)
		return closeBudget(token, overallBudget.hashKey).then(budget => {
			setOverallBudget(undefined)
			showFeedback('Budget closed', '', 'success')
			fetchClosedBudgets()
			return budget
		})
			.catch(() => {
				showFeedback('Error', 'Something went wrong to close the current budget. Try again reloading the page', 'error')
				return undefined
			})
	}, [overallBudget, token, showFeedback, fetchClosedBudgets])

	useEffect(() => {
		setLoading(true)
		getOverallBudget(token)
			.then(overallBudget => {
				setOverallBudget(overallBudget)
				setIsOverallBudgetFinished(overallBudget ? overallBudget?.spent > overallBudget?.amount : false)
			})
			.finally(() => setLoading(false))
		fetchClosedBudgets()
	}, [token, showFeedback, fetchClosedBudgets])

	const removeBudget = useCallback(({hashKey}: Budget): void => {
		deleteBudget(token, hashKey).then(budget => {
			setOverallBudget(undefined)
			showFeedback('Budget removed', '', 'success')
		}).catch(() => {
			showFeedback('Error', 'Something went wrong trying to remove the budget. Try again reloading the page', 'error')
			return undefined
		})
	}, [showFeedback, token])

	const value: BudgetsContextValue = useMemo(() => ({
			loading, loadingClosedBudgets, isOverallBudgetFinished, overallBudget, teamBudgets, closedBudgets,
			saveBudget, updateBudgetAmount, closeCurrentBudget, removeBudget}),
		[loading, loadingClosedBudgets, isOverallBudgetFinished, overallBudget, teamBudgets, closedBudgets,
			saveBudget, updateBudgetAmount, closeCurrentBudget, removeBudget])

	return <BudgetsContext.Provider value={value}>
		{children}
	</BudgetsContext.Provider>
}