import {useCallback, useMemo, useState} from 'react'
import {AnalyticsEvent} from '../types/AnalyticsEvent'
import {v4 as uuidv4} from "uuid";
import {sendMarketingCommsEvent} from '../service/persistenceService'
import {TrialContextValue} from '../context/TrialContext'
import {useUserContext} from '../context/UserContext'
import {useUser} from '@clerk/clerk-react'

export const useTrial = (): TrialContextValue => {

    const {token} = useUserContext()
    const {user} = useUser()
    const [isMarketingEventSent, setIsMarketingEventSent] = useState<boolean>(false)

    const trackMarketingEvent = useCallback(async () => {
        const storedCheckboxState = localStorage.getItem('marketingCommsChecked')
        const isChecked = storedCheckboxState ? JSON.parse(storedCheckboxState) : false
        const eventData: AnalyticsEvent = {
            id: uuidv4(),
            event_type: 'opt_in',
            opt_in: isChecked ? 'yes' : 'no',
            email: user?.primaryEmailAddress?.emailAddress ?? ''
        }

        await sendMarketingCommsEvent(token, eventData)
            .then(() => {
                localStorage.removeItem('marketingCommsChecked')
                setIsMarketingEventSent(true)
            })
            .catch(() => {
                console.error('Error sending the event to HS')
            })
    }, [user?.primaryEmailAddress?.emailAddress, token])

    return useMemo(() => ({
        isMarketingEventSent, trackMarketingEvent
    }), [isMarketingEventSent, trackMarketingEvent])
}