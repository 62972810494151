import {Alert} from '@mui/material'
import './NoBudgetUserAlert.scss'
import {useUserBudgetContext} from '../../context/UserBudgetContext'
import {useLocation} from 'react-router-dom'

export const NoBudgetUserAlert = () => {

    const {hasBudgetRemaining} = useUserBudgetContext()
    const location = useLocation()

    if (hasBudgetRemaining) return <></>

    return <Alert severity='error' className='noBudgetUserBanner'>
        {location.pathname.startsWith('/aiChat') ? 'Your team has run out of budget, it is not possible to send a prompt. Please contact your administrator to request more budget.' : 'No prompts left as budget spent. Wait until budget renews or contact admin.'}
    </Alert>
}