import React from 'react'
import {Box, Grid, IconButton} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import {FormatWarning} from '../../../../types/Warning'
import './Warnings.scss'
import Switch from '@mui/material/Switch'
import {FormatItemIcon} from './FormatItemIcon'

export interface FormatItemProps {
	warning: FormatWarning
	handleEditClicked: (event: React.MouseEvent<HTMLButtonElement>, warning: FormatWarning) => void
	handleEnableClicked: (warning: FormatWarning) => void
}

export const FormatItem = ({warning, handleEditClicked, handleEnableClicked}: FormatItemProps) => {

	return <Grid item xs={12}>
		<Box className={`warningItemRow ${!warning.enabled ? 'formatWarningDisabled' : ''}`}>
			<Box className='warningName formatWarningName'>
				<FormatItemIcon format={warning.format.toLowerCase()}/>
				{warning.format}
			</Box>
			<Box className='warningLevel'>Warning level: <span className={`name ${warning.level}`}>{warning.level}</span></Box>
			<IconButton
				onClick={(event) => handleEditClicked(event, warning)}
				onMouseDown={(event) => handleEditClicked(event, warning)}
				edge='end'
				disabled={!warning.enabled}
				className='actionIcon'
			>
				<SettingsIcon/>
			</IconButton>
			<Switch checked={warning.enabled} className='enableFormatItem' onClick={() => handleEnableClicked(warning)}/>
		</Box>
	</Grid>
}