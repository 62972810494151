import {useChatMessagesContext} from '../../context/ChatMessagesContext'
import {useCallback, useEffect} from 'react'
import {AiChat} from '../aiChat/AiChat'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {useParams} from 'react-router-dom'
import {useChatsContext} from '../../context/ChatsContext'

export const SavedChat = () => {

	const params = useParams()
	const {chatId, setChatId, groupMessagesByOrder, getGroupedMessagesProcessed, chatMessages} = useChatMessagesContext()
	const {chatsByUser, chat, setChat, loadValuesFromChat} = useChatsContext()
	const {setModelIds, setPromptChats} = usePromptCreationContext()

	const loadPreviousMessages = useCallback(() => {
		if (chat) {
			const { modelId } = chat
			setModelIds([modelId])
	
			const groupedMessagesByOrder = groupMessagesByOrder(chatMessages)
			const groupedMessagesProcessed = getGroupedMessagesProcessed(groupedMessagesByOrder)
			const messages: PromptChatDetail[] = [{modelId, messages: groupedMessagesProcessed, compilationSummary: { summary: ''}}]
			setPromptChats(messages)
		}
	}, [setModelIds, setPromptChats, chat, chatMessages, groupMessagesByOrder, getGroupedMessagesProcessed])

	useEffect(() => {
		setChatId(params.chatId)
		setChat(chatsByUser.find(chat => chat.chatId === chatId))
		loadValuesFromChat()
		loadPreviousMessages()
	}, [chatId, params.chatId, chatsByUser, loadPreviousMessages, setChatId, setChat, loadValuesFromChat])

	return <AiChat skipInitialExecution={true}/>
}