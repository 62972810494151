import {createContext, Dispatch, ReactNode, SetStateAction, useContext} from 'react'
import {ChatFieldName, ChatIDsByModel, useChats} from '../hooks/useChats'
import {Chat} from '../types/Chat'
import {ChatMessage} from '../types/ChatMessage'
import {AIModelID} from '../types/AiModel'

interface ChatsContextProviderProps {
	children: ReactNode
}

export interface ChatsContextValue {
	loading: boolean
	saveChat: (chat: Chat, tempPromptId: string) => Promise<Chat | undefined>
	editChat: (chatToEdit: Chat) => Promise<Chat | undefined>
	chat: Chat | undefined
	setChat: Dispatch<SetStateAction<Chat | undefined>>
	setChatValueHandler: (set: Dispatch<SetStateAction<any>>, value: any, fieldName: ChatFieldName) => void
	chatName: string
	setChatName: Dispatch<SetStateAction<string>>
	chatDescription: string
	setChatDescription: Dispatch<SetStateAction<string>>
	chatLabels: string[]
	chatsByUser: Chat[]
	setChatLabels: Dispatch<SetStateAction<string[]>>
	loadValuesFromChat: () => void
	clearChatFields: () => void
	clearChatData: () => void
	deleteChat: (chatId: string) => Promise<void>
	getChatTemplate: (messages: ChatMessage[], modelId: AIModelID) => Chat
	chatIdsForCurrentConversation: ChatIDsByModel[]
}

const DEFAULT_CHATS_CONTEXT: ChatsContextValue = {
	loading: false,
	saveChat: () => Promise.resolve(undefined),
	editChat: () => Promise.resolve(undefined),
	chat: undefined,
	setChat: () => {},
	setChatValueHandler: () => {},
	chatName: '',
	setChatName: () => {},
	chatDescription: '',
	setChatDescription: () => {},
	chatLabels: [],
    chatsByUser: [],
	setChatLabels: () => {},
	loadValuesFromChat: () => {},
	clearChatFields: () => {},
	clearChatData: () => {},
	deleteChat: () => Promise.resolve(undefined),
	getChatTemplate: () => ({
		chatId: '',
		messages: [],
		modelId: AIModelID.GPT_3_5,
		chatOwnerId: '',
		name: '',
		description: '',
		labels: []
	}),
	chatIdsForCurrentConversation: []
}

export const ChatsContext = createContext<ChatsContextValue>(DEFAULT_CHATS_CONTEXT)

export const useChatsContext = () => useContext(ChatsContext)

export const ChatsContextProvider = ({children}: ChatsContextProviderProps) => {
	const value = useChats()

	return <ChatsContext.Provider value={value}>
		{children}
	</ChatsContext.Provider>
}