import React, {useState} from 'react'
import {Box, Grid, IconButton} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {TopicWarning, WarningElement, WordWarning} from '../../../../types/Warning'
import {getWarningContent} from '../../../../helpers/WarningHelpers'
import './Warnings.scss'

export interface WarningItemProps {
	warning: WordWarning | TopicWarning
	handleEditClicked: (event: React.MouseEvent<HTMLButtonElement>, warning: WarningElement) => void
	handleDeleteClicked: (warning: WarningElement) => void
	deleteEventName: 'topicWarningDeletion' | 'wordWarningDeletion'
}

export const WarningItem = ({warning, handleDeleteClicked, handleEditClicked, deleteEventName}: WarningItemProps) => {

	const [showDeleteDomainDialog, setShowDeleteDomainDialog] = useState<boolean>(false)

	const handleDelete = () => {
		setShowDeleteDomainDialog(false)
		handleDeleteClicked(warning)
	}

	return <Grid item xs={12}>
		<Box className='warningItemRow'>
			<Box className='warningName'> {getWarningContent(warning)} </Box>
			<Box className='warningLevel'>Warning level: <span className={`name ${warning.level}`}>{warning.level}</span></Box>
			<IconButton
				onClick={(event) => handleEditClicked(event, warning)}
				onMouseDown={(event) => handleEditClicked(event, warning)}
				edge='end'
				className='actionIcon'
			>
				<SettingsIcon/>
			</IconButton>
			<IconButton
				onClick={() => setShowDeleteDomainDialog(true)}
				onMouseDown={() => setShowDeleteDomainDialog(true)}
				edge='end'
				className='actionIcon'
			>
				<DeleteIcon/>
			</IconButton>
			<ConfirmationDialog handleClose={() => setShowDeleteDomainDialog(false)} open={showDeleteDomainDialog}
			                    handleDelete={handleDelete}
			                    currentEvent={deleteEventName}/>
		</Box>
	</Grid>
}