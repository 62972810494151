export const NAVIGATION_CONFIG_BASES = ['overview','logs','configuration'] as const

export type NavigationConfigBase = typeof NAVIGATION_CONFIG_BASES[number]

export type NavigationConfigDeep = {
    tab: NavigationConfigBase,
    section: string,
    value: string
}

export type NavigationConfig = NavigationConfigBase | NavigationConfigDeep
