import {FC, useState} from 'react'
import {UserPromptAudit} from '../../../types/UserAuditLog'
import {useWarningsContext} from '../../../context/WarningsContext'
import {isAuditWarningRemovedOrEdited} from '../../../utils/userAuditLogUtils'
import {Alert} from '@mui/material'
import './AuditingLogDetailPromptInfo.scss'

type AuditingLogDetailPromptInfoProps = {
    promptAudit: UserPromptAudit
}

export const AuditingLogDetailPromptInfo: FC<AuditingLogDetailPromptInfoProps> = ({
    promptAudit
}) => {
    const { formatWarnings, topicWarnings, wordWarnings } = useWarningsContext()
    const [visible, setVisible] = useState(true)

    const { warnings } = promptAudit
    const groupedWarnings = [...formatWarnings, ...topicWarnings, ...wordWarnings]

    const filteredWarnings = warnings.filter(isAuditWarningRemovedOrEdited(groupedWarnings))

    return filteredWarnings.length && visible ? <Alert className='AuditingLogDetailPromptInfo' severity='info'  onClose={() => setVisible(false)}>
        { filteredWarnings.map(warning => `The warning ${warning.name} was removed or edited. This report predates this change. This type of alert will not appear in reports generated after this date.`).join('\n')}
    </Alert> : <></>
}