import {createContext, ReactNode, useContext, useMemo} from 'react'
import {OrganizationContextValue, useOrganization} from '../hooks/useOrganization'


interface OrganizationContextProviderProps {
	children: ReactNode
}

const DEFAULT_ORGANIZATION_CONTEXT: OrganizationContextValue = {
	loading: false,
	organization: undefined,
	editOrganization: (organizationName: string, allowedDomains: string[]) => Promise.resolve('')
}

export const OrganizationContext = createContext<OrganizationContextValue>(DEFAULT_ORGANIZATION_CONTEXT)

export const useOrganizationContext = () => useContext(OrganizationContext)

export const OrganizationContextProvider = ({children}: OrganizationContextProviderProps) => {
	const {loading, organization, editOrganization} = useOrganization()

	const value: OrganizationContextValue = useMemo(() => ({loading, organization, editOrganization}),
		[loading, organization, editOrganization])

	return <OrganizationContext.Provider value={value}>
		{children}
	</OrganizationContext.Provider>
}