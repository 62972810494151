import {FC} from 'react'
import {useAuditingLogsContext} from '../../context/AuditingLogsContext'
import {hasViewPending} from '../../utils/userAuditLogUtils'
import ErrorIcon from '@mui/icons-material/Error'
import './ChipAlertsIcon.scss'
import {useAppMenuContext} from '../../context/AppMenuContext'

export const ChipAlertsIcon: FC = () => {
    const {auditingLogs} = useAuditingLogsContext()
    const {isOpen} = useAppMenuContext()
    const pendingLogsLength = auditingLogs.filter(hasViewPending).length

    return pendingLogsLength && !isOpen ? <ErrorIcon className='ChipAlertsIcon' fontSize='small'/> : <></>
}