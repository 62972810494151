import {extractCodeSnippet} from './textUtils'

type FeedbackCallback = () => void

const debounce = (callback: Function, wait: number) => {
    let timeout: ReturnType<typeof setTimeout> | undefined
    return (...args: any[]) => {
        if (timeout) clearTimeout(timeout)
        timeout = setTimeout(() => callback(...args), wait)
    }
}

const processLiElements = () => {
        document.querySelectorAll('li').forEach((li) => {
        if (li.classList.contains('_nestedListItem_1tncs_59') && li.style.listStyleType !== 'none') {
            li.style.listStyleType = 'none'
        }
    })
}

const createSVGCopyIcon = () => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg')
    svg.setAttribute('width', '22')
    svg.setAttribute('height', '23')
    svg.setAttribute('viewBox', '0 0 22 23')
    svg.setAttribute('fill', 'none')

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path')
    path.setAttribute('d', 'M15.1251 1.18164H4.12508C3.11675 1.18164 2.29175 2.00664 2.29175 3.01497V15.8483H4.12508V3.01497H15.1251V1.18164ZM17.8751 4.84831H7.79175C6.78342 4.84831 5.95842 5.67331 5.95842 6.68164V19.515C5.95842 20.5233 6.78342 21.3483 7.79175 21.3483H17.8751C18.8834 21.3483 19.7084 20.5233 19.7084 19.515V6.68164C19.7084 5.67331 18.8834 4.84831 17.8751 4.84831ZM17.8751 19.515H7.79175V6.68164H17.8751V19.515Z')
    path.setAttribute('fill', '#374151')
    path.setAttribute('fill-opacity', '0.75')
    svg.appendChild(path)

    return svg
}

const createHeaderWithButton = (): HTMLDivElement => {
    const header = document.createElement('div')
    const buttonContainer = document.createElement('div')
    const svg = createSVGCopyIcon()

    header.className = 'headerCodeBlock'
    buttonContainer.className = 'copyButtonCodeBlock'
    buttonContainer.appendChild(svg)
    header.appendChild(buttonContainer)
    return header
}

const addClickEventToButton = (codeToCopy: string, onSuccess: FeedbackCallback, onError: FeedbackCallback) => {
    const copyButtonContainer = document.querySelector('.copyButtonCodeBlock')
    copyButtonContainer?.addEventListener('click', () => {
        navigator.clipboard.writeText(extractCodeSnippet(codeToCopy))
            .then(onSuccess)
            .catch(onError)
    })
}

const wrapPreElements = (input: string, onSuccess: FeedbackCallback, onError: FeedbackCallback) => {
    const preElements: NodeListOf<HTMLPreElement> = document.querySelectorAll('pre')

    preElements.forEach(pre => {
        const header = createHeaderWithButton()
        pre.insertBefore(header, pre.firstChild)
        addClickEventToButton(input, onSuccess, onError)
    })
}

export const debouncedFormatCodeBlock = debounce((codeToCopy: string, onSuccess: FeedbackCallback, onError: FeedbackCallback) => wrapPreElements(codeToCopy, onSuccess, onError), 100)
export const debouncedProcessLiElements = debounce(processLiElements, 100)