import {FC} from 'react'
import {useAuditingLogsContext} from '../../context/AuditingLogsContext'
import {hasViewPending} from '../../utils/userAuditLogUtils'
import {Chip} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import './ChipAlerts.scss'
import {useAppMenuContext} from '../../context/AppMenuContext'

export const ChipAlerts: FC = () => {
    const {auditingLogs} = useAuditingLogsContext()
    const {isOpen} = useAppMenuContext()
    const pendingLogsLength = auditingLogs.filter(hasViewPending).length
    const chipText = `Alerts (${pendingLogsLength})`

    return pendingLogsLength && isOpen ? <Chip className='AlertsChip' icon={<ErrorIcon/>} label={chipText}/> : <></>
}