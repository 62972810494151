import {Subscription} from '../types/Stripe'
import {VALID_STATUS_SUBSCRIPTIONS} from '../constants/StripeConstants'

export const findTrialSubscription = (subscriptions: Subscription[]): Subscription | undefined =>
	subscriptions.filter(subscription => subscription.status === 'trialing')?.[0]

export const hasOrganizationValidSubscription = (subscriptions: Subscription[]): boolean =>
	subscriptions.some(subscription => VALID_STATUS_SUBSCRIPTIONS.includes(subscription.status))

export const isOrganizationNotPaidAfterTrial = (subscriptions: Subscription[]): boolean =>
	subscriptions.some(subscription => subscription.status === 'paused')