import {useCallback, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useUser} from '@clerk/clerk-react'
import {Box, Button, Typography} from '@mui/material'
import {InfoScreen} from '../../common/infoScreen/InfoScreen'
import {RoundIcon} from '../../common/roundIcon/RoundIcon'
import {PageView, TrackActionEvent} from '../../../service/SegmentService'
import CheckIcon from '@mui/icons-material/CheckCircle'
import './PaymentSuccess.scss'

export const PaymentSuccess = () => {
    const {user} = useUser()
    const navigate = useNavigate()

    const userId = user?.externalId ?? user?.id

    const handleContinueClick = useCallback(() => {
        TrackActionEvent('Payment', userId, {action: 'success_go_main'})
        navigate('/')
    }, [userId, navigate])

    useEffect(() => {
        PageView('Payment Success')
    }, [])

    return (
        <InfoScreen>
            <Box className='paymentSuccessContainer'>
                <RoundIcon className='paymentSuccessIcon' icon={CheckIcon} size={200} iconSize={150}/>
                <Typography className='paymentSuccessTitle'>Plan successfully purchased</Typography>
                <Typography className='paymentSuccessSubtitle'>Your purchase has been successfully completed, you can now enjoy all the features of Narus.</Typography>
                <Box className='paymentSuccessActions'>
                    <Button variant='contained' onClick={handleContinueClick}>Go to Narus</Button>
                </Box>
            </Box>
        </InfoScreen>
    )
}