import React from 'react'
import '../promptCard/PromptCard.scss'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

interface CardLabelProps {
    label: string
    className?: string
}

interface CardLabelsProps {
    labels: string[] | undefined
    className?: string
    maxElementsToShow?: number
}

export const CardLabel = ({label, className}: CardLabelProps) => <Chip className={`promptCardText promptCardLabel ${className}`} label={label}/>

export const CardLabels = ({labels, className, maxElementsToShow}: CardLabelsProps) => {
    if (!labels?.length) return <></>

    const maxElements = maxElementsToShow || 3

    const firstLabels = labels.slice(0, maxElements).map((label, index) =>
        <CardLabel label={label} className={className} key={`key-${label}-${index}`}/>)

    const restLabels = labels.length > maxElements ? labels.slice(maxElements).join(', \n') : ''
    const moreLabelsTooltip = (
        <Tooltip title={restLabels} placement='top'>
            <span className='promptCardText'>+{labels.length - maxElements} more</span>
        </Tooltip>
    )

    return <>
        {firstLabels}
        {labels.length > maxElements && moreLabelsTooltip}
    </>
}