import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {getAuditingLogs, markAuditingLogAsViewed} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import {UserAuditLog} from '../types/UserAuditLog'
import {DateRange} from 'rsuite/DateRangePicker'
import {convertToStartOfDay} from '../helpers/DateHelpers'
import {AuditingLogsContextValue} from '../context/AuditingLogsContext'
import { update } from '../utils/genericUtils'
import { isAuditLogChanged } from '../utils/userAuditLogUtils'

export const useAuditingLogs = (): AuditingLogsContextValue => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [auditingLogs, setAuditingLogs] = useState<UserAuditLog[]>([])
	const [dateRangeFilter, setDateRangeFilter] = useState<DateRange>()

	const getUsersAuditingLogs = useCallback((dateRangeFilter: DateRange | undefined) => {
		setLoading(true)
		getAuditingLogs(token, dateRangeFilter ? [convertToStartOfDay(dateRangeFilter[0]), dateRangeFilter[1]] : undefined)
			.then((logs: UserAuditLog[]) => {

			setAuditingLogs(logs.sort((logA: UserAuditLog, logB: UserAuditLog) => logB.conversationDate - logA.conversationDate))
		}).catch(error => {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
			setAuditingLogs([])
		}).finally(() => setLoading(false))

	}, [token, showFeedback])

	const viewAuditingLog = useCallback((auditingLog: UserAuditLog) => {
		markAuditingLogAsViewed(token, auditingLog)
			.then(() => {
				const updatedAuditingLog: UserAuditLog = { ...auditingLog, isViewed: true }
				setAuditingLogs(update(updatedAuditingLog, isAuditLogChanged))
			})
			.catch((error) => {
				showFeedback('Error', error.message ?? 'There was an error updating auditing Log.', 'error')
			})
	}, [token, showFeedback])


	useEffect(() => {
		getUsersAuditingLogs(dateRangeFilter)
	}, [getUsersAuditingLogs, dateRangeFilter])

	return useMemo(() => ({
		loading,
		auditingLogs,
		setDateRangeFilter,
		viewAuditingLog
	}), [loading, auditingLogs, viewAuditingLog])
}