import React, {ChangeEvent} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import {GroupAction} from './EditGroupMenu'

interface GroupConfirmationDialogProps {
    onCloseDialog: () => void
    open: boolean
    onConfirmAction: (action: GroupAction) => void
    groupName: string
    action: GroupAction | undefined
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void
    hasError?: boolean
}

const groupDialogsMap = (groupName: string, onCloseDialog: () => void, onConfirmAction: (action: GroupAction) => void) => ({
    remove: {
        title: `Are you sure you want to delete ${groupName}?`,
        text: `Deleting a team cannot be undone. All members of ${groupName} will be no longer assigned to a team.`,
        secondaryAction: onCloseDialog,
        secondaryText: '',
        primaryAction: () => onConfirmAction('REMOVE'),
        primaryText: `Delete ${groupName}`
    },
    rename: {
        title: `${groupName}`,
        text: 'Enter the new name for the team',
        secondaryAction: onCloseDialog,
        secondaryText: 'Cancel',
        primaryAction: () => onConfirmAction('RENAME'),
        primaryText: 'Save'
    }
})

export const GroupConfirmationDialog = ({
                                            onCloseDialog,
                                            open,
                                            onConfirmAction,
                                            groupName,
                                            action,
                                            onChange,
                                            hasError
                                        }: GroupConfirmationDialogProps) => {
    if (!action) return <></>

    const {
        title,
        text,
        secondaryAction,
        secondaryText,
        primaryAction,
        primaryText
    } = groupDialogsMap(groupName, onCloseDialog, onConfirmAction)[action.toLowerCase()]

    return (
        <Dialog onClose={onCloseDialog} open={open} className='dialogContainer'>
            <DialogTitle className='dialogTitle'>
                <span>{title}</span>
                <CloseIcon className='dialogCloseIcon' onClick={onCloseDialog}/>
            </DialogTitle>
            <DialogContent className='dialogContent'>
                <DialogContentText>{text}</DialogContentText>
                {action === 'RENAME' &&
                    <TextField
                        variant='outlined'
                        label='Team name'
                        placeholder='Marketing'
                        fullWidth
                        className='newGroupNameTextfield'
                        error={hasError}
                        onChange={onChange}
                    />
                }
            </DialogContent>
            <DialogActions className='dialogActionsContainer'>
                {action === 'RENAME' &&
                    <Button variant='outlined' className='cancelButton' onClick={secondaryAction}>
                        {secondaryText}
                    </Button>
                }
                <Button variant={action === 'REMOVE' ? 'outlined' : 'contained'}
                    className={action === 'REMOVE' ? 'deleteButton noBorderDeleteButton' : 'saveButton'}
                    onClick={primaryAction}>{primaryText}</Button>
            </DialogActions>
        </Dialog>
    )
}