import React from 'react'
import {Box, Slider} from '@mui/material'
import './CustomSlider.scss'

interface CustomSliderProps {
    sliderKey: string
    defaultValue: string
    step: number
    startNumber: number
    endNumber: number
    onChangeValue: (event: Event) => void
    marks?: Array<{value: number, label: string}>
}

const CustomSlider = ({sliderKey, defaultValue, step, startNumber, endNumber, onChangeValue, marks}: CustomSliderProps) => {

    const valuetext = (value: number): string => `${value}`

    return (
        <Box className='customSliderWrapper'>
            <Slider
                key={sliderKey}
                className='customSlider'
                getAriaValueText={valuetext}
                marks={marks || true} step={step} min={startNumber} max={endNumber}
                value={parseFloat(defaultValue)}
                valueLabelDisplay='auto'
                onChange={onChangeValue}
            />
        </Box>
    )
}
export default CustomSlider