import {Box, Typography, capitalize} from '@mui/material'
import {UserAuditLog, UserPromptAudit} from '../../../types/UserAuditLog'
import {AuditingLogTrailItem} from './AuditingLogTrailItem'
import {auditWarningComparator, getAuditWarningTrailDescription} from '../../../utils/userAuditLogUtils'
import './AuditingLogTrail.scss'
import {AuditWarning} from '../../../../cdk/src/types/AuditPromptInfoStored'

type AuditingLogTrailProps = {
    auditLog: UserAuditLog
}

export const AuditingLogTrail = ({
    auditLog
}: AuditingLogTrailProps) => {
    const { messages, aiModelName, isOutputSaved } = auditLog
    return <Box className='AuditingLogTrail_Container'>
        <Typography className='AuditingLogTrail_Title' variant='h3'>Auditing Trail</Typography>
        <Box className='AuditingLogTrail_ItemsWrapper'>
            <Box className='AuditingLogTrail_VerticalDivider'/>
            <Box className='AuditingLogTrail_Items'>
                <AuditingLogTrailItem key='AuditingLogTrail_Item_Start' title='Start of chat' description={`A prompt was sent to:\n• ${aiModelName}`} type='Start'/>
                { renderPromptAudits(messages) }
                { isOutputSaved ? <AuditingLogTrailItem key='AuditingLogTrail_Item_Save' title='Save prompt compilation' description={`The user saved a prompt compilation on ${aiModelName}`} type='Save'/> : <></>}
            </Box>
        </Box>
    </Box>
}

const renderPromptAudits = (promptAudits: UserPromptAudit[]) =>
    promptAudits.map(renderPromptAudit)

const renderPromptAudit = (promptAudit: UserPromptAudit, promptIndex: number) =>
    promptAudit.warnings.length
        ? renderWarnings(promptAudit.warnings, promptIndex)
        : renderNoWarnings(promptIndex)

const renderWarnings = (promptWarnings: AuditWarning[], promptIndex: number) =>
    promptWarnings.sort(auditWarningComparator).map(renderWarning(promptIndex))

const renderWarning = (promptIndex: number) => (warning: AuditWarning, warningIndex: number) =>
    <AuditingLogTrailItem type={warning.level}
        key={`AuditingLogTrail_Prompt_${promptIndex}_Warning_${warningIndex}`} 
        title={`Prompt ${promptIndex + 1} ${capitalize(warning.level)} Risk`}
        description={getAuditWarningTrailDescription(warning)}/>

const renderNoWarnings = (promptIndex: number) =>
    promptIndex === 0 ? <></> : renderSuccessItem(promptIndex)

const renderSuccessItem = (promptIndex: number) => 
    <AuditingLogTrailItem key={`AuditingLogTrail_Prompt_${promptIndex}`} title={`Prompt ${promptIndex + 1}`} description={'The user sent a prompt. No warnings triggered.'} type='none'/>
