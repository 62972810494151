import {UserInfo} from '../types/UserInfo'
import {GroupMember} from '../types/GroupMember'
import {SelectOption} from '../types/SelectOption'

export const getUsersListOptions = (usersListInfo: UserInfo[], groupsMembers: {groupId: string, groupMembers: GroupMember[]}[]): SelectOption[] => {
    const filteredUsersList = getFilteredUsersList(groupsMembers, usersListInfo)
    return filteredUsersList.map((userInfo) => ({label: userInfo.userFullName, value: userInfo.userId}))
}

const getFilteredUsersList = (allGroupsMembers: {groupId: string, groupMembers: GroupMember[]}[], usersInfo: UserInfo[]): UserInfo[] => {
    const allGroupsMembersIds = allGroupsMembers.flatMap(({groupMembers}) => groupMembers.map(member => member.userId))
    return usersInfo.filter(({userId}) => !allGroupsMembersIds.includes(userId))
}