import {FC, MouseEvent, PropsWithChildren, useCallback, useState} from 'react'
import {AppMenuSideItem} from '../AppMenuSideItem'
import {Popover} from '@mui/material'
import {AppMenuItem} from '../../../types/AppMenu'
import './CustomSideItemWithPopover.scss'

type Props = {
    item: AppMenuItem
}

export const CustomSideItemWithPopover: FC<PropsWithChildren<Props>> = ({
    item,
    children
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const open = !!anchorEl

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }, [])

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    return <>
        <AppMenuSideItem item={item} selected={open} onClick={handleClick}/>
        <Popover 
            className='customSideItemPopover'
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
            }}
            transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
            }}
            onClose={handleClose}>
                {children}
        </Popover>
    </>
}