import {Group} from '../types/Group'
import {GroupMember, GroupMemberEntry} from '../types/GroupMember'
import {isEqual} from './genericUtils'

export const areGroupsMatch = (a: Group) => (b: Group): boolean =>
    a.hashKey ===  b.hashKey

export const areGroupsChanged = (a: Group) => (b: Group): boolean =>
    areGroupsMatch(a)(b) && !isEqual(a, b)

export const getSortedGroupNames = (groups: Group[]): Group[] =>
    groups.sort((a, b) => a.name.localeCompare(b.name)) ?? []

export const addGroupMembers = (a: GroupMemberEntry) => (b: GroupMemberEntry): GroupMemberEntry => ({
    groupId: a.groupId,
    groupMembers: [...a.groupMembers, ...b.groupMembers]
})

export const areGroupMemberEntriesMatch = (a: GroupMemberEntry) => (b: GroupMemberEntry): boolean =>
    a.groupId === b.groupId

export const areGroupMemberEntriesChanged = (a: GroupMemberEntry) => (b: GroupMemberEntry): boolean =>
    areGroupMemberEntriesMatch(a)(b) && !isEqual(a, b)

export const isUserInSelectedGroup = (allGroupsMembers: {
    groupId: string,
    groupMembers: GroupMember[]
}[], selectedGroupId: string, searchText: string): boolean =>
    allGroupsMembers
        .flatMap(({groupMembers}) => groupMembers)
        .some(({userEmail, userFullName, groupId}) =>
            (userFullName?.toLowerCase().includes(searchText) || userEmail?.includes(searchText)) && selectedGroupId === groupId)