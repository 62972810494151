import {Accordion, AccordionDetails, AccordionSummary, Box, Chip, Divider, Grid, Link, Typography} from "@mui/material"
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet'
import './LicenseSetting.scss'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import InfoIcon from '@mui/icons-material/Info'
import CheckIcon from '@mui/icons-material/Check'
import {useStripeSubscriptions} from '../../../../hooks/useStripeSubscriptions'
import Spinner from '../../../spinner/Spinner'
import {getPeriodByPriceId, getPlanByPriceId, getPlanPrice} from '../../../../utils/licenseUtils'
import {PRICES_INFO_MAP} from '../../../../constants/StripeConstants'
import {formatDateStringToLocaleDate} from '../../../../helpers/DateHelpers'

export const LicenseSettings = () => {
	const {user} = useUser()

	const {subscriptions} = useStripeSubscriptions()

	const subscription = subscriptions.length ? subscriptions[0] : undefined
	const plan = subscription ? getPlanByPriceId(subscription.plan.id) : undefined
	const period = subscription ? getPeriodByPriceId(subscription.plan.id) : undefined
	const planPrice = plan && period && subscription ? getPlanPrice(plan, period, subscription.quantity) : 0
	const benefits = plan ? PRICES_INFO_MAP[plan].benefits : []

	const onCurrentSubscriptionClick = () => {
		TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
			action: 'changeSubtab',
			tab: 'currentSubscription'
		})
	}

	if (!subscription) return <Spinner/>

	return <Grid container className='licenseSettings'>
		<Grid item xs={2}>
			<Link className='licenseSubmenu active' onClick={onCurrentSubscriptionClick}>
				<AccountBalanceWalletIcon fontSize="small"/>
				Current Subscription
			</Link>
		</Grid>
		<Grid item xs={10} className='submenuContent'>
			<Box className='licenseTitleContainer'>
				<Typography className='licenseName'>{plan} plan</Typography>
				{plan !== 'trial' && <Chip label={period} variant='outlined' className='periodChip'/>}
			</Box>

			<Typography className='pricePerSeat'>${planPrice} per seat</Typography>
			<Typography className='seatsCount'>{subscription.quantity} seats</Typography>

			<Accordion className='benefitsAccordion'>
				<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
					<InfoIcon className='infoIcon'/> <Typography className='benefitsTitle'>Included in your plan</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<Grid container className='benefitContainer'>
						{benefits.map((benefit, index) => <Grid item xs={4} md={3} className='benefitItem' key={`benefit${index}`}>
							<Typography> <CheckIcon className='checkIcon'/>{benefit}</Typography>
						</Grid>)}
					</Grid>
				</AccordionDetails>
			</Accordion>
			{plan !== 'trial' && <>
				<Typography variant='h3' className='subscriptionDetails'>Subscription details</Typography>
				<Divider/>
				<Typography variant='h4' className='periodBilling'>Monthly billing</Typography>
				<Typography className='nextPeriod'>Next charge on {formatDateStringToLocaleDate(subscription.current_period_end * 1000)}</Typography>
				<Divider/>
				<Typography className='newSeats'>The new seats added will be available immediately and will be billed retroactively in the next billing cycle for the remainder of the month.</Typography>
			</>}
		</Grid>
	</Grid>
}