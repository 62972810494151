import {Box, Drawer, Grid, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useUser} from '@clerk/clerk-react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {useWarningsContext} from '../../../../context/WarningsContext'
import {FormatWarning} from '../../../../types/Warning'
import './Warnings.scss'
import {useFeedbackContext} from '../../../../context/FeedbackContext'
import {FormatItem} from './FormatItem'
import {EditFormatWarning} from './EditFormatWarning'
import {TrackActionEvent} from '../../../../service/SegmentService'

export const Format = () => {

	const {user} = useUser()
	const {showFeedback} = useFeedbackContext()
	const {formatWarnings, editFormatWarning} = useWarningsContext()
	const {displayDrawerEditFormatWarning, toggleDrawer} = useToggleDrawerContext()

	const [formatWarningToEdit, setFormatWarningToEdit] = useState<FormatWarning | undefined>()


	const handleEditClicked = (event: React.MouseEvent<HTMLButtonElement>, formatWarning: FormatWarning) => {
		setFormatWarningToEdit(formatWarning)
		return toggleDrawer(true, 'EDIT_FORMAT_WARNING_DRAWER')(event)
	}

	const handleEnableClicked = async (formatWarning: FormatWarning) => {
		const error = await editFormatWarning({...formatWarning, enabled: !formatWarning.enabled})
		if (error) {
			showFeedback('Error enabling the format', 'Something failed updating the warning level for this format', 'error', 5)
			return
		}
		showFeedback(`Format ${formatWarning.enabled ? 'disabled' : 'enabled'}`, '', 'success', 5)
		TrackActionEvent('Warnings', user?.externalId ?? user?.id, {action: formatWarning.enabled ? 'disabled' : 'enabled', type: 'format'})
	}

	return <>
		<Grid container className='settingContainer'>
			<Box className='sectionContainer'>
				<Grid item xs={12}>
					<Typography variant='h5' className='warningSubtitle'>Standard format</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography className='body'>To be warned about these types of data turn the toggle on. Use the cog to change the warning level.</Typography>
				</Grid>
				{formatWarnings.length ? <Grid className='warningNameContainer'>
					{formatWarnings.map((formatWarning, index) => <FormatItem key={index} warning={formatWarning} handleEditClicked={handleEditClicked}
					                                                          handleEnableClicked={handleEnableClicked}/>)}
				</Grid> : <></>}
			</Box>
		</Grid>
		<Drawer anchor='right' open={displayDrawerEditFormatWarning} onClose={toggleDrawer(false, 'EDIT_FORMAT_WARNING_DRAWER')}>
			{formatWarningToEdit ? <EditFormatWarning formatWarningToEdit={formatWarningToEdit}/> : <></>}
		</Drawer>
	</>
}