import {createContext, FC, PropsWithChildren, useContext} from 'react'
import {useWarnings} from '../hooks/useWarnings'
import {FormatWarning, TopicWarning, WordWarning} from '../types/Warning'

export type WarningsContextValue = {
	loading: boolean
	topicWarnings: TopicWarning[]
	wordWarnings: WordWarning[]
	formatWarnings: FormatWarning[]
	createTopicWarning: (topic: string, warningLevel: string) => Promise<string | undefined>
	editTopicWarning: (topicWarning: TopicWarning) => Promise<string | undefined>
	removeTopicWarning: (topicWarning: TopicWarning) => Promise<string | undefined>
	createWordWarning: (word: string, warningLevel: string) => Promise<string | undefined>
	editWordWarning: (wordWarning: WordWarning) => Promise<string | undefined>
	removeWordWarning: (wordWarning: WordWarning) => Promise<string | undefined>
	editFormatWarning: (formatWarning: FormatWarning) => Promise<string | undefined>
}

const DEFAULT_WARNINGS_CONTEXT: WarningsContextValue = {
	loading: false,
	topicWarnings: [],
	wordWarnings: [],
	formatWarnings: [],
	createTopicWarning: (topic: string, warningLevel: string) => Promise.resolve(''),
	editTopicWarning: (topicWarning: TopicWarning) => Promise.resolve(''),
	removeTopicWarning: (topicWarning: TopicWarning) => Promise.resolve(''),
	createWordWarning: (word: string, warningLevel: string) => Promise.resolve(''),
	editWordWarning: (wordWarning: WordWarning) => Promise.resolve(''),
	removeWordWarning: (wordWarning: WordWarning) => Promise.resolve(''),
	editFormatWarning: (formatWarning: FormatWarning) => Promise.resolve('')
}

export const WarningsContext = createContext<WarningsContextValue>(DEFAULT_WARNINGS_CONTEXT)

export const useWarningsContext = () => useContext(WarningsContext)

export const WarningsContextProvider: FC<PropsWithChildren> = ({children}) => {
	const value = useWarnings()

	return <WarningsContext.Provider value={value}>
		{children}
		</WarningsContext.Provider>
}