import {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext, useMemo, useState} from 'react'

export type TableContextValue = {
	page: number
	setPage: (page: number) => void
	keepPage: boolean
	setKeepPage: Dispatch<SetStateAction<boolean>>
}

const DEFAULT_TABLE_CONTEXT: TableContextValue = {
	page: 0,
	setPage: () => {},
	keepPage: false,
	setKeepPage: () => {},
}


const TableContext = createContext<TableContextValue>(DEFAULT_TABLE_CONTEXT)

export const useTableContext = () => useContext(TableContext)

export const TableContextProvider: FC<PropsWithChildren> = ({ children }) => {

	const [page, setPage] = useState<number>(0)
	const [keepPage, setKeepPage] = useState<boolean>(false)

	const value = useMemo(() => ({
		page, setPage,
		keepPage, setKeepPage})
	, [page, keepPage])

	return <TableContext.Provider value={value}>
		{children}
	</TableContext.Provider>

}