import { Budget, BudgetPeriodicity } from '../types/Budget'
import { getClosestMonthlyPeriodDate } from './dateUtils'

const BUDGET_PERIODICITY_MONTH_OFFSET_MAP: { [key in BudgetPeriodicity]: number} = {
    Monthly: 1,
    Quarterly: 3
}

export const getClosestBudgetPeriodStartDate = (budget: Budget): Date => 
    getClosestMonthlyPeriodDate(new Date(budget.startDate), BUDGET_PERIODICITY_MONTH_OFFSET_MAP[budget.periodicity])

export const getClosestBudgetPeriodEndDate = (budget: Budget): Date => {
    const date = getClosestBudgetPeriodStartDate(budget)
    date.setMonth(date.getMonth() + BUDGET_PERIODICITY_MONTH_OFFSET_MAP[budget.periodicity])
    return date
}
