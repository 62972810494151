import React, {useState} from 'react'
import Box from '@mui/material/Box'
import {AiModelIcon} from '../../../icons/AiModelIcon'
import {AIModel} from '../../../../types/AiModel'
import Typography from '@mui/material/Typography'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import Switch from '@mui/material/Switch'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {useAiModelsContext} from '../../../../context/AIModelsContext'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {Grid} from '@mui/material'

type AiModelControlProps = {
	aiModel: AIModel
	index: number
	onSelectAiModel: (aiModel: AIModel) => void
}

export const AiModelControl = ({aiModel, index, onSelectAiModel}: AiModelControlProps) => {

	const {updateAiModelEnablement, deleteAiModel} = useAiModelsContext()
	const {toggleDrawer} = useToggleDrawerContext()

	const [showAiDeletionDialog, setShowAiDeletionDialog] = useState<boolean>(false)

	const deleteAiModelConfirmationHandler = () => {
		deleteAiModel(aiModel.id)
		setShowAiDeletionDialog(false)
	}

	const onRemoveAiModel = (aiModel: AIModel) => {
		if (aiModel.isEnabled) {
			setShowAiDeletionDialog(true)
		}
	}

	const onEditAiModel = (event: React.MouseEvent) => {
		onSelectAiModel(aiModel)
		return toggleDrawer(true, 'EDIT_AI_MODEL_DRAWER')(event)
	}

	return <>
		<Grid container className={`aiModelRow ${aiModel.isEnabled ? '' : 'disabled'}`}
		      key={`${aiModel.id}-${index}`}>
			<Grid item lg={10} md={9} xs={7}>
				<Box><AiModelIcon modelId={aiModel.id}/></Box>
				<Typography>{aiModel.name}</Typography>
			</Grid>
			<Grid item lg={1} md={2} xs={3}>
				<Box className={`aiModelColumnIcons ${aiModel.isEnabled ? '' : 'disabled'}`}>
					<SettingsIcon onClick={onEditAiModel}/>
					<DeleteIcon onClick={() => onRemoveAiModel(aiModel)}/>
				</Box>
			</Grid>
			<Grid item lg={1} md={1} xs={2} className='switchModelIcon'>
				<Switch checked={aiModel.isEnabled}
				        onChange={(event) => updateAiModelEnablement(event.target.checked, aiModel)}/>
			</Grid>
		</Grid>
		<ConfirmationDialog handleClose={() => setShowAiDeletionDialog(false)} open={showAiDeletionDialog}
		                    currentEvent='aiModelDeletion' handleDelete={deleteAiModelConfirmationHandler}/>
	</>
}