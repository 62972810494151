import {Prompt, UserPromptTemplate, PromptVisibility} from '../types/Prompt'
import {AIModelID} from '../types/AiModel'
import {Chat} from '../types/Chat'
import {Conversation} from '../types/Conversation'

export const isChat = (entity: Conversation): entity is Chat => 'name' in entity
export const isPrompt = (entity: Conversation): entity is Prompt => 'title' in entity

export const createPromptObject = (promptTemplate: UserPromptTemplate): Prompt => {
	const {title, description} = promptTemplate
    
	return {
		...promptTemplate,
		title: title.trim(),
		description: description.trim(),
		creationDate: new Date().getTime(),
	}
}

export const filterConversationsByParams = <T extends Conversation>(
	conversations: T[],
	searchAIModelIDs: AIModelID[],
	searchLabels: string[],
	searchText: string,
	visibility?: PromptVisibility[]
): T[] => {
	const filteredItems = conversations.filter(item => (
		(!searchAIModelIDs.length || (item.modelId && searchAIModelIDs.includes(item.modelId))) &&
		(!searchLabels.length || (searchLabels.some(label => item.labels?.includes(label)))) &&
		(!searchText.length || (
			(isChat(item) && item.name.toLowerCase().includes(searchText.toLowerCase())) ||
			(isPrompt(item) && item.title.toLowerCase().includes(searchText.toLowerCase())) ||
			item.description.toLowerCase().includes(searchText.toLowerCase())
		))
	))

	return visibility && filteredItems.every(isPrompt) ? 
		filterPromptsByVisibility(filteredItems as Prompt[], visibility) as T[] : filteredItems
}

export const filterPromptsByVisibility = (prompts: Prompt[], visibility: PromptVisibility[]): Prompt[] => {
	if (visibility.length === 2) return prompts
	if (visibility.includes('public')) return prompts.filter(prompt => prompt.isPublic)
	if (visibility.includes('private')) return prompts.filter(prompt => !prompt.isPublic)
	return []
}