import React, {useEffect, useRef, useState} from 'react'
import {Box} from '@mui/material'
import {StickyProps} from '../../types/StickyProps'

export const Sticky = ({position, stuckClasses = "", unstuckClasses = "", children}: StickyProps) => {
	const [stuck, setStuck] = useState(false)
	const ref = useRef<Element | undefined>(undefined)

	useEffect(() => {
		const cachedRef = ref.current
		const observer = new IntersectionObserver(
			([e]) => setStuck(e.intersectionRatio < 1),
			{ threshold: [1] }
		)
		if(cachedRef) observer.observe(cachedRef)
		return () => {
			if(cachedRef) observer.unobserve(cachedRef)
		}
	}, [ref])

	return <Box className={stuck ? stuckClasses : unstuckClasses} style={{position: "sticky", [position]: -1}} ref={ref}>
			{children}
		</Box>
}