import { FormTemplate, StepIndex, StepIndexes, StepsState } from '../types/AccountCreation'

export const getFormCompanyName = (form: FormTemplate): string =>
    form.companyName.trim()

export const getFormTableSuffix = (form: FormTemplate): string =>
    getFormCompanyName(form).toUpperCase().replaceAll(' ','-')

export const getFormCompanyDomains = (form: FormTemplate): string[] =>
    form.companyDomains.map(getCompanyDomain)

export const getFormCompanyEmails = (form: FormTemplate): string[] =>
    form.companyEmails.map(getCompanyEmail)

export const getFormAdminEmails = (form: FormTemplate): string [] =>
    form.adminEmails.map(getAdminEmail)

export const getCompanyDomain = (domain: string): string =>
    domain.trim()

export const getCompanyEmail = (email: string): string =>
    email.trim()

export const getAdminEmail = (email: string): string => 
    email.trim()

export const isCompanyNameValid = (name: string): boolean =>
    /^[\w\s]{1,220}$/.test(name)

export const areCompanyDomainsValid = (domains: string[]): boolean =>
    !!domains.length && domains.every(isCompanyDomainValid)

export const areCompanyEmailsValid = (emails: string[]): boolean =>
    !!emails.length && emails.every(isEmailAddress)

export const isCompanyDomainValid = (domain: string): boolean =>
    isEmailDomain(domain)

export const isCompanyEmailValid = (email: string): boolean =>
    isEmailAddress(email)

export const areAdminEmailsValid = (emails: string[], domains: string[]): boolean =>
    emails.every(isAdminEmailValid(domains))

export const isAdminEmailValid = (domains: string[]) => (email: string): boolean =>
    domains.some(emailMatchesDomain(email)) || isWhitelistedEmail(email)

export const getFirstIncompleteIndex = (stepsState: StepsState): StepIndex | undefined =>
    StepIndexes.reduceRight((prev, index) => stepsState[index] !== 'completed' ? index : prev, undefined as StepIndex | undefined)

export const isEmailDomain = (value: string): boolean =>
    // eslint-disable-next-line no-useless-escape
    /^@[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}$/.test(value)

export const isEmailAddress = (value: string): boolean =>
    /^[a-zA-Z0-9_.+-]+@[a-z0-9-]+(\.[a-z]{2,5}){1,2}$/.test(value)

export const emailMatchesDomain = (email: string) => (domain: string) => {
    const isDomainEmail = isEmailAddress(domain)
    
    if (isDomainEmail) {
        return email === domain
    } else {
        const parsedDomain = domain.startsWith('*') ? domain.slice(1) : domain

        return email.endsWith(parsedDomain)
    }
}

export const isWhitelistedEmail = (email: string) => {
    const whitelist = ['@gmail.','@outlook.','@me.','@icloud.','@yahoo.','@hotmail.','@protonmail.', '@aol.', '@fastmail.', '@zoho.','@yandex.', '@mail.']

    return isEmailAddress(email) && whitelist.some(partialDomain => email.includes(partialDomain))
}