import {AppMenuItem} from '../types/AppMenu'
import {PathCondition} from '../types/Path'

const PathConditionMap: {[key in PathCondition]: (path: string, item: AppMenuItem) => boolean} = {
    pathEndsWith: (path, item) => item.navigateTo !== undefined && path.endsWith(item.navigateTo),
    pathExactTo: (path, item) => path === item.navigateTo,
    pathStartsWith: (path, item) => item.navigateTo !== undefined && path.startsWith(item.navigateTo)
}

export const isAppMenuItemSelected = ({pathname}: {pathname: string}) => (item: AppMenuItem): boolean => 
    PathConditionMap[item.pathCondition ?? 'pathStartsWith'](pathname, item)