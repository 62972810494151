import {createContext, ReactNode, useContext} from 'react'
import {UserInfo} from '../types/UserInfo'
import {useUserInfo} from '../hooks/useUserInfo'
import Spinner from '../components/spinner/Spinner'
import {AppGridWrapper} from '../components/appGridWrapper/AppGridWrapper'
import {AIModelID} from '../types/AiModel'

interface UserInfoContextProviderProps {
	children: ReactNode
}

export interface UserInfoContextValue {
	loading: boolean
	userInfo: UserInfo | undefined
	updateUserModels: (selectedModels: AIModelID[]) => void
}

const DEFAULT_USER_INFO_CONTEXT: UserInfoContextValue = {
	loading: false,
	userInfo: undefined,
	updateUserModels: () => {}
}

export const UserInfoContext = createContext<UserInfoContextValue>(DEFAULT_USER_INFO_CONTEXT)

export const useUserInfoContext = () => useContext(UserInfoContext)

export const UserInfoContextProvider = ({children}: UserInfoContextProviderProps) => {
	const value = useUserInfo()
	const { userInfo } = value

	return <UserInfoContext.Provider value={value}>
		{ userInfo ? children : <AppGridWrapper hideBanners><Spinner/></AppGridWrapper> }
	</UserInfoContext.Provider>
}