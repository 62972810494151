export const objectsAreEquals = (obj1: object, obj2: object): boolean => {
	const sortedObj1 = JSON.stringify(sortObjectByKey(obj1))
	const sortedObj2 = JSON.stringify(sortObjectByKey(obj2))
	return sortedObj1 === sortedObj2
}

export const sortObjectByKey = (unsortedObj: object): any => {
	return Object.keys(unsortedObj).sort().reduce((acc: any, key: string) => {
		acc[key] = unsortedObj[key]
		return acc
	}, {})
}