import {MenuItem, Select} from '@mui/material'
import React from 'react'

export const SelectWarningLevel = ({setWarningLevel}) => {

  return <Select onChange={(event) => setWarningLevel(event.target.value)} className='newWarningTextfield warningLevelSelect' defaultValue='medium'
                 fullWidth
                 label="Warning level"
  >
	  <MenuItem value='low'>Low</MenuItem>
	  <MenuItem value='medium'>Medium</MenuItem>
	  <MenuItem value='high'>High</MenuItem>
  </Select>
}