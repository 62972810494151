import {FC, PropsWithChildren, useEffect} from 'react'
import {PageView} from '../../service/SegmentService'
import {AdminErrorScreen} from './error/AdminErrorScreen'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {Grid} from '@mui/material'
import {NoBudgetAdminAlert} from './NoBudgetAdminAlert'
import './Admin.scss'

export const Admin: FC<PropsWithChildren> = ({
    children
}) => {

    const {userInfo} = useUserInfoContext()
    const isAdmin = userInfo?.isAdmin

    useEffect(() => {
        if (isAdmin) PageView('Narus admin')
    }, [isAdmin])

    return <>
        { isAdmin ? <>
            <NoBudgetAdminAlert/>
            <Grid container className='adminContainer'>
                <Grid xs={12} item>
                    {children}
                </Grid>
            </Grid>
        </> : <AdminErrorScreen/> }
    </>
}