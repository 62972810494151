import {FC, PropsWithChildren, createContext, useContext, useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {UserOrganizationContextValue} from '../types/UserOrganization'
import {DEFAULT_USER_ORGANIZATION, DEFAULT_USER_ORGANIZATION_CONTEXT_VALUE} from '../constants/UserOrganizationConstants'
import {useUserContext} from './UserContext'
import Spinner from '../components/spinner/Spinner'
import {getUserOrganizationWithRetries} from '../service/persistenceService'
import {isUserOrganizationFilled} from '../utils/userOrganizationUtils'
import {AppGridWrapper} from '../components/appGridWrapper/AppGridWrapper'
import {AsyncState} from '../types/AsyncState'
import {isFinishedState} from '../utils/asyncUtils'

export const UserOrganizationContext = createContext<UserOrganizationContextValue>(DEFAULT_USER_ORGANIZATION_CONTEXT_VALUE)

export const useUserOrganizationContext = () => useContext(UserOrganizationContext)

export const UserOrganizationContextProvider: FC<PropsWithChildren> = ({
    children
}) => {
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const [loadingState, setLoadingState] = useState<AsyncState>('idle')
    const [userOrganization, setUserOrganization] = useState(DEFAULT_USER_ORGANIZATION)
    const { token } = useUserContext()
    const isContextValid = isUserOrganizationFilled(userOrganization)
    const isAccountCreationPath = [
        '/create-account-basic-information',
        '/create-account-assign-admins',
        '/create-account-connect-ai'
    ].some(path => pathname === path)

    const value: UserOrganizationContextValue = useMemo(() => ({
        loadingState,
        userOrganization,
        setUserOrganization
    }), [loadingState, userOrganization])
	
    useEffect(() => {
		if (token) {
            setLoadingState('loading')
			getUserOrganizationWithRetries(token)
                .then(response => {
                    setLoadingState('completed')
                    setUserOrganization(response)
                })
				.catch(() => {
                    setLoadingState('error')
                    navigate('/create-account-basic-information')
                })
		}
	}, [token, navigate])


    return <UserOrganizationContext.Provider value={value}>
        { isContextValid || (isAccountCreationPath && isFinishedState(loadingState)) 
            ? children
            : <AppGridWrapper hideBanners><Spinner/></AppGridWrapper>  }
    </UserOrganizationContext.Provider>
}