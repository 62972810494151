import {FC, PropsWithChildren} from 'react'
import {UserOrganizationContextProvider} from '../context/UserOrganizationContext'
import {UserContextProvider} from '../context/UserContext'
import {UserInfoContextProvider} from '../context/UserInfoContext'
import {StripeSubscriptionsContextProvider} from '../context/StripeSubscriptionsContext'
import {AiModelsContextProvider} from '../context/AIModelsContext'

export const CommonProviders: FC<PropsWithChildren> = ({
    children
}) => (
    <UserContextProvider>
        <UserOrganizationContextProvider>
            <UserInfoContextProvider>
                <StripeSubscriptionsContextProvider>
                    <AiModelsContextProvider>
                        {children}
                    </AiModelsContextProvider>
                </StripeSubscriptionsContextProvider>
            </UserInfoContextProvider>
        </UserOrganizationContextProvider>
    </UserContextProvider>
)
