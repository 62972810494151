import {Box, Button, MenuItem, Select, SelectChangeEvent, TextField, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, {ChangeEvent, useState} from 'react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {TopicWarning, WarningLevel} from '../../../../types/Warning'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useWarningsContext} from '../../../../context/WarningsContext'
import {useUser} from '@clerk/clerk-react'
import {useFeedbackContext} from '../../../../context/FeedbackContext'

interface EditTopicWarningProps {
	topicWarningToEdit: TopicWarning
}

export const EditTopicWarning = ({topicWarningToEdit}: EditTopicWarningProps) => {

	const {user} = useUser()
	const {showFeedback} = useFeedbackContext()
	const {toggleDrawer} = useToggleDrawerContext()
	const {topicWarnings, editTopicWarning} = useWarningsContext()


	const [updatedTopic, setUpdatedTopic] = useState<string>(topicWarningToEdit.topic)
	const [updatedWarningLevel, setUpdatedWarningLevel] = useState<WarningLevel>(topicWarningToEdit.level)

	const [errorMessage, setErrorMessage] = useState<string>('')
	const [topicValidError, setTopicValidError] = useState<boolean>(false)

	const handleUpdateTopicWarningClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!updatedTopic) {
			setTopicValidError(true)
			setErrorMessage('The topic is mandatory')
			return
		}
		if (topicWarnings.find(topicWarning => topicWarning.topic === updatedTopic && topicWarning.hashKey !== topicWarningToEdit.hashKey)) {
			setTopicValidError(true)
			setErrorMessage('That topic already exists. Try adding a different one')
			return
		}
		setTopicValidError(false)

		const error = await editTopicWarning({...topicWarningToEdit, topic: updatedTopic, level: updatedWarningLevel})
		if (error) {
			setTopicValidError(true)
			setErrorMessage(error)
			return
		}

		showFeedback('Topic updated', '', 'success', 5)
		TrackActionEvent('Warnings', user?.externalId ?? user?.id, {action: 'edit', type: 'topic'})
		return toggleDrawer(false, 'EDIT_TOPIC_WARNING_DRAWER')(event)
	}

	return <Box className='editWarningContainer'>
		<Box className='editWarningHeader'>
			<Typography variant='h3'>Configuration</Typography>
			<CloseIcon onClick={toggleDrawer(false, 'EDIT_TOPIC_WARNING_DRAWER')}/>
		</Box>
		<Typography variant='body2'>Use the following options to define your topic.</Typography>
		<TextField fullWidth label='Topic' variant='outlined' className='textFieldInput configDrawer'
		           value={updatedTopic} error={topicValidError} helperText={topicValidError ? errorMessage : ''}
		           onChange={(event: ChangeEvent<HTMLInputElement>) => setUpdatedTopic(event?.target?.value ?? '')}/>

		<Select label="Warning level" onChange={(event: SelectChangeEvent) => setUpdatedWarningLevel(event.target.value as WarningLevel)} value={updatedWarningLevel}
		        className='newWarningTextfield warningLevelSelect edit' fullWidth>
			<MenuItem value='low'>Low</MenuItem>
			<MenuItem value='medium'>Medium</MenuItem>
			<MenuItem value='high'>High</MenuItem>
		</Select>

		<Box className='saveWarningButtonContainer'>
			<Button onClick={handleUpdateTopicWarningClicked}>Save</Button>
		</Box>
	</Box>
}