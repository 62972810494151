import { Box, CircularProgress, Typography } from '@mui/material'
import './AccountCreationProgress.scss'

export const AccountCreationProgress = () => <Box className='AccountCreationProgress'>
    <Box className='AccountCreationProgress_Icon'>
        <CircularProgress size={48}/>
    </Box>
    <Box className='AccountCreationProgress_Content'>
        <Typography variant='h3' className='AccountCreationProgress_Title'>Synchronizing users and creating workspaces</Typography>
        <Typography variant='h5' className='AccountCreationProgress_Subtitle'>We are creating your workspace and connecting to your base, this may take a few minutes.</Typography>
    </Box>
</Box>