import {capitalize, Chip} from '@mui/material'
import {AuditLogWarningLevel} from '../../../types/UserAuditLog'
import './AuditLogWarningLevelChip.scss'

export type AuditLogWarningLevelChipProps = {
	level: AuditLogWarningLevel
}

export const AuditLogWarningLevelChip = ({
    level
}: AuditLogWarningLevelChipProps) =>
    level !== 'none'
        ? <Chip className={`AuditLogWarningLevelChip_${capitalize(level)}`} size='small' label={`● ${capitalize(level)}`}/>
        : <></>