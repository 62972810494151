import {FC, MouseEvent, useCallback} from 'react'
import {AppMenuItem} from '../../types/AppMenu'
import {IconButton, Tooltip, TooltipProps} from '@mui/material'
import {AppMenuIcon} from './AppMenuIcon'
import {useAppMenuContext} from '../../context/AppMenuContext'
import {isAppMenuItemSelected} from '../../utils/appMenuUtils'
import {useLocation} from 'react-router-dom'
import './AppMenuSideItem.scss'

const tooltipSlotProps: TooltipProps['slotProps'] = {
    popper: {
        modifiers: [
            { name: 'offset', options: { offset: [0,-8] }}
        ]
    }
}
const tooltipClasses: TooltipProps['classes'] = {
    tooltip: 'appMenuSideItemTooltip'
}

type Props = {
    item: AppMenuItem
    /** overlaps default logic to determine if item is marked as selected */
    selected?: boolean
    /** extra logic on menu item clicked */
    onClick?: (event: MouseEvent<HTMLButtonElement>, item: AppMenuItem) => void
}

export const AppMenuSideItem: FC<Props> = ({
    item,
    selected,
    onClick
}) => {
    const { pathname } = useLocation()
    const { selectMenuItem } = useAppMenuContext()
    const { icon } = item
    const selectedSuffix = selected || isAppMenuItemSelected({pathname})(item) ? ' selected' : ''

    const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        selectMenuItem(item)
        onClick?.(event, item)
    }, [selectMenuItem, item, onClick])

    return (
        <Tooltip classes={tooltipClasses} title={item.title} placement='right' slotProps={tooltipSlotProps}>
            <IconButton className={`appMenuSideItem${selectedSuffix}`} onClick={handleClick}>
                <AppMenuIcon icon={icon}/>
            </IconButton>
        </Tooltip>
    )
}