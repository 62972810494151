import {Box, Tooltip, Typography} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import CreatableSelect from 'react-select/creatable'
import {createSelectOption} from '../../helpers/LabelHelpers'
import {GroupBase, SingleValue, StylesConfig} from 'react-select'
import {SelectOption} from '../../types/SelectOption'
import {usePromptCreationContext} from '../../context/PromptCreationContext'

const defaultTones = ['Professional', 'Friendly', 'Persuasive', 'Formal', 'Funny', 'Confident', 'Bold', 'Witty', 'Empathetic']
const defaultFormat = ['E-mail', 'One-pager', 'Bullet points', 'Table', 'One paragraph', 'Essay', 'White paper', 'Letter', 'Memorandum', 'Contract']
const defaultLanguages = ['English', 'Spanish', 'French', 'Chinese']
const defaultControlStyles: StylesConfig<SelectOption, false, GroupBase<SelectOption>> = {
	control: (base) => ({
		...base,
		height: '100%',
	})
}

export const OptimizationSettings = () => {
	const {tone, setTone, format, setFormat, language, setLanguage, setValueHandler} = usePromptCreationContext()

	return <Box className='fieldsContainer'>
		<Typography variant='subtitle1' className='fieldLabel'>Tone of voice</Typography>
		<Typography variant='subtitle1' className='fieldLabel'>Format<Tooltip
			title='Specify how you want the output to be structure, for example One paragraph'><HelpIcon
			className='helpIcon'
			fontSize='inherit'/></Tooltip></Typography>
		<Typography variant='subtitle1' className='fieldLabel'>Language</Typography>
		<CreatableSelect isClearable
			styles={defaultControlStyles}
			menuPlacement='top'
			options={tone ? [...defaultTones, tone].map(createSelectOption) : defaultTones.map(createSelectOption)}
			value={tone ? createSelectOption(tone) : null}
			menuPosition='fixed'
			placeholder='Type or select an option' className='creatableSelect'
			onChange={(event: SingleValue<SelectOption>) => setValueHandler(setTone, event?.value ?? '', 'tone')}/>
		<CreatableSelect isClearable options={defaultFormat.map(createSelectOption)}
			styles={defaultControlStyles}
			menuPlacement='top'
			value={format ? createSelectOption(format) : null}
			menuPosition='fixed'
			placeholder='Type or select an option' className='creatableSelect'
			onChange={(event: SingleValue<SelectOption>) => setValueHandler(setFormat, event?.value ?? '', 'format')}/>
		<CreatableSelect isClearable options={defaultLanguages.map(createSelectOption)}
			styles={defaultControlStyles}
			menuPlacement='top'
			defaultValue={language ? createSelectOption(language) : null}
			menuPosition='fixed'
			placeholder='Ex: English'
			className='creatableSelect'
			onChange={(event: SingleValue<SelectOption>) => setValueHandler(setLanguage, event?.value ?? '', 'language')}/>
	</Box>
}