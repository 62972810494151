import {AddressElement} from '@stripe/react-stripe-js'
import {Box, Button} from '@mui/material'
import React from 'react'
import {StripeAddressElementChangeEvent} from '@stripe/stripe-js'
import {usePaymentContext} from '../../../context/PaymentContext'
import Spinner from '../../spinner/Spinner'

export const CustomerDetails = () => {

	const {customerDetails, setCustomerDetails, getTaxes, taxesLoading} = usePaymentContext()

	const onAddressChange = (event: StripeAddressElementChangeEvent) => {
		setCustomerDetails(event.value)
	}

	return <>
		<AddressElement options={{mode: 'billing'}} onChange={onAddressChange}/>
		<Button variant='contained' className='purchaseButton' fullWidth disabled={!customerDetails?.address.postal_code || !customerDetails?.address.country}
		        onClick={() => getTaxes()}>Next</Button>
		{taxesLoading && <Box className='spinnerContainer'><Spinner/></Box>}
	</>
}