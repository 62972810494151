import {Prompt} from '../types/Prompt'

const HR_STRATEGY_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'HR_STRATEGY_PROMPT_TEMPLATES-1',
        title: 'Align HR Strategy with Organizational Goals',
        description: 'Steps to create a Human Resources strategy that aligns with your company.  Steps to create a Human Resources strategy that aligns with your company. Steps to create a Human Resources strategy that aligns with your company. Steps to create a Human Resources strategy that aligns with your company.',
        userPrompt: 'As an HR strategist, write the steps to help me develop a comprehensive HR strategy that aligns with our {company mission/industry best practices/specific business goals} for a company in the {industry} industry with {number} employees. Ensure the strategy includes clear objectives, key performance indicators (KPIs), and a roadmap for implementation.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_STRATEGY_PROMPT_TEMPLATES-2',
        title: 'Enhance HR Effectiveness',
        description: 'Human Resources strategy analysis and improvement.',
        userPrompt: 'As an HR analyst, explain the steps to analyze our current HR strategy and identify areas for improvement to enhance {employee engagement/talent retention/organizational efficiency} in a company with {number} employees in the {industry} industry. Provide specific recommendations and a plan for implementation.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_STRATEGY_PROMPT_TEMPLATES-3',
        title: 'Implement HR Initiatives',
        description: 'Roadmap to implement Human Resources initiatives that support your strategy.',
        userPrompt: 'As a project manager, create a roadmap for implementing new HR initiatives to support our {strategic objectives/organizational transformation/employee well-being} in a company with {number} employees in the {industry} industry. Include a timeline, resources required, and potential risks and challenges.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_STRATEGY_PROMPT_TEMPLATES-4',
        title: 'Evaluate HR Programs',
        description: 'Human Resources program evaluation framework.',
        userPrompt: 'As an HR researcher, develop a framework for evaluating the effectiveness of our HR programs and measuring their impact on {key performance indicators/employee satisfaction/business outcomes} in a company with {number} employees in the {industry} industry. Define the metrics to be used, data collection methods, and frequency of evaluation.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_STRATEGY_PROMPT_TEMPLATES-5',
        title: 'Stay Ahead in HR Practices',
        description: 'Human Resources innovation strategies.',
        userPrompt: 'As an HR thought leader, recommend innovative HR practices that can help us stay ahead of industry trends and attract top talent in the {industry} industry for a company with {number} employees. Provide examples of best practices from other organizations and assess their potential applicability to our company.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const HR_OPERATIONS_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'HR_OPERATIONS_PROMPT_TEMPLATES-1',
        title: 'Automate HR Processes',
        description: 'Identify automations for Human Resources operations.',
        userPrompt: 'As an HR operations expert, help me identify and implement automation solutions to streamline our HR processes, such as payroll, benefits administration, and employee onboarding, for a company with {number} employees in the {industry} industry.',
        labels: ['Human Resources', 'Operations'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_OPERATIONS_PROMPT_TEMPLATES-2',
        title: 'Improve HR Data Management',
        description: 'How to organize Human Resources data to improve decision making.',
        userPrompt: 'As an HR data analyst, develop a strategy to improve the collection, organization, and analysis of HR data to support decision-making and enhance employee experiences in a company with {number} employees in the {industry} industry.',
        labels: ['Human Resources', 'Operations'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_OPERATIONS_PROMPT_TEMPLATES-3',
        title: 'Enhance HR Technology',
        description: 'Human Resources software tools analysis and recommendation to improve efficiency.',
        userPrompt: 'As an HR technology specialist, evaluate and recommend HR software and tools to improve the efficiency and effectiveness of our HR operations, such as applicant tracking systems, performance management systems, and employee self-service portals, for a company with {number} employees in the {industry} industry.',
        labels: ['Human Resources', 'Operations'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_OPERATIONS_PROMPT_TEMPLATES-4',
        title: 'Optimize HR Reporting',
        description: 'Reporting framework for human resources reporting.',
        userPrompt: 'As an HR reporting specialist, develop a comprehensive HR reporting framework to provide valuable insights into workforce trends, employee engagement, and HR program effectiveness for a company with {number} employees in the {industry} industry.',
        labels: ['Human Resources', 'Operations'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const HR_EXPERIENCE_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'HR_EXPERIENCE_PROMPT_TEMPLATES-1',
        title: 'Foster Employee Engagement',
        description: 'Employee experience program that creates a positive environment.',
        userPrompt: 'As an employee experience designer, design an employee experience program that fosters {employee engagement/work-life balance/professional growth} in a company with {number} employees in the {industry} industry. Include specific initiatives, programs, and policies that will create a positive and supportive work environment.',
        labels: ['Human Resources', 'Employee Experience'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_EXPERIENCE_PROMPT_TEMPLATES-2',
        title: 'Enhance Onboarding',
        description: 'Enhanced onboarding process for great employee experience.',
        userPrompt: 'As an onboarding specialist, create a welcoming and inclusive onboarding process that helps new hires integrate seamlessly into our {company culture} in a company with {number} employees in the {industry} industry. Outline the steps involved, key touch-points, and resources available to new employees.',
        labels: ['Human Resources', 'Employee Experience'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_EXPERIENCE_PROMPT_TEMPLATES-3',
        title: 'Develop Performance Management',
        description: 'Performance management system for better employee evaluation.',
        userPrompt: 'As a performance management expert, develop a performance management system that is fair, transparent, and supports {employee development/goal achievement/career advancement} in a company with {number} employees in the {industry} industry. Define the performance evaluation process, criteria, and opportunities for feedback and growth.',
        labels: ['Human Resources', 'Employee Experience'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_EXPERIENCE_PROMPT_TEMPLATES-4',
        title: 'Design Employee Benefits',
        description: 'Employee benefit definition that meets company values and culture.',
        userPrompt: 'As an employee benefits consultant, design a comprehensive employee benefits package that meets the needs of our diverse workforce and promotes {employee health/financial well-being/work-life balance} in a company with {number} employees in the {industry} industry. Outline the benefits offered, eligibility requirements, and how they align with our company values.',
        labels: ['Human Resources', 'Employee Experience'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const HR_TALENT_ACQUISITION_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'HR_TALENT_ACQUISITION_PROMPT_TEMPLATES-1',
        title: 'Identify Target Candidates',
        description: 'Sourcing plan for target candidates.',
        userPrompt: 'As a sourcing manager, develop a sourcing plan that identifies the best channels to reach our {target candidates} in the {industry} industry for a company with {number} employees. Research and evaluate different sourcing methods, including job boards, social media, and employee referrals.',
        labels: ['Human Resources', 'Talent acquisition'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_TALENT_ACQUISITION_PROMPT_TEMPLATES-2',
        title: 'Screen Candidates Effectively',
        description: 'Steps to create a screening process that attracts the most qualified candidates.',
        userPrompt: 'As a recruiter, design a screening process that ensures we attract the most qualified candidates  in the {industry} industry for a company with {number} employees. Outline the screening criteria, assessment tools, and communication protocols to be used.',
        labels: ['Human Resources', 'Talent acquisition'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_TALENT_ACQUISITION_PROMPT_TEMPLATES-3',
        title: 'Write interview questions',
        description: 'Write interview questions for specific job titles and skills.',
        userPrompt: 'You are an experienced interviewer for {company name}, tasked with conducting an interview for a {Job title} position. Create a list of {number} interview questions that assess the candidate\'s proficiency in {skill 1}, {skill 2} and {skill 3}. Include at least one scenario-based question that evaluates the candidate\'s problem-solving abilities in a typical {job title} situation.',
        labels: ['Human Resources', 'Talent acquisition'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_TALENT_ACQUISITION_PROMPT_TEMPLATES-4',
        title: 'Boolean searches for Linkedin',
        description: 'Generate boolean searches for Linkedin to find professionals.',
        userPrompt: 'You are a recruiter specializing in the {industry} sector. Your task is to conduct a LinkedIn boolean search aimed at identifying {job title} professionals who boast a minimum of 5 years of experience within the fintech industry. These candidates should possess competencies in {skill 1}, {skill 2} and {skill 3}. Additionally, they must either be based in {city} or be open to relocating there. Focus on leveraging relevant keywords and phrases to refine your search effectively. Remember, your search should mirror a professional tone, ensuring clarity and precision in grammar and structure, avoiding redundancy and ambiguity.',
        labels: ['Human Resources', 'Talent acquisition'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'HR_TALENT_ACQUISITION_PROMPT_TEMPLATES-4',
        title: 'Write job description for Linkedin',
        description: 'Write a job description optimized for Linkedin based on job title and skills.',
        userPrompt: `You are a professional LinkedIn job advertisement writer. Create a compelling job post template for LinkedIn that incorporates variables for a specific job title, required skills, offered benefits, and other relevant information. The template should be easily customizable for various positions and industries. Include sections for:
An attention-grabbing headline with {job title} and {company name}
- A brief, engaging company description highlighting {adjective} company culture
- A clear job description outlining {key responsibilities 1, 2 and 3}
- A list of example day to day tasks like {tasks 1, 2 and 3}
- Reference to {preferred qualifications 1, 2 and 3}
- Details on work environment and growth opportunities
- An overview of  employee benefits such as {benefit 1, 2 and 3}
Ensure the language is inclusive, professional, concise, and optimized for LinkedIn's platform. Use action verbs and industry-specific terminology where appropriate. Incorporate keywords that will help the post appear in relevant job searches. The final template should be easily adaptable for different positions while maintaining a consistent, appealing structure that attracts top talent.`,
        labels: ['Human Resources', 'Talent acquisition'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const HR_PROMPT_TEMPLATES = [...HR_STRATEGY_PROMPT_TEMPLATES, ...HR_OPERATIONS_PROMPT_TEMPLATES, ...HR_EXPERIENCE_PROMPT_TEMPLATES, ...HR_TALENT_ACQUISITION_PROMPT_TEMPLATES]

const MARKETING_PRODUCT_MARKETING_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'MARKETING_PRODUCT_MARKETING_PROMPT_TEMPLATES-1',
        title: 'Product Positioning Analysis',
        description: 'Positioning analysis for a product existing in the market currently.',
        userPrompt: 'As a product marketing manager conduct a thorough analysis of {product name}\'s current positioning in the {industry} market. Identify its key differentiators, {target audience}, and competitive landscape. Determine areas for improvement and develop a strategy to enhance our position.',
        labels: ['Marketing', 'Product Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_PRODUCT_MARKETING_PROMPT_TEMPLATES-2',
        title: 'Target Market Segmentation',
        description: 'Step-by-step instructions of how to build a target market segmentation.',
        userPrompt: 'You are a go-to-market strategist tasked with segmenting our target market into distinct groups based on demographics, psychographics, and behavior. Write the steps to develop detailed profiles for each segment, identifying their specific needs, preferences, and pain points.',
        labels: ['Marketing', 'Product Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_PRODUCT_MARKETING_PROMPT_TEMPLATES-3',
        title: 'Product Launch Plan',
        description: 'Draft a launch plan for a product that includes budget, distribution channels and promotional activities.',
        userPrompt: 'You are a product marketing manager responsible for developing a detailed plan for the successful launch of {product name}. Determine the target launch date, {marketing budget}, {distribution channels}, and {promotional activities}. Create a timeline for each stage of the launch process and assign responsibilities to team members.',
        labels: ['Marketing', 'Product Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_PRODUCT_MARKETING_PROMPT_TEMPLATES-4',
        title: 'Go-to-Market Strategy',
        description: 'Go to market strategy for a product based on value proposition, strategy and other relevant items.',
        userPrompt: 'As a product marketing manager develop a go-to-market strategy for {product name}. Define the target market, value proposition, competitive advantages, pricing strategy, and distribution channels. Outline the key marketing initiatives and tactics to drive product adoption and growth.',
        labels: ['Marketing', 'Product Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const MARKETING_CONTENT_MARKETING_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'MARKETING_CONTENT_MARKETING_PROMPT_TEMPLATES-1',
        title: 'Content Strategy Development',
        description: 'Step by step content strategy description including target marked, industry and competitor variables.',
        userPrompt: 'Craft a comprehensive brief that outlines a systematic approach for generating new content ideas. Include steps for leveraging market research, competitor analysis, and current trends to identify content gaps and opportunities. Detail processes for brainstorming sessions, including tools and methodologies for idea generation and selection. Specify variables such as {target market}, {industry trends}, and {competitor names}.',
        labels: ['Marketing', 'Content'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_CONTENT_MARKETING_PROMPT_TEMPLATES-2',
        title: 'Audience Analysis and Segmentation',
        description: 'Guide on how to perform an in-depth audience analysis.',
        userPrompt: 'Develop a guide that describes how to perform an in-depth audience analysis to better understand {target demographics}, their preferences, pain points, and behaviors. Include instructions on utilizing analytics tools, social media insights, and market surveys to segment the audience effectively. Explain how this segmentation can inform content customization and personalization strategies with variables like {social media platforms} and {analytics tools}.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_CONTENT_MARKETING_PROMPT_TEMPLATES-3',
        title: 'Content Strategy Framework',
        description: 'Outline the components necessary for a strong content strategy.',
        userPrompt: 'Outline the components necessary to create a robust content strategy. Include considerations for setting clear objectives, defining the brand voice, mapping out the content calendar, and aligning content types with audience segments. Provide a step-by-step approach for integrating SEO practices throughout the content creation process and suggest metrics for measuring content effectiveness with variables such as {content types} and {SEO practices}.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_CONTENT_MARKETING_PROMPT_TEMPLATES-4',
        title: 'SEO Optimization Techniques',
        description: 'Plan to optimize existing and new content for search engines.',
        userPrompt: 'Create a detailed plan for optimizing existing and new content for search engines. Discuss keyword research tools and strategies, the importance of meta tags (title, description, and keywords), URL structure, and the role of backlinking. Highlight the significance of mobile optimization and page loading speed. Offer guidance on staying updated with Google\'s algorithm changes and best practices for SEO with variables like {keyword research tools}.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_CONTENT_MARKETING_PROMPT_TEMPLATES-5',
        title: 'Content Performance Tracking and Analysis',
        description: 'Protocol to track content campaigns.',
        userPrompt: 'Formulate a protocol for tracking the performance of content campaigns, detailing the key performance indicators (KPIs) to monitor, such as web traffic, engagement rates, conversion rates, and SEO rankings. Recommend tools and platforms for gathering and analyzing data. Provide a framework for interpreting these metrics, making data-driven decisions, and iteratively improving content strategy based on performance feedback with variables such as {KPIs}.',
        labels: ['Human Resources', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const MARKETING_BRANDING_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'MARKETING_BRANDING_PROMPT_TEMPLATES-1',
        title: 'Crafting a Clear and Compelling Brand Definition',
        description: 'Clear and compelling brand definition based on target audience, mission and USP.',
        userPrompt: 'Reflect on our current brand identity and its perception among our internal stakeholders and {target audience}. How can we refine our brand\'s definition to ensure it communicates our {core values}, {mission}, and {unique selling propositions} more clearly and compellingly? Consider the emotional connection and the narrative you want to establish with {target audience}. Draft a proposal that outlines potential adjustments or redefinitions, supported by insights from existing market research.',
        labels: ['Human Resources', 'Brand'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_BRANDING_PROMPT_TEMPLATES-2',
        title: 'Analyzing Brand Engagement Across Channels',
        description: 'Step by step instructions to analyze brand engagement.',
        userPrompt: 'Explain the steps to do a comprehensive analysis of our brand\'s engagement across all our marketing channels ({list channels such as social media, email, website}). Identify where we are seeing the highest engagement and analyze why these channels are successful. Conversely, pinpoint the channels where engagement is lacking and propose hypotheses on potential reasons. Develop a plan to experiment with different content types, messaging, or strategies to enhance engagement across underperforming channels, drawing on best practices and innovative tactics from {successful brands in our industry}.',
        labels: ['Human Resources', 'Brand'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_BRANDING_PROMPT_TEMPLATES-3',
        title: 'Measuring the Impact of Brand Marketing Campaigns',
        description: 'Framework to measure the impact of a brand based on business objectives. ',
        userPrompt: 'Develop a framework for measuring the impact of our brand marketing campaigns on our overall business objectives. This should include both qualitative and quantitative metrics, such as {brand awareness}, {perception}, {customer satisfaction}, and direct contributions to {sales} or other key performance indicators. Propose {tools or software} that could facilitate this measurement, and suggest a regular reporting rhythm to track progress over time. How can this data be fed back into our strategy to make ongoing improvements?',
        labels: ['Human Resources', 'Brand'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const MARKETING_PERFORMANCE_MARKETING_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'MARKETING_PERFORMANCE_MARKETING_PROMPT_TEMPLATES-1',
        title: 'Creating a Messaging Campaign on LinkedIn',
        description: 'Linkedin messaging campaign based on target audience, industry and product.',
        userPrompt: 'Detail a comprehensive plan for a LinkedIn messaging campaign targeting {Target Audience} in the {Industry}. Focus on creating messages that highlight the unique benefits of {Product/Service}, using professional language tailored to the platform. Include strategies for using LinkedIn\'s targeting tools to reach the right demographic, and outline methods for tracking the campaign\'s success through metrics such as engagement rates and conversion to trial sign-ups. (Estimated word count: 800-1,200 words)',
        labels: ['Human Resources', 'Performance Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_PERFORMANCE_MARKETING_PROMPT_TEMPLATES-2',
        title: 'Generating Prospect Leads for a SaaS Product',
        description: 'Strategy framework to generate leads including content marketing and SEO.',
        userPrompt: 'Develop a strategic outline for generating high-quality leads for {SaaS Product} through a multi-channel approach. This should include content marketing, SEO, and targeted social media advertising tailored to {Target Audience}. Describe how to create keyword-rich content that addresses the audience\'s needs, applying SEO best practices for visibility, and crafting social media ads with compelling CTAs for {SaaS Product}. Include metrics for tracking lead quality, engagement, and conversion rates. (Estimated word count: 1,200-1,800 words)',
        labels: ['Human Resources', 'Performance Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_PERFORMANCE_MARKETING_PROMPT_TEMPLATES-3',
        title: 'Utilizing Influencer Marketing to Expand SaaS Product Reach',
        description: 'Identify influencers to partner with based on industry and product.',
        userPrompt: 'Outline a plan for identifying and partnering with influencers in the {Specific Industry/Niche} who align with {SaaS Product}\'s brand values. Detail a content strategy that includes product reviews, tutorials, and case studies, utilizing trackable links or promo codes to measure referral traffic and conversions. Describe the process for analyzing the effectiveness and ROI of these partnerships. (Estimated word count: 800-1,200 words)',
        labels: ['Human Resources', 'Performance Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MARKETING_PERFORMANCE_MARKETING_PROMPT_TEMPLATES-4',
        title: 'Optimizing Website Conversion Rate for SaaS Sales',
        description: 'Guide to optimize website conversion rate based on product type.',
        userPrompt: 'Create a detailed guide for optimizing the website conversion rate of {SaaS Product}, focusing on UX design principles. Include testing different layouts, images, and CTAs through A/B testing, incorporating testimonials, and setting up analytics to track visitor behavior and conversion paths. Offer strategies for data-driven adjustments to improve conversion rates continually. (Estimated word count: 1,000-1,500 words)',
        labels: ['Human Resources', 'Performance Marketing'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const MARKETING_PROMPT_TEMPLATES = [...MARKETING_PRODUCT_MARKETING_PROMPT_TEMPLATES, ...MARKETING_CONTENT_MARKETING_PROMPT_TEMPLATES, ...MARKETING_BRANDING_PROMPT_TEMPLATES, ...MARKETING_PERFORMANCE_MARKETING_PROMPT_TEMPLATES]


const MEDIA_CONTENT_CREATOR_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'MEDIA_CONTENT_CREATOR_PROMPT_TEMPLATES-1',
        title: 'Create Engaging Social Media Content',
        description: 'Post for social media to engage with a specific type of target.',
        userPrompt: 'As a content creator, write a captivating social media post for {target audience} that will intrigue {specific interest or problem area} and drive engagement. Include a hook, {number of relevant hashtags} relevant hashtags, and a call to action about {desired action}.',
        labels: ['Media', 'Content creator'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_CONTENT_CREATOR_PROMPT_TEMPLATES-2',
        title: 'Craft a Compelling Blog Post',
        description: 'Blog post optimized for SEO about a specific topic.',
        userPrompt: 'Step into the role of a content creator and craft a compelling blog post on {topic} that educates and informs my readers about {specific aspect of topic}. Focus on providing valuable insights and optimizing for SEO with {number of keywords} keywords.',
        labels: ['Media', 'Content creator'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_CONTENT_CREATOR_PROMPT_TEMPLATES-3',
        title: 'Develop a Script for a Video',
        description: 'Video script with strong call to action.',
        userPrompt: 'Assume the role of a content creator and develop a script for a {length of video} video that will engage {target audience} and convey {core message} effectively. Include a clear storyline, {number of visual cues} engaging visuals, and a strong call to action regarding {desired action}.',
        labels: ['Media', 'Content creator'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_CONTENT_CREATOR_PROMPT_TEMPLATES-4',
        title: 'Create a Visually Stunning Infographic',
        description: 'Brief for an infographic on with captivating visuals, relevant statistics and clear design.',
        userPrompt: 'As a content creator, write the brief and steps to design a visually stunning infographic on {topic} that presents {specific data points} in a clear and concise manner. Use captivating visuals, {number of fonts} eye-catching fonts, and {number of statistics} relevant statistics.',
        labels: ['Media', 'Content creator'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_CONTENT_CREATOR_PROMPT_TEMPLATES-5',
        title: 'Brainstorm Creative Ideas for a Podcast',
        description: 'Brainstorm ideas for a podcast based on a topic and target audience. ',
        userPrompt: 'Step into the shoes of a content creator and brainstorm creative ideas for a podcast on {topic} that will attract and engage {target audience}. Consider the topic, format, {number of guest speakers} guest speakers, and promotional strategies involving {channels for promotion}.',
        labels: ['Media', 'Content creator'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const MEDIA_EDITOR_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'MEDIA_EDITOR_PROMPT_TEMPLATES-1',
        title: 'Develop a Comprehensive Media Plan',
        description: 'Develop media plan: audience analysis, channels, budget, performance metrics tracking.',
        userPrompt: 'Assume the role of a media strategist and develop a comprehensive media plan for {campaign/objective}. Include target audience analysis of {target audience specifics}, channel selection including {number of channels}, budget allocation of {budget amount}, and performance tracking metrics focused on {specific metrics}.',
        labels: ['Media', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_EDITOR_PROMPT_TEMPLATES-2',
        title: 'Analyze Media Performance',
        description: 'Analyze media campaigns: reach, engagement, conversion, improvements, data-driven recommendations.',
        userPrompt: 'Step into the role of a media strategist and analyze the performance of {recent media campaigns}. Assess the reach, {engagement metrics}, and {conversion metrics}. Identify areas for improvement and make data-driven recommendations based on {specific data points}.',
        labels: ['Media', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_EDITOR_PROMPT_TEMPLATES-3',
        title: 'Identify Emerging Media Trends',
        description: 'Identify media trends: research platforms, technologies, consumer behaviors, actionable insights.',
        userPrompt: 'Assume the role of a media strategist and identify emerging media trends that could impact {industry}. Research {number of new platforms} new platforms, technologies, and consumer behaviors. Provide actionable insights and recommendations for adaptation to {specific action or strategy}.',
        labels: ['Media', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_EDITOR_PROMPT_TEMPLATES-4',
        title: 'Create a Social Media Marketing Strategy',
        description: 'Create social media strategy: target audience, content types, engagement tactics, brand.',
        userPrompt: 'Step into the role of a media strategist and create a social media marketing strategy for {brand or product}. Determine target audience including {demographics}, content strategy involving {number of content types}, and engagement tactics including {specific engagement actions}.',
        labels: ['Media', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'MEDIA_EDITOR_PROMPT_TEMPLATES-5',
        title: 'Develop a Content Distribution Plan',
        description: 'Develop content distribution plan: channels, optimization, tracking, maximize reach, impact.',
        userPrompt: 'Assume the role of a media strategist and develop a content distribution plan to maximize the reach and impact of {type of content}. Identify {number of distribution channels} distribution channels, optimize content for each platform based on {optimization criteria}, and track results with {tracking tools/methods} to improve performance.',
        labels: ['Media', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

// Skip this for now
// const MEDIA_MEDIA_STRATEGIST_PROMPT_TEMPLATES: Prompt[] = [
// 	{
//      id: 'MEDIA_MEDIA_STRATEGIST_PROMPT_TEMPLATES-1',
// 		title: 'Edit a Blog Post for Clarity and Conciseness',
// 		description: '',
// 		userPrompt: 'Step into the role of an editor and edit a blog post on {topic} to improve clarity, conciseness, and readability. Ensure logical flow, correct grammar and punctuation, and adherence to {style guidelines} style guidelines.',
// 		labels: ['Media', 'Editor'],
// 		aiPrompt: ''
// 	},
// 	{
//      id: 'MEDIA_MEDIA_STRATEGIST_PROMPT_TEMPLATES-2',
// 		title: 'Proofread a Marketing Brochure',
// 		description: '',
// 		userPrompt: 'Assume the role of an editor and proofread a marketing brochure for {product/service} to eliminate errors in grammar, spelling, punctuation, and formatting. Check for consistency in tone, style, and {brand\'s messaging guidelines} brand messaging.',
// 		labels: ['Media', 'Editor'],
// 		aiPrompt: ''
// 	},
// 	{
//      id: 'MEDIA_MEDIA_STRATEGIST_PROMPT_TEMPLATES-3',
// 		title: 'Critique a Video Script for Effectiveness',
// 		description: '',
// 		userPrompt: 'Step into the role of an editor and provide a framework to critique a video script for {purpose of video} on its effectiveness in conveying {intended message}. Assess storytelling, pacing, dialogue, and visual appeal. Provide constructive feedback and recommendations for improvement based on {specific criteria}.',
// 		labels: ['Media', 'Editor'],
// 		aiPrompt: ''
// 	},
// 	{
//      id: 'MEDIA_MEDIA_STRATEGIST_PROMPT_TEMPLATES-4',
// 		title: 'Edit a Podcast Episode for Flow and Engagement',
// 		description: '',
// 		userPrompt: 'Assume the role of an editor and explain the steps to edit a podcast episode on {topic} to improve its flow, engagement, and overall listener experience. Remove unnecessary pauses, enhance sound quality, and add {number of music or sound effects} to enhance the narrative.',
// 		labels: ['Media', 'Editor'],
// 		aiPrompt: ''
// 	},
// 	{
//      id: 'MEDIA_MEDIA_STRATEGIST_PROMPT_TEMPLATES-5',
// 		title: 'Fact-Check an Article for Accuracy',
// 		description: '',
// 		userPrompt: 'Step into the role of an editor and write the steps of how to fact-check an article on {topic} to ensure its accuracy and credibility. Verify {number of facts} facts, cite {number of sources} sources, and identify any potential biases or inaccuracies. Ensure the article adheres to {journalistic standards and ethical guidelines}.',
// 		labels: ['Media', 'Editor'],
// 		aiPrompt: ''
// 	}
// ]

const MEDIA_PROMPT_TEMPLATES = [...MEDIA_CONTENT_CREATOR_PROMPT_TEMPLATES, ...MEDIA_EDITOR_PROMPT_TEMPLATES]


const EDUCATION_STAFF_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'EDUCATION_STAFF_PROMPT_TEMPLATES-1',
        title: 'Education strategic Planning',
        description: 'Strategic planning template for education leaders to set goals and initiatives.',
        userPrompt: 'Develop a comprehensive strategic planning template that guides {education leaders} through the process of setting long-term goals, identifying key initiatives, and outlining implementation steps. This can help ensure strategic plans are well-structured and actionable.',
        labels: ['Education', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STAFF_PROMPT_TEMPLATES-2',
        title: 'Data-Driven Decision Making',
        description: 'Framework for analyzing key performance indicators to inform data-driven decisions.',
        userPrompt: 'Create a framework for systematically collecting, analyzing, and interpreting key {performance indicators} related to {student outcomes}, {enrollment}, {faculty productivity}, and other {institutional metrics}. Use these insights to inform data-driven decision making.',
        labels: ['Education', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STAFF_PROMPT_TEMPLATES-3',
        title: 'Curriculum Mapping and Alignment',
        description: 'Curriculum mapping tool for visualizing program structure and ensuring alignment.',
        userPrompt: 'Develop a curriculum mapping tool that allows {education leaders} to visualize how {program} and {course} offerings are structured, identify gaps or redundancies, and ensure alignment with {accreditation standards} and {industry needs}.',
        labels: ['Education', 'Strategy'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STAFF_PROMPT_TEMPLATES-4',
        title: 'Streamlining Reporting and Compliance',
        description: 'Templates and checklists for compliance reporting and documenting policy changes.',
        userPrompt: 'Design templates and checklists for compiling required {reports}, documenting {policy changes}, and demonstrating compliance with {regulatory bodies}. This can help ensure {education institutions} meet all necessary guidelines and standards.',
        labels: ['Education'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STAFF_PROMPT_TEMPLATES-5',
        title: 'Improving Campus-Wide Communication',
        description: 'Communication templates for sharing updates, soliciting feedback, and fostering transparency.',
        userPrompt: 'Design {communication templates} and {channels} that allow {education leaders} to efficiently share important {updates}, solicit {feedback}, and foster {transparency} across the institution. This can help strengthen {engagement} and {collaboration}.',
        labels: ['Education', 'Communication'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STAFF_PROMPT_TEMPLATES-6',
        title: 'Streamlining Accreditation Processes',
        description: 'Accreditation toolkit guiding institutions through self-study, peer review, and continuous improvement processes',
        userPrompt: 'Create a comprehensive {accreditation toolkit} that guides {education institutions} through the {self-study}, {peer review}, and {continuous improvement} processes. This can help ensure ongoing compliance and a culture of {quality assurance}.',
        labels: ['Education'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const EDUCATION_RESEARCH_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-1',
        title: 'Identify Knowledge Gaps (Topic Agnostic)',
        description: 'Comprehensive literature review to uncover knowledge gaps in the field.',
        userPrompt: 'Conduct a comprehensive literature review using relevant databases and search terms {e.g., Google Scholar, PubMed}. Analyze the identified literature to pinpoint areas where knowledge is lacking, considering both explicit gaps and potential research opportunities.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-2',
        title: 'Develop a Research Plan with Clear Objectives and Milestones',
        description: 'Define research objectives, methods, timeline, and mitigate potential risks.',
        userPrompt: 'Define the overarching research question and specific objectives. Outline a detailed methodology, including data collection and analysis methods {e.g., surveys, experiments, qualitative interviews}. Establish a realistic timeline with clear milestones and deliverables. Identify potential risks and mitigation strategies.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-3',
        title: 'Explore State-of-the-Art Techniques in a Specific Domain',
        description: 'Analyze trends and best practices in the research domain.',
        userPrompt: 'Identify the most recent advancements, emerging trends, and best practices in a specific research domain {e.g., machine learning, climate science, education}. Analyze the strengths and limitations of different techniques, considering their applicability to the research question.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-4',
        title: 'Identify Potential Collaborators with Complementary Expertise',
        description: 'Collaborate with experts to complement the research project.',
        userPrompt: 'Utilize professional networks, research databases, and online platforms {e.g., LinkedIn, ResearchGate} to identify individuals or institutions with expertise that complements the research project. Establish criteria for collaboration {e.g., relevant skills, experience, location} and develop a strategy for reaching out to potential partners.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-5',
        title: 'Optimize Data Collection and Analysis for Robust Insights',
        description: 'Design reliable data collection and analysis techniques.',
        userPrompt: 'Design data collection protocols that ensure reliability and validity {e.g., sampling methods, data collection instruments}. Select appropriate data analysis tools and techniques based on the research question and data type {e.g., statistical software, qualitative analysis software}. Implement quality control measures to minimize bias and error.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-6',
        title: 'Communicate Research Findings Effectively to Diverse Audiences',
        description: 'Devise a communication plan to effectively share research findings.',
        userPrompt: 'Develop a communication plan that considers the target audience {e.g., academic researchers, policymakers, industry professionals}, desired outcomes {e.g., knowledge dissemination, policy change, product development}, and appropriate channels for dissemination {e.g., journals, conferences, social media}. Craft clear and concise messages, utilizing visual aids and storytelling techniques to engage audiences.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-7',
        title: 'Plan for Research Dissemination through Multiple Channels',
        description: 'Identify suitable platforms for research publication and public engagement.',
        userPrompt: 'Identify potential journals {e.g., high-impact journals, specialized journals}, conferences {e.g., international conferences, regional workshops}, and other platforms {e.g., open access repositories, online forums} for disseminating research findings. Consider open access options and explore opportunities for public engagement and outreach. Develop a dissemination timeline and strategy for maximizing the impact of the research.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_RESEARCH_PROMPT_TEMPLATES-8',
        title: 'Identify Funding Opportunities Aligned with Research Goals',
        description: 'Research and apply for funding sources aligned with objectives.',
        userPrompt: 'Research and identify funding sources that align with the research objectives and budget requirements {e.g., government grants, foundation grants, industry sponsorships}. Analyze funding criteria {e.g., research area, eligibility requirements}, deadlines {e.g., submission deadlines, review deadlines}, and application processes. Develop a compelling proposal that clearly articulates the research significance and potential impact.',
        labels: ['Research'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const EDUCATION_TEACHERS_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'EDUCATION_TEACHERS_PROMPT_TEMPLATES-1',
        title: 'Improving Class Evaluation',
        description: 'Comprehensive evaluation using diverse methods to assess student learning.',
        userPrompt: 'You are a professor teaching an undergraduate course in {subject area}. Please develop a comprehensive evaluation plan to assess student learning and engagement in your course. Consider incorporating a variety of assessment methods, such as in-class participation, written assignments, projects, and exams. Ensure the evaluation aligns with the course\'s learning objectives and provides meaningful feedback to students. Use a professional and objective tone when crafting the evaluation plan. Avoid ambiguity by clearly outlining the evaluation criteria and grading rubrics.',
        labels: ['Education', 'Teaching'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_TEACHERS_PROMPT_TEMPLATES-2',
        title: 'Enhancing Teaching Methods',
        description: 'Propose innovative teaching strategies for improved student engagement.',
        userPrompt: 'You are a faculty member in the {department/school} at the university. Please research and propose innovative teaching strategies that could be implemented to improve student learning and engagement in your discipline. Consider incorporating active learning techniques, technology-enhanced instruction, and opportunities for collaborative learning. Provide a detailed plan outlining the proposed teaching methods, their intended learning outcomes, and how they can be effectively integrated into your course curriculum. Write the proposal using a formal and persuasive tone to convince the department or curriculum committee.',
        labels: ['Education', 'Teaching'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_TEACHERS_PROMPT_TEMPLATES-3',
        title: 'Developing a New Course',
        description: 'Design new course addressing emerging trends, interdisciplinary topics.',
        userPrompt: 'You are a department chair in the {department/school} at the university. Please design a new course that addresses emerging trends or interdisciplinary topics in your field. Clearly define the course\'s learning objectives, target audience, and potential impact on the academic program. Outline the course\'s structure, including the topics to be covered, teaching methods, and assessment strategies. Present the course proposal in a well-organized and professional manner, highlighting its potential to enhance the department\'s curriculum and student learning experiences.',
        labels: ['Education', 'Teaching'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_TEACHERS_PROMPT_TEMPLATES-3',
        title: 'Improving Student Engagement',
        description: 'Plan to increase student engagement, retention, and academic success.',
        userPrompt: 'You are a student success coordinator at the university. Please develop a plan to increase student engagement and retention in {specific academic program or department}. Consider strategies such as fostering a sense of community, providing mentorship opportunities, and implementing extracurricular activities. Analyze existing data and identify areas for improvement to support student success and academic achievement. Write the plan using a professional and solution-oriented tone, emphasizing the benefits to both students and the university.',
        labels: ['Education', 'Teaching'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_TEACHERS_PROMPT_TEMPLATES-4',
        title: 'Enhancing University-Industry Partnerships',
        description: 'Propose partnerships to enhance student learning and faculty research.',
        userPrompt: 'You are the director of the university\'s Office of Industry Engagement. Please create a proposal to establish new or strengthen existing partnerships between the university and local/regional industry leaders. Identify potential areas of collaboration, such as internships, research projects, or joint educational programs. Outline the mutual benefits of these partnerships, including how they can enhance student learning, faculty research, and the university\'s reputation. Present the proposal in a professional and persuasive manner, highlighting the strategic value of these partnerships for the university and its stakeholders.',
        labels: ['Education', 'Teaching'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const EDUCATION_STUDENTS_PROMPT_TEMPLATES: Prompt[] = [
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-1',
        title: 'Mind Mapping for Task Organization',
        description: 'Visual task organization using mind maps with central and subtopics.',
        userPrompt: 'Use mind mapping to visually organize your tasks by creating a central topic ({Topic}) and connecting it to subtopics and details. Identify relationships and dependencies between tasks to prioritize and plan more effectively. Consider using different colors, shapes, and annotations to enhance visual clarity and comprehension.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-2',
        title: 'Time Blocking for Focused Productivity',
        description: 'Time blocking to minimize distractions and maximize task concentration.',
        userPrompt: 'Divide your day into specific time blocks dedicated to different tasks ({Tasks}). Focus on one task at a time during each block to minimize distractions and maximize concentration. Experiment with different time intervals and block durations to find what works best for your work style and preferences.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-3',
        title: 'Pomodoro Technique for Time Management',
        description: 'Focused work intervals followed by breaks to maintain productivity.',
        userPrompt: 'Work in intervals of focused time ({Focused Time}) followed by short breaks ({Break Time}). This technique helps maintain focus and prevents burnout. Adjust the time intervals and break duration based on your individual needs and preferences.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-4',
        title: 'Kanban Board for Task Visualization',
        description: 'Kanban board for visual task tracking and workflow management.',
        userPrompt: 'Create a visual representation of your tasks using a Kanban board. Divide the board into columns representing different stages of completion ({Stages}). Move tasks through the columns as they progress. Customize the board layout, column names, and color coding to suit your specific workflow and preferences.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-5',
        title: 'Eisenhower Matrix for Task Prioritization',
        description: 'Task prioritization using urgency and importance grid or diagram.',
        userPrompt: 'Categorize your tasks based on urgency and importance ({Urgency}, {Importance}). Focus on completing tasks in the "Urgent and Important" quadrant first. Consider using a visual representation, such as a grid or quadrant diagram, to enhance clarity and decision-making.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-6',
        title: 'SMART Goal Setting for Task Clarity',
        description: 'SMART criteria to ensure tasks are specific, measurable, achievable.',
        userPrompt: 'Define your tasks using the SMART criteria: Specific ({Specific}), Measurable ({Measurable}), Achievable ({Achievable}), Relevant ({Relevant}), and Time-Bound ({Time-Bound}). This ensures that your tasks are well-defined and achievable. Use specific metrics, timelines, and milestones to track progress and evaluate outcomes.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-7',
        title: 'Task Automation for Efficiency',
        description: 'Automate routine tasks using software to focus on creative work.',
        userPrompt: 'Identify tasks that can be automated using software or tools ({Tasks}). This frees up time to focus on more complex or creative tasks. Explore a range of automation tools and techniques, including workflow automation, data entry automation, and scheduling tools.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    },
    {
        id: 'EDUCATION_STUDENTS_PROMPT_TEMPLATES-8',
        title: 'Delegate for Collaboration',
        description: 'Delegate tasks to distribute workload and leverage team expertise.',
        userPrompt: 'If feasible, delegate tasks to others ({Tasks}, {Others}) to distribute workload and leverage their expertise. Provide clear instructions and expectations to ensure successful task completion. Establish clear communication channels and feedback mechanisms to facilitate effective collaboration and knowledge sharing.',
        labels: ['Education', 'Students'],
        aiPrompt: '',
        isPublic: true,
        isTemplate: true
    }
]

const EDUCATION_PROMPT_TEMPLATES = [...EDUCATION_STAFF_PROMPT_TEMPLATES, ...EDUCATION_RESEARCH_PROMPT_TEMPLATES, ...EDUCATION_TEACHERS_PROMPT_TEMPLATES, ...EDUCATION_STUDENTS_PROMPT_TEMPLATES,]

export const PROMPT_TEMPLATES: Prompt[] = [...HR_PROMPT_TEMPLATES, ...MARKETING_PROMPT_TEMPLATES, ...MEDIA_PROMPT_TEMPLATES, ...EDUCATION_PROMPT_TEMPLATES]