import {
    markPromptAsFavourite,
    removePromptAsFavourite
} from '../service/persistenceService'
import {useCallback} from 'react'
import {usePromptsContext} from '../context/PromptsContext'
import {Prompt} from '../types/Prompt'
import {FavouritePrompt} from '../../cdk/src/types/FavouritePrompt'

export type UsePromptFavouriteValue = {
    makeFavourite: (prompt: Prompt) => Promise<Prompt>
    removeFavourite: (prompt: Prompt, favouriteId: string) => Promise<void>
}

export const usePromptFavourite = (userToken: string): UsePromptFavouriteValue => {

    const {setFavourites} = usePromptsContext()

    const makeFavourite = useCallback((prompt: Prompt): Promise<Prompt> => {
        return new Promise((resolve, reject) => {
            if (prompt.id) markPromptAsFavourite(prompt.id, userToken)
                .then((favouriteInfo: FavouritePrompt) => {
                    setFavourites(previousFavsPrompts => [...previousFavsPrompts, {
                        ...prompt,
                        favouriteId: favouriteInfo.id
                    }])
                    resolve({...prompt, favouriteId: favouriteInfo.id})
                })
            else resolve(prompt)
        })
    }, [userToken, setFavourites])

    const removeFavourite = useCallback((prompt: Prompt, favouriteId: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            if (prompt.id && favouriteId) removePromptAsFavourite(prompt.id, favouriteId, userToken)
                .then(() => {
                    setFavourites((previousFavsPrompts) =>
                        previousFavsPrompts.filter(favouritePrompt => favouritePrompt.id !== prompt.id))
                    resolve()
                })
            else resolve()
        })
    }, [userToken, setFavourites])

    return {makeFavourite, removeFavourite}
}