import {Box, Button, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {RoundIcon} from '../../common/roundIcon/RoundIcon'
import LockIcon from '@mui/icons-material/Lock'
import './AdminErrorScreen.scss'

export const AdminErrorScreen = () => {

    const navigate = useNavigate()

    return (
        <Box className='adminErrorScreenWrapper'>
            <Box className='adminErrorScreenContainer'>
                <RoundIcon icon={LockIcon} size={200} iconSize={100}/>
                <Typography variant='h3'>Oops! It seems like you don’t have access to this section of Narus </Typography>
                <Typography variant='h5'>Please contact the administrator to request admin level access.</Typography>
                <Button onClick={() => navigate('/')}>Go to home</Button>
            </Box>
        </Box>
    )
}