import {createContext, FC, PropsWithChildren, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {useUserContext} from './UserContext'
import {getUserHasBudgetRemaining} from '../service/persistenceService'

type UserBudgetContextValue = {
	hasBudgetRemaining: boolean
}

const DEFAULT_USER_BUDGET_CONTEXT: UserBudgetContextValue = {
	hasBudgetRemaining: false
}

export const UserBudgetContext = createContext<UserBudgetContextValue>(DEFAULT_USER_BUDGET_CONTEXT)

export const useUserBudgetContext = () => useContext(UserBudgetContext)

export const UserBudgetContextProvider: FC<PropsWithChildren> = ({children}) => {
	const {token} = useUserContext()

	const [hasBudgetRemaining, setHasBudgetRemaining] = useState<boolean>(true)

	const value: UserBudgetContextValue = useMemo(() => ({
		hasBudgetRemaining,
	}), [hasBudgetRemaining])

	const getUserBudgetRemaining = useCallback(async () => {
		const budgetRemaining = await getUserHasBudgetRemaining(token)
		setHasBudgetRemaining(budgetRemaining)
	}, [token])

	useEffect(() => {
		getUserBudgetRemaining()
	}, [getUserBudgetRemaining])

	return <UserBudgetContext.Provider value={value}>
		{children}
	</UserBudgetContext.Provider>
}