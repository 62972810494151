/**
 * Returns the number of days left until the given Unix timestamp.
 * @param timestamp - A Unix timestamp.
 * @returns The number of days left until the given timestamp.
 */
export const getDaysUntil = (timestamp: number): number => {
    const now = new Date()
    const targetDate = new Date(timestamp * 1000)

    const timeDifference = targetDate.getTime() - now.getTime()
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24)
    return Math.floor(daysDifference)
}

export const getClosestMonthlyPeriodDate = (startDate: Date, monthInterval: number): Date => {
    const currentDate = new Date()
    const periodDate = new Date(startDate)

    while (periodDate < currentDate) {
        periodDate.setMonth(periodDate.getMonth() + monthInterval)
    }
    const previousPeriodDate = new Date(periodDate)
    previousPeriodDate.setMonth(previousPeriodDate.getMonth() - monthInterval)

    const diffAfter = periodDate.getTime() - currentDate.getTime()
    const diffBefore = currentDate.getTime() - previousPeriodDate.getTime()

    return diffAfter < diffBefore ? periodDate : previousPeriodDate
}