import {createContext, FC, PropsWithChildren, useCallback, useContext, useMemo, useState} from 'react'
import {AppMenuItem} from '../types/AppMenu'
import {TrackActionEvent} from '../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useSearchContext} from './SearchContext'
import {useAppNavigationContext} from './AppNavigationContext'

export type AppMenuContextValue = {
    isOpen: boolean
    open: () => void
    close: () => void
    toggle: () => void
    selectMenuItem: (item: AppMenuItem) => void
}

const DEFAULT_APP_MENU_CONTEXT: AppMenuContextValue = {
    isOpen: true,
    open: () => {},
    close: () => {},
    toggle: () => {},
    selectMenuItem: () => {}
}

const AppMenuContext = createContext<AppMenuContextValue>(DEFAULT_APP_MENU_CONTEXT)

export const useAppMenuContext = () => useContext(AppMenuContext)

export const AppMenuContextProvider: FC<PropsWithChildren> = ({
    children
}) => {
	const {navigate} = useAppNavigationContext()
	const {user} = useUser()
	const {clearSearch} = useSearchContext()

    const [isOpen, setIsOpen] = useState(true)
    const userId = user?.externalId ?? user?.id

    const selectMenuItem = useCallback((item: AppMenuItem) => {
        TrackActionEvent('Menu clicked', userId, {path: item.navigateTo ?? item.title})
        if (item.navigateTo !== undefined) {
            clearSearch()
            navigate(item.navigateTo)
        }
    }, [userId, clearSearch, navigate])
    
    const value: AppMenuContextValue = useMemo(() => ({
        isOpen,
        open: () => setIsOpen(true),
        close: () => setIsOpen(false),
        toggle: () => setIsOpen(prev => !prev),
        selectMenuItem
    }), [isOpen, selectMenuItem])

    return <AppMenuContext.Provider value={value}>
        {children}
    </AppMenuContext.Provider>
}