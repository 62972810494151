import {Box, Grid, Typography} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import './NoModelsEnabledError.scss'
import { RoundIcon } from '../common/roundIcon/RoundIcon'

export const NoModelsEnabledError = () => <Grid container className='noModelsEnabledPanel'>
	<Box className='noModelsEnabledErrorWrapper'>
		<Box className='noModelsEnabledErrorContainer'>
			<RoundIcon icon={ErrorIcon} size={200}/>
			<Typography variant='h3'>There are no AI models enabled for you</Typography>
			<Typography variant='h5'>Please contact your administrator and ask them to enable at least one AI model for you to use Narus.</Typography>
		</Box>
	</Box>
</Grid>