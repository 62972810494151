import { PopoverOrigin, Select, SelectProps } from '@mui/material'
import { useState, useRef, SyntheticEvent, useCallback } from 'react'

const INITIAL_MENU_POSITION: PopoverOrigin = {
    vertical: 'bottom',
    horizontal: 'left'
} as const

export const SmartPositioningSelect = <Value=unknown>({ children, ...props }: SelectProps<Value>) => {
    const selectRef = useRef<Element>(null)
    const [anchorEl, setAnchorEl] = useState<(EventTarget & Element) | null>(null)
    const [menuPosition, setMenuPosition] = useState<PopoverOrigin>(INITIAL_MENU_POSITION)
    const [menuId] = useState(`menu-${Math.random().toString(36).substring(2, 15)}`)

    const { open, ref, onOpen, onClose, MenuProps, ...otherProps } = props

    const handleOpen = useCallback((event: SyntheticEvent) => {
        setAnchorEl(event.currentTarget);

        setTimeout(() => {
            const element = selectRef.current
            if (element) {
                const selectRect = element.getBoundingClientRect()
                const menuHeight = document.getElementById(menuId)?.clientHeight ?? 0
                const spaceBelow = window.innerHeight - (selectRect.bottom + menuHeight)

                if (spaceBelow < 0) {
                    setMenuPosition({ vertical: 'top', horizontal: 'left' })
                } 
            }
        }, 0)
        onOpen?.(event)
    }, [menuId, onOpen])

    const handleClose = useCallback((event: SyntheticEvent) => {
        setAnchorEl(null)
        setMenuPosition(INITIAL_MENU_POSITION)
        onClose?.(event)
    }, [onClose])


    return (
        <Select
            ref={selectRef}
            open={Boolean(anchorEl)}
            onOpen={handleOpen}
            onClose={handleClose}
            MenuProps={{
                anchorEl: anchorEl,
                anchorOrigin: menuPosition,
                transformOrigin: {
                    vertical: menuPosition.vertical === 'top' ? 'bottom' : 'top',
                    horizontal: menuPosition.horizontal,
                },
                slotProps: {
                    paper: {
                        id: menuId
                    }
                }
            }}
            {...otherProps}>
            {children}
        </Select>
    )
}