import React from 'react'
import {Avatar, IconButton, List, ListItem, ListItemAvatar, Divider, Box} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ListItemText from '@mui/material/ListItemText'
import {GroupMember} from '../../../../types/GroupMember'
import './UsersSelectedList.scss'
import {stringAvatar} from '../../../../helpers/AvatarHelpers'

interface UsersSelectedListProps {
    selectedUsers: Partial<GroupMember>[]
    onRemoveMember: (userToRemove: Partial<GroupMember>) => void
}

export const UsersSelectedList = ({selectedUsers, onRemoveMember}: UsersSelectedListProps) =>

    <List dense={false} className='listItemContainer'>
        {selectedUsers.map(user =>
            <Box key={`${user.userId}`}>
                <ListItem secondaryAction={
                    <IconButton edge='end' aria-label='delete'>
                        <DeleteIcon className='deleteIcon' onClick={() => onRemoveMember(user)}/>
                    </IconButton>
                }>
                    <ListItemAvatar>
                        <Avatar className='groupAvatarIcon' {...stringAvatar(user.userFullName ?? '')} />
                    </ListItemAvatar>
                    <ListItemText primary={user.userFullName} className='listItemUserName'/>
                </ListItem>
                <Divider/>
            </Box>)}
    </List>
