import React, {useState} from 'react'
import {Box, Grid, IconButton, Tooltip} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'
import {AdminEmailProps} from '../../../../types/AdminEmailProps'

export const AdminEmail = ({email, deleteDisabled, handleDeleteClicked}: AdminEmailProps) => {

	const [showDeleteAdminEmailDialog, setShowDeleteAdminEmailDialog] = useState<boolean>(false)

	const handleDelete = () => {
		setShowDeleteAdminEmailDialog(false)
		handleDeleteClicked(email)
	}

	return <Grid item xs={12}>
		<Box className='accountDomainRow'>
			<Box className='accountDomainName'>{email}</Box>
			<Tooltip title={deleteDisabled ? 'Narus needs at least 1 active admin. Add another admin if you want to remove this one.' : ''}>
				<span>
					<IconButton
						onClick={() => setShowDeleteAdminEmailDialog(true)}
						onMouseDown={() => setShowDeleteAdminEmailDialog(true)}
						edge='end'
						disabled={deleteDisabled}
						className={`domainActionIcon ${deleteDisabled ? 'disabled' : ''}`}
					>
						<DeleteIcon/>
					</IconButton>
				</span>
			</Tooltip>
			<ConfirmationDialog handleClose={() => setShowDeleteAdminEmailDialog(false)} open={showDeleteAdminEmailDialog}
			                    handleDelete={handleDelete}
			                    currentEvent='adminEmailDeletion'/>
		</Box>
	</Grid>
}