import {SignUp} from '@clerk/clerk-react'
import {Box, FormControlLabel, Grid, Link, Typography} from '@mui/material'
import {ChangeEvent, useEffect, useState} from 'react'
import './Trial.scss'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import AutoGraphIcon from '@mui/icons-material/AutoGraph'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import LaunchIcon from '@mui/icons-material/Launch'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {FeedbackMessage} from '../feedbackMessage/FeedbackMessage'
import {MobileBanner} from '../mobileBanner/MobileBanner'
import Checkbox from '@mui/material/Checkbox'
import { useSearchParams } from 'react-router-dom'
import {validateAwsMarketplaceCustomerSignature} from '../../utils/JwtUtils'

export const Trial = () => {

    const [isMarketingCommsChecked, setIsMarketingCommsChecked] = useState<boolean>(false)
    const [searchParams] = useSearchParams()

    useEffect(() => {
        PageView('Trial')

        const token = searchParams.get('jwt')
        const awsMarketplaceCustomerData = validateAwsMarketplaceCustomerSignature(token ?? '')
        localStorage.setItem('awsMarketplaceCustomerData', JSON.stringify(awsMarketplaceCustomerData))
    }, [searchParams])

    const trackTermsLinkClicked = (linkType: string) => {
        TrackActionEvent('Trial link clicked', undefined, {type: linkType})
    }

    const trackMarketingCommsChecked = (event: ChangeEvent<HTMLInputElement>) => {
        localStorage.setItem('marketingCommsChecked', JSON.stringify(event.target.checked))
        setIsMarketingCommsChecked(event.target.checked)
    }

    useEffect(() => {
        const storedCheckboxState = localStorage.getItem('marketingCommsChecked')
        setIsMarketingCommsChecked(storedCheckboxState ? JSON.parse(storedCheckboxState) : false)
    }, [])

    return <>
        <Grid container className='trialContainer'>
            <Grid item xs={12} className='titleRow'>
                <img alt='logo' src={process.env.PUBLIC_URL + '/narus-logo.png'} width={51} height={51}/>
                <Typography variant='h3'>Narus</Typography>
            </Grid>
            <Grid item xs={6} className='contentColumn'>
                <Typography variant='h3' className='title'>Unlock Your 90-Day Free Trial Today!</Typography>
                <Typography className='explanation subtitle'>Get instant, full access to your private GenAI platform. No
                    credit card required.</Typography>

                <Box className='benefit'>
                    <AutoAwesomeIcon className='icon'/>
                    <Typography className='explanation'>Securely connect and chat with multiple AI models.</Typography>
                </Box>
                <Box className='benefit'>
                    <VerifiedUserIcon className='icon'/>
                    <Typography className='explanation'>Apply your AI safeguard policy & get tailored
                        alerts.</Typography>
                </Box>
                <Box className='benefit'>
                    <AutoGraphIcon className='icon'/>
                    <Typography className='explanation'>Monitor usage and AI costs across teams.</Typography>
                </Box>
                <Box className='benefit'>
                    <AutoFixHighIcon className='icon'/>
                    <Typography className='explanation'>Easily optimize prompts for better outputs.</Typography>
                </Box>
                <Box className='benefit'>
                    <LibraryBooksIcon className='icon'/>
                    <Typography className='explanation'>Save and share great prompts.</Typography>
                </Box>
            </Grid>
            <Grid item xs={6} className='contentColumn login'>
                <SignUp/>
                <Box className='termsSection'>
                    <Typography>By signing in, I agree to the <Link className='termsLink'
                                                                    href='https://www.kolekti.com/kolekti-eula'
                                                                    target='_blank'
                                                                    onClick={() => trackTermsLinkClicked('EULA')}>Kolekti
                        EULA<LaunchIcon
                            className='redirectIcon'/></Link></Typography>
                    <Typography><Link className='termsLink' href='https://www.theadaptavistgroup.com/policy/terms'
                                      target='_blank' onClick={() => trackTermsLinkClicked('website_terms')}>Website
                        terms<LaunchIcon
                            className='redirectIcon'/></Link>, <Link className='termsLink'
                                                                     href='https://www.narus.ai/privacy-policy'
                                                                     target='_blank'
                                                                     onClick={() => trackTermsLinkClicked('privacy_policy')}>Privacy
                        policy<LaunchIcon
                            className='redirectIcon'/></Link> and <Link className='termsLink'
                                                                        href='https://static.adaptavistassets.com/downloads/kolekti_dpa.pdf'
                                                                        target='_blank'
                                                                        onClick={() => trackTermsLinkClicked('DPA')}>DPA<LaunchIcon
                        className='redirectIcon'/></Link></Typography>
                </Box>
                <Box className='marketingCommsSection'>
                    <FormControlLabel control={<Checkbox
                        inputProps={{'aria-label': 'Checkbox Marketing comms'}}
                        onChange={trackMarketingCommsChecked}
                        sx={{'& .MuiSvgIcon-root': {fontSize: 20}}}
                        checked={isMarketingCommsChecked}/>} label="Yes, please keep me up posted on Kolekti and Narus' events, news, and offers."/>
                </Box>
            </Grid>

            <svg width={0} height={0}>
                <linearGradient id="benefitGradient" x1="11.9951" y1="-15.2656" x2="12.2134" y2="22.905"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5A8287"/>
                    <stop offset="0.528562" stopColor="#50939C"/>
                    <stop offset="0.695" stopColor="#7190BC"/>
                    <stop offset="0.95" stopColor="#A07EE3"/>
                </linearGradient>
            </svg>
        </Grid>
        <FeedbackMessage/>
        <MobileBanner/>
    </>
}