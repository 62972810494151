import {v4 as uuid} from 'uuid'
import {CSSProperties, FC, useState} from 'react'
import {SvgIconTypeMap } from '@mui/material'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import './RoundIcon.scss'

const DEFAULT_COLOR_START = '#6BC4D04C'
const DEFAULT_COLOR_END = '#C8C4D080'

type Props = {
    size: number
    className?: string
    borderSize?: number
    colorStart?: string
    colorEnd?: string
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {muiName: string}
    iconColorStart?: string
    iconColorEnd?: string
    iconSize?: number
}

export const RoundIcon: FC<Props> = ({
    className,
    size,
    borderSize,
    colorStart,
    colorEnd,
    icon,
    iconColorStart,
    iconColorEnd,
    iconSize,
}) => {
    const [id] = useState(uuid())
    const diameter = size
    const radius = size / 2
    const stroke = borderSize ?? 7
    const sizePx = `${size}px`

    const roundIconStyle: CSSProperties = {
        display: 'grid',
        minWidth: sizePx,
        maxWidth: sizePx,
        minHeight: sizePx,
        maxHeight: sizePx,
    }
    const IconComponent = icon

    const viewBoxSpaceRange = 150
    const toViewBoxSpace = (value: number): string => `${value * size / viewBoxSpaceRange}`
    const x1 = toViewBoxSpace(75.5)
    const y1 = toViewBoxSpace(12.5)
    const x2 = toViewBoxSpace(75.5)
    const y2 = toViewBoxSpace(137.5)

    const borderId = `${id}-color-border`
    const iconId = `${id}-color-icon`

    const borderColorA = colorStart ?? DEFAULT_COLOR_START
    const borderColorB = colorEnd ?? DEFAULT_COLOR_END
    const iconColorA = iconColorStart ?? borderColorA
    const iconColorB = iconColorEnd ?? borderColorB

    return (
        <div className={className} style={roundIconStyle}>
            <svg width={0} height={0}>
                <defs>
                    <linearGradient id={borderId} x1={x1} y1={y1} x2={x2} y2={y2} gradientUnits='userSpaceOnUse'>
                        <stop stopColor={borderColorA}/>
                        <stop offset='1' stopColor={borderColorB}/>
                    </linearGradient>
                    <linearGradient id={iconId} x1='15.5' y1='0.331055' x2='15.5' y2='20.6689' gradientUnits='userSpaceOnUse'>
                        <stop stopColor={iconColorA} />
                        <stop offset='1' stopColor={iconColorB}/>
                    </linearGradient>
                </defs>
            </svg>
            <div className='roundIconBox'>
                <IconComponent sx={{fontSize: iconSize ?? (size - stroke * 4), fill: `url(#${iconId})`}} />
            </div>

            <div className='roundIconBorder'>
                <svg width={diameter} height={diameter} viewBox={`0 0 ${diameter} ${diameter}`}>     
                    <circle cx={radius} cy={radius} r={radius - stroke / 2} stroke={`url(#${borderId})`} strokeWidth={stroke} fill='none' />
                </svg>
            </div>
        </div>
    )
}