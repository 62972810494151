export const stringAvatar = (name: string) => {
	return {
		sx: {
			width: 20,
			height: 20,
			fontSize: '12px'
		},
		alt: name,
		children: getInitials(name)
	}
}


const getInitials = (name: string): string => {
	const nameParts = name.split(' ')

	if (nameParts.length === 1) return nameParts[0][0]?.toUpperCase()

	const firstInitial = nameParts[0][0]?.toUpperCase()
	const secondInitial = nameParts[1] ? nameParts[1][0]?.toUpperCase() : ''

	return `${firstInitial}${secondInitial}`
}