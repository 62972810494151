import {Box, Card, CardContent, Skeleton, Stack} from '@mui/material'
import React from 'react'
import CardActions from '@mui/material/CardActions'
import './CardSkeleton.scss'

const CardSkeleton = () => {
	return (
		<Stack spacing={1}>
			<Card className='promptCard'>
				<CardContent className='cardSkeletonContent'>
					<Skeleton variant="text" sx={{fontSize: '1rem'}} width={254}/>
					<Skeleton variant="text" sx={{fontSize: '1rem'}} width={174}/>

					<Skeleton variant="text" sx={{fontSize: '1rem'}} width={254}/>
					<Skeleton variant="text" sx={{fontSize: '1rem'}} width={174}/>

				</CardContent>
				<Box className='promptCardHorizontalLine'></Box>
				<CardActions className='promptCardButtons'>
					<Box className='promptCardIconButton'>
						<Skeleton variant="text" sx={{fontSize: '1rem'}} width={127}/>
					</Box>
					<Box className='promptCardVerticalLine'></Box>
					<Box className='promptCardIconButton'>
						<Skeleton variant="text" sx={{fontSize: '1rem'}} width={127}/>
					</Box>
				</CardActions>
			</Card>
		</Stack>
	)
}

export default CardSkeleton