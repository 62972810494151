import {useCallback, useRef} from 'react'

export type UseAbortControllerValue = {
    abortControllerRefSignal: AbortSignal
    abortControllerRefAbort: () => void
}

export const useAbortController = (): UseAbortControllerValue => {
    const abortControllerRef = useRef<AbortController>(new AbortController())
    const abortControllerRefSignal = abortControllerRef.current.signal
    const abortControllerRefAbort = useCallback(() => abortControllerRef.current.abort(), [])

    return {abortControllerRefSignal, abortControllerRefAbort}
}