import {useCallback, useState} from 'react'
import {useUser} from '@clerk/clerk-react'
import {Box, Button} from '@mui/material'
import {TrackActionEvent} from '../../../service/SegmentService'
import {NEED_HELP} from '../../../constants/AppMenuConstants'
import {USER_DOCUMENTATION_URL} from '../../../constants/Links'
import {CustomSideItemWithPopover} from './CustomSideItemWithPopover'
import EmailIcon from '@mui/icons-material/Email'
import InfoIcon from '@mui/icons-material/Info'
import {ContactSupportDialog} from '../../common/contactSupportDialog/ContactSupportDialog'
import './CustomSideItemHelp.scss'

export const CustomSideItemHelp = () => {
    const {user} = useUser()
    const [emailDialogOpen, setEmailDialogOpen] = useState(false)
    const userId = user?.externalId ?? user?.id

    const handleDocumentationClick = useCallback(() => {
		TrackActionEvent('Menu clicked', userId, {path: 'user_documentation'})
    }, [userId])

    const handleEmailClick = useCallback(() => {
		TrackActionEvent('Menu clicked', userId, {path: 'user_support_email'})
        setEmailDialogOpen(true)
    }, [userId])

    const handleCloseEmailDialog = useCallback(() => {
        setEmailDialogOpen(false)
    }, [])

    return <>
        <CustomSideItemWithPopover item={NEED_HELP}>
            <Box className='sideItemHelpContainer'>
                <Button className='sideItemHelpButton' startIcon={<InfoIcon/>} href={USER_DOCUMENTATION_URL} target={'narus_user_documentation'} onClick={handleDocumentationClick}>User documentation</Button>
                <Button className='sideItemHelpButton' startIcon={<EmailIcon/>} onClick={handleEmailClick}>Help & Support</Button>
            </Box>
        </CustomSideItemWithPopover>
        <ContactSupportDialog open={emailDialogOpen} onClose={handleCloseEmailDialog}/>
    </>
}