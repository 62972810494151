import {GridColDef} from '@mui/x-data-grid'
import {AI_MODEL_HEADER_NAMES_MAP, AIModel, ALL_AI_MODEL_IDS} from '../types/AiModel'
import {not} from './genericUtils'
import {isModelDisabledGlobally} from './aiModelUtils'

export const getUsageTableColumnDefinitions = (aiModels: AIModel[]): GridColDef[] => {

    const MODEL_COLUMNS: GridColDef[] = ALL_AI_MODEL_IDS
        .filter(not(isModelDisabledGlobally))
        .map((id): GridColDef => ({
            field: id,
            headerName: aiModels.find(aiModel => aiModel.id === id)?.name ?? AI_MODEL_HEADER_NAMES_MAP[id],
            valueGetter: (_, row) => row.totalPromptsByModel[id],
            flex: 1
        }))

    return [
        {field: 'totalPrompts', headerName: 'Total prompts', flex: 1},
        ...MODEL_COLUMNS,
        {
            field: 'totalCost',
            headerName: 'Total cost',
            valueGetter: (value) => `$${Math.round((value + Number.EPSILON) * 100) / 100}`,
            flex: 1
        }
    ]
}