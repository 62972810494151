import { Box, TextField, Typography } from '@mui/material'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { ChangeEvent } from 'react'
import { getFormCompanyName, isCompanyNameValid } from '../../utils/accountCreationHelpers'

export const AccountCreationFormCompanyName = () => {
    const { form, valid, error, changeFormValue, changeFormValueValid, changeFormValueError } = useAccountCreationContext()
    const showError = valid.companyName === false || !!error.companyName
    const helperText = showError ? (error.companyName || 'Only alphanumeric, and maximum length of 220 characters') : ''

    const handleInputChanged = (event: ChangeEvent<HTMLInputElement>) => {
        changeFormValue('companyName', event.target.value)
    }

    const handleInputBlurred = () => {
        const value = getFormCompanyName(form)
        if (value !== form.companyName) {
            changeFormValue('companyName', value)
        }
        changeFormValueValid('companyName', value ? isCompanyNameValid(value) : undefined)
    }

    const handleInputFocus = () => {
        changeFormValueError('companyName', undefined)
        changeFormValueValid('companyName', undefined)
    }

    return <Box className='AccountCreationForm_Control'>
        <Typography variant='h4' className='AccountCreationForm_ControlHeader'>Company space</Typography>
        <TextField variant='outlined' error={showError} label='Company name' helperText={helperText} value={form.companyName} placeholder='Your company name' onFocus={handleInputFocus} onBlur={handleInputBlurred} onChange={handleInputChanged}/>
    </Box>
}