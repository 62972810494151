import {useEffect, useState} from 'react'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'

const Spinner = () => {
	const [progress, setProgress] = useState(0)

	useEffect(() => {
		const timer = setInterval(() => {
			setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 25))
		}, 800)

		return () => clearInterval(timer)
	}, [])

	return <Stack spacing={2} direction="row">
		<CircularProgress variant="determinate" value={progress} size={30}/>
	</Stack>
}

export default Spinner