import {FC, PropsWithChildren} from 'react'
import {NoBudgetUserAlert} from './NoBudgetUserAlert'

export const UserAlerts: FC<PropsWithChildren> = ({
    children
}) => {
    return <>
        <NoBudgetUserAlert/>
        {children}
    </>
}