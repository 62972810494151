import React from 'react'
import Box from '@mui/material/Box'
import {useSearchContext} from '../../context/SearchContext'
import './EmptyPromptsList.scss'
import {EmptyListProps} from '../../types/EmptyListProps'
import {usePromptCreationContext} from '../../context/PromptCreationContext'

const EmptyPromptsList = ({message}: EmptyListProps) => {
    const {isDefaultSearch} = useSearchContext()
    const {userPrompt} = usePromptCreationContext()

    if (isDefaultSearch && !userPrompt) return <Box className='noPrompts'>{message}</Box>
    return <Box className='noPrompts'>Sorry, we couldn’t find a match for that.</Box>
}

export default EmptyPromptsList