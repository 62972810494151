import {GROWTH_MONTHLY_PRICE_ID, GROWTH_YEARLY_PRICE_ID, PRICES_INFO_MAP, SCALE_MONTHLY_PRICE_ID, TRIAL_PRICE_ID, ULTIMATE_MONTHLY_PRICE_ID} from '../constants/StripeConstants'
import {Period, Plan} from '../types/Stripe'

export const getPlanPrice = (plan: Plan | undefined, period: Period, seats: number): number => {
	if (!plan || plan === 'trial') return 0

	const prices = PRICES_INFO_MAP[plan][period].priceBySeats
	const bucketSeats = Object.keys(prices).find(bucketSeats => seats <= parseInt(bucketSeats))
	return bucketSeats ? prices[bucketSeats] : 0
}

export const getPlanByPriceId = (priceId: string): Plan => {
	switch (priceId) {
		case TRIAL_PRICE_ID:
			return 'trial'
		case GROWTH_MONTHLY_PRICE_ID || GROWTH_YEARLY_PRICE_ID:
			return 'growth'
		case ULTIMATE_MONTHLY_PRICE_ID:
			return 'ultimate'
		default:
			return 'scale'
	}
}

export const getPeriodByPriceId = (priceId: string): Period => {
	if ([GROWTH_MONTHLY_PRICE_ID, SCALE_MONTHLY_PRICE_ID, ULTIMATE_MONTHLY_PRICE_ID].includes(priceId)) return 'monthly'
	return 'annual'
}