import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {
	deleteTopicWarning,
	deleteWordWarning,
	getFormatWarnings,
	getTopicWarnings,
	getWordWarnings,
	saveTopicWarning,
	saveWordWarning, updateFormatWarning,
	updateTopicWarning,
	updateWordWarning
} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import { WarningsContextValue } from '../context/WarningsContext'
import {FormatWarning, TopicWarning, WordWarning} from '../types/Warning'

export const useWarnings = (): WarningsContextValue => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [topicWarnings, setTopicWarnings] = useState<TopicWarning[]>([])
	const [wordWarnings, setWordWarnings] = useState<WordWarning[]>([])
	const [formatWarnings, setFormatWarnings] = useState<FormatWarning[]>([])


	const listTopicWarnings = useCallback(() => {
		setLoading(true)
		getTopicWarnings(token)
			.then(setTopicWarnings)
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to get warning topics. Try again reloading the page', 'error')
				return error.message
			}).finally(() => setLoading(false))
	}, [token, showFeedback])

	const createTopicWarning = useCallback((topic: string, warningLevel: string) => {
		return saveTopicWarning(token, topic, warningLevel)
			.then(newTopicWarning => setTopicWarnings(previousTopicWarnings => [...previousTopicWarnings, newTopicWarning]))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to save warning topics. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	const editTopicWarning = useCallback((topicWarning: TopicWarning) => {
		return updateTopicWarning(token, topicWarning)
			.then(newTopicWarning => setTopicWarnings(previousTopicWarnings => {
				const topicWarningIndex = previousTopicWarnings.findIndex(previousTopicWarning =>  previousTopicWarning.hashKey === topicWarning.hashKey)
				if (topicWarningIndex === -1) return previousTopicWarnings
				previousTopicWarnings[topicWarningIndex] = newTopicWarning
				return previousTopicWarnings
			}))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to update warning topics. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	const removeTopicWarning = useCallback((topicWarning: TopicWarning) => {
		return deleteTopicWarning(token, topicWarning.hashKey)
			.then( () => setTopicWarnings(previousTopicWarnings =>
				previousTopicWarnings.filter(previousTopicWarning => previousTopicWarning.hashKey !== topicWarning.hashKey)))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to delete a warning topics. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	const listWordWarnings = useCallback(() => {
		setLoading(true)
		getWordWarnings(token)
			.then(setWordWarnings)
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to get warning words. Try again reloading the page', 'error')
				return error.message
			}).finally(() => setLoading(false))
	}, [token, showFeedback])

	const createWordWarning = useCallback((word: string, warningLevel: string) => {
		return saveWordWarning(token, word, warningLevel)
			.then(newWordWarning => setWordWarnings(previousWordWarnings => [...previousWordWarnings, newWordWarning]))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to save warning word. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	const editWordWarning = useCallback((wordWarning: WordWarning) => {
		return updateWordWarning(token, wordWarning)
			.then(newWordWarning => setWordWarnings(previousWordWarnings => {
				const wordWarningIndex = previousWordWarnings.findIndex(previousTopicWarning =>  previousTopicWarning.hashKey === wordWarning.hashKey)
				if (wordWarningIndex === -1) return previousWordWarnings
				return [...previousWordWarnings.slice(0, wordWarningIndex), newWordWarning, ...previousWordWarnings.slice(wordWarningIndex + 1)]
			}))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to update warning word. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	const removeWordWarning = useCallback((wordWarning: WordWarning) => {
		return deleteWordWarning(token, wordWarning.hashKey)
			.then(() => setWordWarnings(previousTopicWarnings =>
				previousTopicWarnings.filter(previousWordWarnings => previousWordWarnings.hashKey !== wordWarning.hashKey)))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to delete a warning word. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	const listFormatWarnings = useCallback(() => {
		setLoading(true)
		getFormatWarnings(token)
			.then(setFormatWarnings)
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to get format warnings. Try again reloading the page', 'error')
				return error.message
			}).finally(() => setLoading(false))
	}, [token, showFeedback])

	const editFormatWarning = useCallback((formatWarning: FormatWarning) => {
		return updateFormatWarning(token, formatWarning)
			.then(newFormatWarning => setFormatWarnings(previousFormatWarnings => {
				const formatWarningIndex = previousFormatWarnings.findIndex(previousTopicWarning =>  previousTopicWarning.hashKey === formatWarning.hashKey)
				if (formatWarningIndex === -1) return previousFormatWarnings
				return [...previousFormatWarnings.slice(0, formatWarningIndex), newFormatWarning, ...previousFormatWarnings.slice(formatWarningIndex + 1)]
			}))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to update warning format. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	useEffect(() => {
		listTopicWarnings()
		listWordWarnings()
		listFormatWarnings()
	}, [listTopicWarnings, listWordWarnings, listFormatWarnings])

	return useMemo(() => ({
		loading,
		wordWarnings,
		topicWarnings,
		formatWarnings,
		createTopicWarning,
		editTopicWarning,
		removeTopicWarning,
		createWordWarning,
		editWordWarning,
	 	removeWordWarning,
		editFormatWarning
	}), [loading, wordWarnings, topicWarnings, formatWarnings, createTopicWarning, editTopicWarning, removeTopicWarning, createWordWarning, editWordWarning, removeWordWarning, editFormatWarning])
}