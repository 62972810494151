import {Box, Button, Typography} from '@mui/material'
import CheckIcon from '@mui/icons-material/CheckCircle'
import {FC, useEffect} from 'react'
import './AccountCreationCompleted.scss'
import {useNavigate} from 'react-router-dom'
import {PageView, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useAccountCreationContext} from '../../context/AccountCreationContext'
import {RoundIcon} from '../common/roundIcon/RoundIcon'

export const AccountCreationCompleted: FC = () => {
    const navigate = useNavigate()
    const {user} = useUser()
    const {form} = useAccountCreationContext()

    const handleButtonClicked = () => {
        TrackActionEvent('Registration Completed', user?.externalId ?? user?.id, {company_name: form.companyName})
        navigate('/')
    }

    useEffect(() => PageView('Account creation completed'), [])

	return <Box className='AccountCreationCompleted'>
        <RoundIcon className='AccountCreationCompleted_Icon' icon={CheckIcon} size={200} iconSize={166} />
        <Box className='AccountCreationCompleted_Content'>
            <Typography variant='h3' className='AccountCreationCompleted_Title'>Welcome to Narus</Typography>
            <Typography variant='h5' className='AccountCreationCompleted_Subtitle'>The workspace has been successfully created, you can now access narus.</Typography>
            <Button variant='contained' onClick={handleButtonClicked}>Go to narus</Button>
        </Box>
    </Box>
}