import {updatePrompt} from '../service/persistenceService'
import {useCallback, useState} from 'react'
import {Prompt} from '../types/Prompt'
import {usePromptsContext} from '../context/PromptsContext'

export type UseEditionPromptValue = {
	loading: boolean
	editPrompt: (prompt: Prompt) => Promise<Prompt | void>
	archivePromptAction: (prompt: Prompt, isArchivedOption: boolean) => Promise<Prompt | void>
}

export const usePromptEdition = (userToken: string): UseEditionPromptValue => {
	const {setPrompts, setFavourites, setRecentlyUsedPrompts, setUserPrompts} = usePromptsContext()

	const [loading, setLoading] = useState(false)

	const editPrompt = useCallback((promptToUpdate: Prompt) => {
		setLoading(true)
		return updatePrompt(promptToUpdate, userToken)
			.then(updatedPrompt => {
				if (!updatedPrompt) Promise.reject(undefined)
				const updateExistingPrompt = (previousPrompts: Prompt[]) => {
					const promptIndex = previousPrompts.findIndex(prompt => prompt.id === promptToUpdate.id)
					
					return promptIndex === -1
						? previousPrompts
						: [
							...previousPrompts.slice(0,promptIndex),
							updatedPrompt,
							...previousPrompts.slice(promptIndex + 1)
						]
				}
				setPrompts(updateExistingPrompt)
				setRecentlyUsedPrompts(updateExistingPrompt)
				setFavourites(updateExistingPrompt)
				setUserPrompts(updateExistingPrompt)
				return updatedPrompt
			})
			.finally(() => setLoading(false))
	}, [userToken, setPrompts, setFavourites, setRecentlyUsedPrompts, setUserPrompts])

	const archivePromptAction = useCallback((promptToArchive: Prompt, isArchivedOption: boolean) => {
		const updatedPrompt: Prompt = {...promptToArchive, isArchived: isArchivedOption}
		setLoading(true)
		return updatePrompt(updatedPrompt, userToken)
			.finally(() => setLoading(false))
	}, [userToken])

	return {loading, editPrompt, archivePromptAction}
}