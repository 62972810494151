import {FC, PropsWithChildren} from 'react'
import {Box} from '@mui/material'
import {NarusLogo} from '../../icons/NarusLogo'
import {UserButton} from '@clerk/clerk-react'
import './InfoScreen.scss'
import { CLERK_INFO_SCREEN_USER_BUTTON_APPEARANCE } from '../../../constants/ClerkConstants'

/**
 * InfoScreen Component renders an information page, 
 * with centered children content, and the addition of main logo icon
 * and profile session icon too
 */
export const InfoScreen: FC<PropsWithChildren> = ({
    children
}) => {
    return (
        <Box className='infoScreenContainer'>
            <Box className='infoScreenContent'>
                {children}
            </Box>
            <Box className='infoScreenHeader'>
                <Box className='infoScreenLogo'>
                    <NarusLogo/>
                </Box>
                <Box className='infoScreenProfile'>
                    <UserButton appearance={CLERK_INFO_SCREEN_USER_BUTTON_APPEARANCE} showName={false}/>
                </Box>
            </Box>
        </Box>
    )
}