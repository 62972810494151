import {CSSObject, Drawer as MuiDrawer, Theme, styled, Box} from '@mui/material'
import {useAppMenuContext} from '../../context/AppMenuContext'
import {APP_MENU_SIDE_WIDTH, APP_MENU_TOTAL_WIDTH} from '../../constants/AppMenuConstants'
import {AppMenuSideItems} from './AppMenuSideItems'
import {AppMenuMainItems} from './AppMenuMainItems'

const openedMixin = (theme: Theme): CSSObject => ({
    width: APP_MENU_TOTAL_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})
  
const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${APP_MENU_SIDE_WIDTH}px + ${theme.spacing(6)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${APP_MENU_SIDE_WIDTH}px + ${theme.spacing(7)} + 1px)`,
    },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme }) => ({
    width: APP_MENU_TOTAL_WIDTH,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
        {
            props: ({ open }) => open,
            style: {
                ...openedMixin(theme),
                '& .MuiDrawer-paper': openedMixin(theme),
            }
        },
        {
            props: ({ open }) => !open,
            style: {
                ...closedMixin(theme),
                '& .MuiDrawer-paper': closedMixin(theme),
            }
        }
    ]
}))

export const AppMenuDrawer = () => {
    const { isOpen } = useAppMenuContext()

    return (
        <Drawer variant='permanent' anchor='left' open={isOpen}>
            <Box sx={{ display: 'flex', height: '100%' }}>
                <AppMenuSideItems/>
                <AppMenuMainItems/>
            </Box>
        </Drawer>
    )
}