import {createContext, FC, PropsWithChildren, useContext} from 'react'
import {AdminEmailsContextValue, useAdminEmails} from '../hooks/useAdminEmails'


const DEFAULT_ADMIN_EMAILS_CONTEXT: AdminEmailsContextValue = {
	loading: false,
	adminEmails: [],
	editAdminEmails: (adminEmails: string[]) => Promise.resolve('')
}

export const AdminEmailsContext = createContext<AdminEmailsContextValue>(DEFAULT_ADMIN_EMAILS_CONTEXT)

export const useAdminEmailsContext = () => useContext(AdminEmailsContext)

export const AdminEmailsContextProvider: FC<PropsWithChildren> = ({children}) => {
	const value = useAdminEmails()

	return <AdminEmailsContext.Provider value={value}>
		{children}
	</AdminEmailsContext.Provider>
}