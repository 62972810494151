import {FC, ReactElement} from 'react'
import {Box, Stack, ThemeProvider} from '@mui/material'
import './PromptsCards.scss'
import EmptyPromptsList from '../emptyPromptsList/EmptyPromptsList'
import PromptCard from '../promptCard/PromptCard'
import Button from '@mui/material/Button'
import {usePromptsContext} from '../../context/PromptsContext'
import {useSearchContext} from '../../context/SearchContext'
import {loadMoreButtonTheme} from '../../themes/CustomThemes'
import CardSkeleton from '../spinner/CardSkeleton'
import {getHomeTabForAnalytics, TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useUserContext} from '../../context/UserContext'
import {Prompt} from '../../types/Prompt'
import {SearchLibrary} from '../searchLibrary/SearchLibrary'
import {getUniqueValues} from '../../utils/genericUtils'

type PromptsCardsProps = {
	library?: 'My prompts' | 'Templates' | 'Public prompts' | 'Favourites'
    prompts: Prompt[]
    noPromptsMessage?: string
    showLoadMore: boolean
	customEmptyPromptsElement?: ReactElement
}

export const PromptsCards: FC<PromptsCardsProps> = ({
	prompts,
	noPromptsMessage,
	showLoadMore,
	customEmptyPromptsElement,
	library
}: PromptsCardsProps) => {

	const {promptsLoading, getNextPrompts, userPrompts, getPromptsBySelectedLibrary} = usePromptsContext()
	const {searchLoading, isDefaultSearch, searchUserPrompts} = useSearchContext()
	const {selectedTab} = useUserContext()
	const {user} = useUser()

	const renderEmptyPrompts = () =>
		customEmptyPromptsElement ?? <EmptyPromptsList message={noPromptsMessage}/>

	const displayPrompts = () => {
		const promptCards = prompts.map((prompt, index) => <PromptCard key={`prompt-${prompt.id}-${index}`} prompt={prompt}/>)
		return <>
			{promptsLoading ? <CardSkeleton/> : promptCards}
		</>
	}

	const loadMoreHandler = () => {
		TrackActionEvent('Load more', user?.externalId ?? user?.id, {section: getHomeTabForAnalytics(selectedTab)})
		if (isDefaultSearch) getNextPrompts()
		else searchUserPrompts(false)
	}

	return (
		<Box className='promptsCardsContainer'>
			{library && library !== 'Favourites' ? <Box className='searchContainer'>
				<SearchLibrary library={library} key={library}
							models={library === 'My prompts' ? getUniqueValues(userPrompts, 'modelId') : undefined}
							labels={getUniqueValues(getPromptsBySelectedLibrary(library), 'labels')}/>
			</Box> : <></>}
			{prompts?.length === 0 && !promptsLoading ? renderEmptyPrompts() : <></>}
			<Stack direction='row' spacing={{xs: 1, sm: 2, md: 4}} useFlexGap flexWrap='wrap' className='promptsCardsWrapper'>
				{prompts?.length ? displayPrompts() : <>
						{promptsLoading ? <CardSkeleton/> : <></>}
					</>
				}
			</Stack>
			{showLoadMore && !searchLoading && !promptsLoading ? <Box justifyContent='center' display='flex'>
				<ThemeProvider theme={loadMoreButtonTheme}>
					<Button variant='outlined' className='loadMoreButton' onClick={loadMoreHandler}>Load more</Button>
				</ThemeProvider>
			</Box> : <></>}
		</Box>
	)
}
