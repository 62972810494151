import React, {ChangeEvent, useState} from 'react'
import {
    Box,
    Button,
    Stack,
    Typography,
    Avatar,
    Autocomplete
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import './GroupCreation.scss'
import {useFeedbackContext} from '../../../../context/FeedbackContext'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {useGroupsContext} from '../../../../context/GroupsContext'
import GroupsIcon from '@mui/icons-material/Groups'
import {getUsersListOptions} from '../../../../helpers/GroupsHelpers'
import {UsersSelectedList} from './UsersSelectedList'
import {GroupMember} from '../../../../types/GroupMember'
import {stringAvatar} from '../../../../helpers/AvatarHelpers'
import ListItemIcon from '@mui/material/ListItemIcon'
import CheckIcon from '@mui/icons-material/Check'
import {SelectOption} from '../../../../types/SelectOption'

export const GroupCreation = () => {

    const {toggleDrawer} = useToggleDrawerContext()
    const {user} = useUser()
    const {showFeedback} = useFeedbackContext()
    const {usersInfo} = useUsersAccessContext()
    const {saveGroup, allGroupsMembers} = useGroupsContext()

    const [errorTeamName, setErrorTeamName] = useState<boolean>(false)
    const [teamName, setTeamName] = useState<string>('')
    const [selectedMembers, setSelectedMembers] = useState<Partial<GroupMember>[]>([])

    const filteredUsersListOptions = getUsersListOptions(usersInfo, allGroupsMembers)

    const createTeamHandler = async (event: React.MouseEvent) => {
        if (!teamName) {
            setErrorTeamName(true)
            return
        }

        saveGroup(teamName, selectedMembers.map(selected => selected.userId!))
            .then(({status, groupId}) => {
                if (status === 207) showFeedback('Team created!', 'The team was created, but some users were not added because they already belong to other team!', 'success')
                else showFeedback('Team created!', 'The team was created!', 'success')
                TrackActionEvent('Groups', user?.externalId ?? user?.id, {
                    action: 'create',
                    group_id: groupId,
                    group_members: selectedMembers.length ?? 0
                })
            })
            .catch(() => showFeedback('Error', 'The team couldn\'t be created, please try again.', 'error'))
            .finally(() => toggleDrawer(false, 'CREATE_GROUP_DRAWER')(event))
    }

    const updateTeamNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setErrorTeamName(false)
        setTeamName(event.target.value)
    }

    const addMembersToTeamHandler = (selectedMembers: SelectOption[]) => {
        const selectedMembersIds = selectedMembers.map(member => member.value)
        const selectedUsersInfo: Partial<GroupMember>[] = usersInfo
            .filter(user => selectedMembersIds.includes(user.userId))
            .map(user => ({userId: user.userId, userFullName: user.userFullName}))

        setSelectedMembers(prevMembers => {
            if (!prevMembers.length) return selectedUsersInfo
            const newSelectedUsers = selectedUsersInfo.filter(
                selected => !prevMembers.some(prevUser => prevUser.userId === selected.userId)
            )

            return newSelectedUsers.length ? [...prevMembers, ...newSelectedUsers] : prevMembers
        })
    }

    const removeMemberHandler = (memberToRemove: Partial<GroupMember>) => {
        setSelectedMembers(prevState => prevState.filter(selectedUser => selectedUser.userId !== memberToRemove.userId))
    }

    const isUserSelected = (userId: string) =>
        selectedMembers.map(selected => selected.userId).indexOf(userId) !== -1

    return <Stack className='createTeamContainer'>
        <Box className='createTeamTitleContainer'>
            <Box className='createTeamTitle'>
                <Box className='createTeamTitleIcon'>
                    <GroupsIcon fontSize='small'/>
                </Box>
                <Typography variant='h4'>Create new team</Typography>
            </Box>
            <CloseIcon onClick={toggleDrawer(false, 'CREATE_GROUP_DRAWER')}
                       cursor='pointer'
                       className='closeIcon'/>
        </Box>
        <Box className='createTeamDescription'>
            <Typography variant='caption' className='createTeamDescriptionText'>Give your team a name and assign
                users.</Typography>
        </Box>
        <Box className='createTeamName'>
            <TextField
                required
                label='Team Name'
                inputProps={{maxLength: 150}}
                className='textFieldInput textFieldTeamName'
                onChange={updateTeamNameHandler}
                placeholder='Marketing'
                fullWidth
                error={errorTeamName}
                variant='outlined'
                value={teamName}
            />
        </Box>
        <Autocomplete
            options={filteredUsersListOptions}
            value={[]}
            multiple
            fullWidth
            onChange={(_, valueList) => addMembersToTeamHandler(valueList)}
            renderInput={(params) => <TextField {...params}
                                                label='Team member'
                                                placeholder='Type or select new member'/>}
            renderOption={(props, option) => {
                const {...optionProps} = props
                return (
                    <Box component='li'
                         key={option.value}
                         style={{backgroundColor: isUserSelected(option.value) ? '#B5E2E8' : 'inherit'}}
                         {...optionProps}
                    >
                        <Box>
                            <ListItemIcon className='groupAvatarIconContainer'>
                                <Avatar className='groupAvatarIcon userAvatarIcon' {...stringAvatar(option.label)}/>
                            </ListItemIcon>
                            {option.label}
                        </Box>
                        {isUserSelected(option.value) ?
                            <CheckIcon fontSize='small' className='memberCheckIcon'/> : <></>}
                    </Box>
                )
            }}
        />
        {selectedMembers ?
            <UsersSelectedList selectedUsers={selectedMembers} onRemoveMember={removeMemberHandler}/> : <></>}
        <Box className='createTeamButtonContainer'>
            <Button className='cancelButton' variant='outlined' onClick={toggleDrawer(false, 'CREATE_GROUP_DRAWER')}>
                Cancel
            </Button>
            <Button className='createTeamButton' variant='contained' onClick={createTeamHandler}>
                Create team
            </Button>
        </Box>
    </Stack>
}