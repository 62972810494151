import {useEffect, useMemo} from 'react'
import {PageView} from '../../service/SegmentService'
import {PromptsCards} from '../promptsCards/PromptsCards'
import {usePromptsContext} from '../../context/PromptsContext'
import {EmptyPrompts} from '../emptySection/EmptyPrompts'
import {filterConversationsByParams} from '../../utils/promptUtils'
import {useSearchContext} from '../../context/SearchContext'
import {Prompt} from '../../types/Prompt'
import { EmptySearch } from '../emptySection/EmptySearch'

export const MyPrompts = () => {

    const {userPrompts} = usePromptsContext()
    const {searchAIModelIDs, searchLabels, searchText, searchVisibility} = useSearchContext()

    useEffect(() => PageView('My prompts'), [])

    const filteredPrompts = useMemo(() => filterConversationsByParams(userPrompts, searchAIModelIDs, searchLabels, searchText, searchVisibility) as Prompt[]
        , [userPrompts, searchAIModelIDs, searchLabels, searchText, searchVisibility])

    return userPrompts.length 
        ? <PromptsCards
            library='My prompts'
            prompts={filteredPrompts}
            showLoadMore={false}
            customEmptyPromptsElement={!filteredPrompts.length ? <EmptySearch/> : undefined}/>
        : <EmptyPrompts/>
}
