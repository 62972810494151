import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {getAdminEmails, updateAdminEmails} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import {AdminEmail} from '../types/AdminEmail'

export type AdminEmailsContextValue = {
	loading: boolean
	adminEmails: AdminEmail[]
	editAdminEmails: (adminEmails: string[]) => Promise<string | undefined>
}

export const useAdminEmails = (): AdminEmailsContextValue => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [adminEmails, setAdminEmails] = useState<AdminEmail[]>([])


	const getAdmins = useCallback(() => {
		setLoading(true)
		getAdminEmails(token)
			.then(setAdminEmails)
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to get admins. Try again reloading the page', 'error')
				return error.message
			}).finally(() => setLoading(false))
	}, [token, showFeedback])

	const editAdminEmails = useCallback((adminEmails: string[]) => {
		return updateAdminEmails(token, adminEmails)
			.then(setAdminEmails)
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to update admins. Try again reloading the page', 'error')
				return error.message
			})
	}, [token, showFeedback])

	useEffect(() => {
		getAdmins()
	}, [getAdmins])

	return useMemo(() => ({loading, adminEmails, editAdminEmails}), [loading, adminEmails, editAdminEmails])
}