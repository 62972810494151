import {Box, Typography, capitalize} from '@mui/material'
import './AuditingLogTrailItem.scss'
import {AuditLogWarningLevel} from '../../../types/UserAuditLog'
import AddIcon from '@mui/icons-material/Add'
import ChatIcon from '@mui/icons-material/ChatOutlined'
import WarningIcon from '@mui/icons-material/WarningAmberRounded'
import SaveIcon from '@mui/icons-material/SaveOutlined'

type TrailItemType = AuditLogWarningLevel | 'Start' | 'Save'
type TrailITemTypeMap<T> = { [key in TrailItemType]: T}

type AuditingLogTrailItemProps = {
    title: string
    description: string
    type: TrailItemType
}

const DisplayedIconMap: TrailITemTypeMap<() => JSX.Element> = {
    high: () => <WarningIcon/>,
    low: () => <WarningIcon/>,
    medium: () => <WarningIcon/>,
    none: () => <ChatIcon/>,
    Save: () => <SaveIcon/>,
    Start: () => <AddIcon/>
}

export const AuditingLogTrailItem = ({
    title,
    description,
    type
}: AuditingLogTrailItemProps) => {
    const Icon = DisplayedIconMap[type]

    return <Box className='AuditingLogTrailItem_Container'>
        <Box className={`AuditingLogTrailItem_Icon ${capitalize(type)}`}>
            <Icon/>
        </Box>
        <Box className={`AuditingLogTrailItem_Card ${capitalize(type)}`}>
            <Typography className='AuditingLogTrailItem_Title'>{ title }</Typography>
            <Typography className='AuditingLogTrailItem_Description'>{ description }</Typography>
        </Box>
    </Box>
}