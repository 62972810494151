import {FC, PropsWithChildren, useEffect, useState} from 'react'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'
import {BlockingDialog} from './BlockingDialog'
import {TrialBanner} from './TrialBanner'
import {useNavigate} from 'react-router-dom'

export const TrialBoundary: FC<PropsWithChildren> = ({
                                                         children
                                                     }) => {
    const [blockingDialogOpen, setBlockingDialogOpen] = useState<boolean>(false)

    const {userInfo} = useUserInfoContext()
    const {isActiveSubscription, isPausedSubscription} = useStripeSubscriptionsContext()
    const navigate = useNavigate()

    const isAdmin = !!userInfo?.isAdmin

    useEffect(() => {
        if (!isActiveSubscription) navigate('/')
        setBlockingDialogOpen(!isActiveSubscription)
    }, [isActiveSubscription, navigate])

    return <>
        <TrialBanner/>
        <BlockingDialog isAdmin={isAdmin} open={blockingDialogOpen} isPausedAfterTrial={isPausedSubscription}/>
        {children}
    </>
}