import {Avatar, Grid, Typography} from '@mui/material'
import React from 'react'
import {UsersByAiModelAccess} from './GroupSettings'
import CloseIcon from '@mui/icons-material/Close'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {AiModelIcon} from '../../../icons/AiModelIcon'
import {stringAvatar} from '../../../../helpers/AvatarHelpers'
import './GroupUsersByModelAccess.scss'

type GroupUsersByModelAccessProps = {
	usersByModelAccess: UsersByAiModelAccess
}

export const GroupUsersByModelAccess = ({usersByModelAccess}: GroupUsersByModelAccessProps) => {

	const {toggleDrawer} = useToggleDrawerContext()

	return <Grid container className='groupUsersByModelAccessContainer'>
		<Grid item xs={12} container className='titleWrapper'>
			<Grid item xs={2}>
				<Avatar className='modelIcon'>
					<AiModelIcon modelId={usersByModelAccess.aiModel.id}/>
				</Avatar>
			</Grid>
			<Grid item xs={9}>
				<Typography variant='h4'>{usersByModelAccess.aiModel.name}</Typography>
			</Grid>
			<Grid item xs={1} className='closerDrawerWrapper'>
				<CloseIcon onClick={toggleDrawer(false, 'SHOW_GROUP_AI_MODEL_ACCESS')}
				           cursor='pointer' className='closeIcon'/>
			</Grid>
		</Grid>
		<Grid item xs={12}>
			{usersByModelAccess.userInfoWithModelAccess.map(user =>
				<Grid item xs={12} key={user.userId} container className='userRow'>
					<Grid item xs={1}>
						<Avatar className='userIcon' src={user.imageUrl} {...stringAvatar(user.userFullName ?? '')} />
					</Grid>
					<Grid item xs={11}>
						<Typography>{user.userFullName}</Typography>
					</Grid>
				</Grid>
			)}
		</Grid>
	</Grid>

}