import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation'
import PhoneIcon from '@mui/icons-material/Phone'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import BadgeIcon from '@mui/icons-material/Badge'
import KeyIcon from '@mui/icons-material/Key'
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications'

interface FormatItemIconProps {
	format: string
}

export const FormatItemIcon = ({format}: FormatItemIconProps) => {
	if(format.includes('bank')) return <AccountBalanceIcon className='formatIcon'/>
	if(format.includes('email')) return <AlternateEmailIcon className='formatIcon'/>
	if(format.includes('social')) return <MedicalInformationIcon className='formatIcon'/>
	if(format.includes('phone')) return <PhoneIcon className='formatIcon'/>
	if(format.includes('credit')) return <CreditCardIcon className='formatIcon'/>
	if(format.includes('id')) return <BadgeIcon className='formatIcon'/>
	if(format.includes('key')) return <KeyIcon className='formatIcon'/>
	return <SettingsApplicationsIcon className='formatIcon'/>
}