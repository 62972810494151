import {FC} from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography} from '@mui/material'
import {OptimizationSettings} from './OptimizationSettings'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import CloseIcon from '@mui/icons-material/Close'
import './OptimizationSettingsDialog.scss'

type OptimizationSettingsDialogProps = {
	showSliders: boolean
	onClose: () => void
}

export const OptimizationSettingsDialog: FC<OptimizationSettingsDialogProps> = ({showSliders, onClose}) =>
	<Dialog onClose={onClose} open={showSliders} className='advancedSettingsDialogContainer' fullWidth={true}>
		<DialogTitle className='title'>
			<AutoFixHighIcon/>
			Configure prompt optimisation
			<IconButton onClick={onClose} className='closeDialog'>
				<CloseIcon/>
			</IconButton>
		</DialogTitle>
		<DialogContent>
			<Typography>Change the values to get a new prompt that helps you get results optimized for your needs.</Typography>
			<OptimizationSettings/>
		</DialogContent>
		<DialogActions className='advancedSettingsActionsContainer'>
			<Button variant='contained' onClick={onClose}>Save</Button>
		</DialogActions>
	</Dialog>