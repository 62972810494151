import {createContext, ReactNode, useContext, useMemo} from 'react'
import {UserInfo} from '../types/UserInfo'
import {useUsersAccess} from '../hooks/useUsersAccess'


interface UsersAccessContextProviderProps {
	children: ReactNode
}

export interface UsersAccessContextValue {
	loading: boolean
	usersInfo: UserInfo[]
	updateUserInfo: (userInfo: UserInfo) => Promise<void>
}

const DEFAULT_USER_ACCESS_CONTEXT: UsersAccessContextValue = {
	loading: false,
	usersInfo: [],
	updateUserInfo: () => Promise.resolve()
}

export const UsersAccessContext = createContext<UsersAccessContextValue>(DEFAULT_USER_ACCESS_CONTEXT)

export const useUsersAccessContext = () => useContext(UsersAccessContext)

export const UsersAccessContextProvider = ({children}: UsersAccessContextProviderProps) => {
	const {loading, usersInfo, updateUserInfo} = useUsersAccess()

	const value: UsersAccessContextValue = useMemo(() => ({loading, usersInfo, updateUserInfo}),
		[loading, usersInfo, updateUserInfo])

	return <UsersAccessContext.Provider value={value}>
		{children}
	</UsersAccessContext.Provider>
}