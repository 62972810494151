import {Box, Button, MenuItem, Select, SelectChangeEvent, TextField, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, {ChangeEvent, useState} from 'react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {WarningLevel, WordWarning} from '../../../../types/Warning'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useWarningsContext} from '../../../../context/WarningsContext'
import {useUser} from '@clerk/clerk-react'
import {useFeedbackContext} from '../../../../context/FeedbackContext'

interface EditWordWarningProps {
	wordWarningToEdit: WordWarning
}

export const EditWordWarning = ({wordWarningToEdit}: EditWordWarningProps) => {

	const {user} = useUser()
	const {showFeedback} = useFeedbackContext()
	const {toggleDrawer} = useToggleDrawerContext()
	const {wordWarnings, editWordWarning} = useWarningsContext()


	const [updatedWord, setUpdatedWord] = useState<string>(wordWarningToEdit.word)
	const [updatedWarningLevel, setUpdatedWarningLevel] = useState<WarningLevel>(wordWarningToEdit.level)

	const [errorMessage, setErrorMessage] = useState<string>('')
	const [wordValidError, setWordValidError] = useState<boolean>(false)

	const handleUpdateWordWarningClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
		if (!updatedWord) {
			setWordValidError(true)
			setErrorMessage('The word is mandatory')
			return
		}
		if (wordWarnings.find(wordWarning => wordWarning.word === updatedWord && wordWarning.hashKey !== wordWarningToEdit.hashKey)) {
			setWordValidError(true)
			setErrorMessage('That word already exists. Try adding a different one')
			return
		}
		if (updatedWord.includes(' ')) {
			setWordValidError(true)
			setErrorMessage('You can only add one word at a time. Edit your word and try again.')
			return
		}
		setWordValidError(false)

		const error = await editWordWarning({...wordWarningToEdit, word: updatedWord, level: updatedWarningLevel})
		if (error) {
			setWordValidError(true)
			setErrorMessage(error)
			return
		}

		showFeedback('Word updated', '', 'success', 5)
		TrackActionEvent('Warnings', user?.externalId ?? user?.id, {action: 'edit', type: 'word'})
		return toggleDrawer(false, 'EDIT_WORD_WARNING_DRAWER')(event)
	}

	return <Box className='editWarningContainer'>
		<Box className='editWarningHeader'>
			<Typography variant='h3'>Configuration</Typography>
			<CloseIcon onClick={toggleDrawer(false, 'EDIT_WORD_WARNING_DRAWER')}/>
		</Box>
		<Typography variant='body2'>Use the following options to define your word.</Typography>
		<TextField fullWidth label='Word' variant='outlined' className='textFieldInput configDrawer'
		           value={updatedWord} error={wordValidError} helperText={wordValidError ? errorMessage : ''}
		           onChange={(event: ChangeEvent<HTMLInputElement>) => setUpdatedWord(event?.target?.value?.trim() ?? '')}/>
		<Select label="Warning level" onChange={(event: SelectChangeEvent) => setUpdatedWarningLevel(event.target.value as WarningLevel)} value={updatedWarningLevel}
		        className='newWarningTextfield warningLevelSelect edit' fullWidth>
			<MenuItem value='low'>Low</MenuItem>
			<MenuItem value='medium'>Medium</MenuItem>
			<MenuItem value='high'>High</MenuItem>
		</Select>

		<Box className='saveWarningButtonContainer'>
			<Button onClick={handleUpdateWordWarningClicked}>Save</Button>
		</Box>
	</Box>
}