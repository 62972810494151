import {PaymentElement, useElements} from '@stripe/react-stripe-js'
import {Button, Typography} from '@mui/material'
import Spinner from '../../spinner/Spinner'
import React, {useState} from 'react'
import {useFeedbackContext} from '../../../context/FeedbackContext'

export const PaymentDetails = ({stripe}) => {
	const elements = useElements()
	const {showFeedback} = useFeedbackContext()

	const [isLoading, setIsLoading] = useState(false)

	const onSubmit = async (event) => {
		event.preventDefault()
		if (!stripe || !elements) return
		setIsLoading(true)

		const {error} = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: `${window.location.origin}/paymentSuccess`,
			},
		})

		if (error.type === "card_error" || error.type === "validation_error") {
			showFeedback(error.message ?? 'There was an error with your credit card', '', 'error')
		} else {
			showFeedback('An unexpected error occured.', '', 'error')
		}
		 setIsLoading(false)
	}

	return <>
		<PaymentElement/>
		<Button variant='contained' className='purchaseButton' fullWidth disabled={!stripe || !elements} onClick={onSubmit}>Submit</Button>
		{isLoading && <Spinner/>}
		<Typography className='paymentConditions'>By providing us with your card details, you allow Narus to charge your card for future payments according to the
			stipulated conditions.</Typography>
	</>
}