import {ChatMessageStored} from '../types/ChatMessageStored'
import {ChatMessage} from '../types/ChatMessage'

export const getStoredMessageProcessed = (storedMessage: ChatMessageStored, regeneratedOutputs: string[] = []): ChatMessage => ({
    sender: storedMessage.sender,
    text: storedMessage.text,
    optimized: storedMessage.optimized,
    regeneratedOutputs,
    messageId: storedMessage.messageId
})

export const getSortedRegeneratedOutputs = (regeneratedMessages: ChatMessageStored[], lastRegenerated: ChatMessageStored): string[] =>
    regeneratedMessages
        .filter(chatMessage => chatMessage.messageId !== lastRegenerated?.messageId)
        .sort((a, b) => (a.regeneratedMessageIndex ?? 0) - (b.regeneratedMessageIndex ?? 0))
        .map(chatMessage => chatMessage.text)

export const sortChatMessages = (messages: ChatMessageStored[]): ChatMessageStored[] =>
    messages.sort((a, b) => {
        if (a.order !== b.order) return a.order - b.order
        return (a.regeneratedMessageIndex ?? -1) - (b.regeneratedMessageIndex ?? -1) // If orders are the same, sort by regeneratedMessageIndex
    })