import {Grid} from '@mui/material'
import {CustomDateRangePicker} from './CustomDateRangePicker'
import {AiUsageChart} from './charts/AiUsageChart'
import {CostPerAIModel} from './charts/CostPerAIModel'
import {UsageTable} from './charts/UsageTable'

export const AdminOverview = () => <>
    <Grid item xs={12} className='datePickerContainer'>
        <CustomDateRangePicker currentTab='OVERVIEW'/>
    </Grid>
    <Grid container item xs={12} columnSpacing={'12px'} rowSpacing={'45px'}>
        <Grid item xs={7}>
            <AiUsageChart/>
        </Grid>
        <Grid item xs={5}>
            <CostPerAIModel/>
        </Grid>
        <Grid item xs={12}>
            <UsageTable/>
        </Grid>
    </Grid>
</>