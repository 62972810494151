import React from 'react'
import {Box, Chip} from "@mui/material"
import './ChipsLabelTeam.scss'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import {useChatsContext} from '../../context/ChatsContext'

interface ChipsLabelTeamProps {
    chips?: string[]
    isLabel?: boolean
}

const ChipsLabelTeam = ({chips, isLabel}: ChipsLabelTeamProps) => {

    const {promptLabels, setPromptLabels, setValueHandler} = usePromptCreationContext()
    const {setChatValueHandler, setChatLabels, chatLabels} = useChatsContext()

    const handleDeleteLabel = (labelToDelete: string) => () => {
        setValueHandler(setPromptLabels, promptLabels.filter(label => label !== labelToDelete), 'labels')
    }

    const handleDeleteCategory = (categoryToDelete: string) => () => {
        setChatValueHandler(setChatLabels, chatLabels.filter(category => category !== categoryToDelete), 'categories')
    }

    return (
        <Box className='chipsLabelTeamWrapper'>
            {chips?.map((chipName, index) =>
                <Chip
                    key={`chip-${index}`}
                    className={isLabel ? 'chip chipLabel' : 'chip chipCategory'}
                    label={chipName}
                    onDelete={isLabel ? handleDeleteLabel(chipName) : handleDeleteCategory(chipName)}
                />
            )}
        </Box>
    )
}

export default ChipsLabelTeam