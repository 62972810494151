import {Box, SxProps, Theme} from '@mui/material'
import {ADMIN_SETTINGS, APP_MENU_SIDE_WIDTH} from '../../constants/AppMenuConstants'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {ReactComponent as AdaptavistIcon} from '../../images/adaptavist.svg'
import {AppMenuSideItem} from './AppMenuSideItem'
import {CustomSideItemHelp} from './customSideItems/CustomSideItemHelp'
import './AppMenuSideItems.scss'

const sx: SxProps<Theme> = {
    width: `${APP_MENU_SIDE_WIDTH}px`,
    minWidth: `${APP_MENU_SIDE_WIDTH}px`,
    maxWidth: `${APP_MENU_SIDE_WIDTH}px`,
}

export const AppMenuSideItems = () => {
    const { userInfo } = useUserInfoContext()
    const isAdmin = userInfo?.isAdmin
    
    return <Box className='appMenuSideItemsContainer' sx={sx}>
        <Box className='sideItemsSection'>
            <Box className='sideItemsLogo'>
                <AdaptavistIcon/>
            </Box>
        </Box>
        <Box className='sideItemsSection'>
            <CustomSideItemHelp/>
            { isAdmin ? <AppMenuSideItem item={ADMIN_SETTINGS} /> : <></>}
        </Box>
    </Box>
}