import {AppMenuItem} from '../types/AppMenu'
import {ChipAlerts} from '../components/admin/ChipAlerts'
import {ChipAlertsIcon} from '../components/admin/ChipAIertsIcon'

/** Left AppMenu side (in pixels) */
export const APP_MENU_SIDE_WIDTH = 38
/** Right AppMenu side (in pixels) */
export const APP_MENU_CONTENT_WIDTH = 243
export const APP_MENU_TOTAL_WIDTH = APP_MENU_SIDE_WIDTH + APP_MENU_CONTENT_WIDTH
export const APP_MENU_ITEM_TITLE_REPORTS = 'Reports'

export const NEED_HELP: AppMenuItem = {
    icon: 'help',
    title: 'Need Help?'
}

export const ADMIN_SETTINGS: AppMenuItem = {
    icon: 'settings',
    title: 'Workspace Settings',
    navigateTo: '/admin/configuration/',
    pathCondition: 'pathExactTo'
}

export const ADMIN_ITEMS: AppMenuItem[] = [
    {
        icon: 'spaceDashboard',
        title: 'Overview',
        navigateTo: '/admin',
        pathCondition: 'pathExactTo'
    },
    {
        icon: 'summarize',
        title: APP_MENU_ITEM_TITLE_REPORTS,
        navigateTo: '/admin/logs',
        buttonChildren: <ChipAlerts/>,
        listItemChildren: <ChipAlertsIcon/>
    }
]

export const ADMIN_CONFIG_ITEMS: AppMenuItem[] = [
    {
        icon: 'autoAwesome',
        title: 'AI models',
        navigateTo: '/admin/configuration/models'
    },
    {
        icon: 'groups',
        title: 'Teams',
        navigateTo: '/admin/configuration/groups'
    },
    {
        icon: 'payments',
        title: 'Budget control',
        navigateTo: '/admin/configuration/budget'
    },
    {
        icon: 'peopleAlt',
        title: 'AI User access',
        navigateTo: '/admin/configuration/users'
    },
    {
        icon: 'lock',
        title: 'Safeguard',
        navigateTo: '/admin/configuration/safeguard'
    }
]

export const HOME_ITEMS: AppMenuItem[] = [
    {
        icon: 'home',
        title: 'Home',
        navigateTo: '/',
        pathCondition: 'pathExactTo'
    }
]

export const USER_ITEMS: AppMenuItem[] = [
    {
        icon: 'chat',
        title: 'Chats',
        navigateTo: '/chats'
    },
    {
        icon: 'libraryBooks',
        title: 'My Prompts',
        navigateTo: '/myPrompts'
    },
    {
        icon: 'autograph',
        title: 'Inspiration prompts',
        navigateTo: '/inspirationPrompts'
    }
]
