import React from 'react'
import {Box, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select} from '@mui/material'
import Chip from '@mui/material/Chip'
import {useSearchContext} from '../../context/SearchContext'
import {AIModelID} from '../../types/AiModel'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {isModelEnabledForUser} from '../../helpers/AiModelHelper'
import {AiModelSelectorProps} from '../../types/AiModelSelectorProps'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import './AiModelSelector.scss'
import {AiModelIcon} from '../icons/AiModelIcon'
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome'
import {useUserGroupContext} from '../../context/UserGroupContext'

export const AiModelSelector = ({customModelsSelected, setCustomModelsSelected, highlightModelSelector, disabled}: AiModelSelectorProps) => {

	const {aiModels} = useAiModelsContext()
	const {userInfo} = useUserInfoContext()
	const {userGroup} = useUserGroupContext()
	const {modelsSelected, setModelsSelected} = useSearchContext()

	// const amazonQName: string | undefined = aiModels?.find(({id}) => id === AIModelID.AMAZON_Q)?.name

	const onClickModelHandler = (id: AIModelID) => {
		setCustomModelsSelected ?
			setCustomModelsSelected(prevState => ({...prevState, [id]: !prevState[id]})) :
			setModelsSelected(prevState => ({...prevState, [id]: !prevState[id]}))
	}

	const onClickDeleteModelHandler = (id: AIModelID) => {
		setCustomModelsSelected ?
			setCustomModelsSelected(prevState => ({...prevState, [id]: false})) :
			setModelsSelected(prevState => ({...prevState, [id]: false}))
	}

	const enabledAndSelectedModels = aiModels
		.filter(({id, name}) => id !== AIModelID.AMAZON_Q && isModelEnabledForUser(id, aiModels, userInfo, userGroup) && ((customModelsSelected && customModelsSelected[id]) || (!customModelsSelected && modelsSelected[id])))

	return <Box>
		<Box className='aiModelContainer'>
			<FormControl className='modelSelectorWrapper' disabled={disabled}>
				<InputLabel id="model-label" className='modelLabel'><AutoAwesomeIcon className='selectModelIcon'/>Select AI models</InputLabel>
				<Select value={[]}
				        labelId="model-label"
				        className={`${highlightModelSelector ? 'gradient-border' : ''} modelSelector`}
				        size='small'
				        multiple
				        input={<OutlinedInput label='____Select AI models'/>}
				        onChange={event => onClickModelHandler(event.target.value as AIModelID)}
				        IconComponent={KeyboardArrowDownIcon}
				>
					{aiModels
						.filter(({id, name}) => id !== AIModelID.AMAZON_Q && isModelEnabledForUser(id, aiModels, userInfo, userGroup))
						.map(({id, name}) => (
							<MenuItem key={id} value={id}>
								<Checkbox checked={customModelsSelected ? customModelsSelected[id] : modelsSelected[id]}/>
								<ListItemText primary={name}/>
							</MenuItem>
						))}
				</Select>
			</FormControl>
			{disabled ? <></> : <Box className='modelsRow'>
				{enabledAndSelectedModels
					.map(({id, name}) => <Chip label={name}
											   className={`aiModelChip ${customModelsSelected ? (customModelsSelected[id] && 'selected') : (modelsSelected[id] && 'selected')}`}
											   key={id}
											   onClick={() => onClickModelHandler(id)}
											   onDelete={() => onClickDeleteModelHandler(id)}
											   icon={<AiModelIcon modelId={id}/>}/>
					)}
			</Box>}
		</Box>

		{/*{isModelEnabledForUser(AIModelID.AMAZON_Q, aiModels, userInfo?.disabledAIModels) ? <Box className='aiModelContainer confluence'>*/}
		{/*	<Typography>Internal knowledge:</Typography>*/}
		{/*	<Chip label={amazonQName} className={`aiModelChip ${modelsSelected[AIModelID.AMAZON_Q] && 'selected'}`}*/}
		{/*	      onClick={() => setModelsSelected(prevState => ({...prevState, [AIModelID.AMAZON_Q]: !prevState[AIModelID.AMAZON_Q]}))}/>*/}
		{/*</Box> : <></>}*/}
	</Box>
}