import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {UserInfo} from '../types/UserInfo'
import {useFeedbackContext} from '../context/FeedbackContext'
import {useUser} from '@clerk/clerk-react'
import { UserInfoContextValue } from '../context/UserInfoContext'
import {getUserInfoByUserIdWithRetries, updateUserSelectedModels} from '../service/persistenceService'
import {AIModelID} from '../types/AiModel'
import {isEqual} from '../utils/genericUtils'

export const useUserInfo = (): UserInfoContextValue => {
	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()
	const {user} = useUser()

	const [loading, setLoading] = useState<boolean>(true)
	const [userInfo, setUserInfo] = useState<UserInfo>()

	const getUserInfo = useCallback(() => {
		if (!user?.externalId && !user?.id) return
		setLoading(true)
		getUserInfoByUserIdWithRetries(token, user.externalId ?? user.id).then(userInfo => {
			setUserInfo(userInfo)
			setLoading(false)
		}).catch(error => {
			showFeedback('Error', error.cause === 403 ?
				'It seems like you don\'t have permissions to access this page. Contact your admin to know more.' :
				'Something went wrong to load data. Try again reloading the page', 'error')
			setUserInfo(undefined)
		}).finally(() => setLoading(false))

	}, [token, showFeedback, user?.id, user?.externalId])

	const updateUserModels = useCallback((models: AIModelID[]) => {
		if (userInfo && !isEqual(userInfo.selectedAIModels, models)) {
			updateUserSelectedModels(token, models)
				.then(selectedAIModels => {
					setUserInfo({...userInfo, selectedAIModels})
				}).catch(() => {
				showFeedback('Error', 'Something went wrong to update the AI models.', 'error')
			})
		}
	}, [userInfo, showFeedback, token])

	useEffect(() => {
		getUserInfo()
	}, [getUserInfo])

	return useMemo(() => ({
		loading,
		userInfo,
		updateUserModels
	}), [loading, userInfo, updateUserModels])
}