import {FC} from 'react'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material'
import {UserAuditLog, UserPromptAudit} from '../../../types/UserAuditLog'
import {getAuditLogHighestWarningLevel, userPromptAuditDateComparator} from '../../../utils/userAuditLogUtils'
import {getDateAndTime} from '../../../helpers/DateHelpers'
import {AuditLogWarningLevelChip} from './AuditLogWarningLevelChip'
import {AuditingLogDetailPromptInfo} from './AuditingLogDetailPromptInfo'
import { AuditingLogDetailPromptWarnings } from './AuditingLogDetailPromptWarnings'

type AuditingLogDetailListProps = {
    auditLog: UserAuditLog
}

export const AuditingLogDetailList: FC<AuditingLogDetailListProps> = ({
    auditLog
}) => {
    const { messages } = auditLog
    return <>
        <Box className='auditLogsListTitleContainer'>
            <WarningAmberIcon className='auditingLogsListTitleIcon'/>
            <Typography variant='h3' className='auditingLogsListTitle'>Warning</Typography>
        </Box>
        <Box className='auditLogsListContainer'>
            { messages
                .sort(userPromptAuditDateComparator)
                .map(renderPromptAudit) }
        </Box>
    </>
}

const renderPromptAudit = (promptAudit: UserPromptAudit, index: number) => {
    const { date, userPrompt, warnings } = promptAudit

    return warnings.length ?
        <Accordion key={`auditAccordion-${index}`} defaultExpanded={index === 0}>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <AuditLogWarningLevelChip level={getAuditLogHighestWarningLevel(promptAudit)}/>
                <Typography className='title'>Prompt {index + 1}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <AuditingLogDetailPromptInfo promptAudit={promptAudit} />
                <AuditingLogDetailPromptWarnings warnings={warnings} />
                <Typography className='date'>
                    Hour: {getDateAndTime(date)}
                </Typography>
                <Typography className='prompt'>
                    {userPrompt}
                </Typography>
            </AccordionDetails>
        </Accordion> : <Box key={`auditAccordion-${index}`}></Box>
}