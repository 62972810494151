import {Box, Button, TextField, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, {ChangeEvent, useState} from 'react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {emailMatchesDomain, isEmailDomain} from '../../../../utils/accountCreationHelpers'
import {useOrganizationContext} from '../../../../context/OrganizationContext'
import {useUser} from '@clerk/clerk-react'
import {TrackActionEvent} from '../../../../service/SegmentService'

export const EditOrganizationDomain = ({domain}) => {

	const {user} = useUser()
	const {toggleDrawer} = useToggleDrawerContext()
	const {organization, editOrganization} = useOrganizationContext()

	const [updatedDomain, setUpdatedDomain] = useState<string>(domain)
	const [errorMessageFromBackend, setErrorMessageFromBackend] = useState<string>('')
	const [domainValidError, setDomainValidError] = useState<boolean>(false)

	const handleUpdateOrganizationDomainClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const disabledCurrentDomainAction = user?.emailAddresses.length ? emailMatchesDomain(user?.emailAddresses[0].emailAddress)(domain) : false
		if (!organization?.allowedDomains?.length || disabledCurrentDomainAction) return
		if (!isEmailDomain(updatedDomain)) {
			setDomainValidError(true)
			return
		}

		const domainToUpdateIndex = organization.allowedDomains.findIndex(allowedDomain => allowedDomain === domain)
		const allowedDomains = [...organization.allowedDomains]
		allowedDomains[domainToUpdateIndex] = updatedDomain
		const error = await editOrganization(organization.name ?? '', allowedDomains)
		if (error) {
			setErrorMessageFromBackend(error)
			setDomainValidError(true)
		} else {
			TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {action: 'editDomain', organization_domains_count: allowedDomains.length ?? 0})
			return toggleDrawer(false, 'EDIT_ORGANIZATION_DOMAIN_DRAWER')(event)
		}
	}

	return <Box className='aiModelConfig'>
		<Box className='aiModelConfigHeader'>
			<Typography variant='h3'>Configuration</Typography>
			<CloseIcon onClick={toggleDrawer(false, 'EDIT_ORGANIZATION_DOMAIN_DRAWER')}/>
		</Box>
		<Typography variant='body2'>Modify the values of the domain you have set up</Typography>
		<TextField fullWidth label='Domain' variant='outlined' className='textFieldInput configDrawer'
		           value={updatedDomain} error={domainValidError} helperText={domainValidError ? errorMessageFromBackend || 'Admin email has to follow the example *@acme.com' : ''}
		           onChange={(event: ChangeEvent<HTMLInputElement>) => setUpdatedDomain(event?.target?.value ?? '')}/>
		<Box className='saveAiModelButtonContainer'>
			<Button onClick={handleUpdateOrganizationDomainClicked}>Save</Button>
		</Box>
	</Box>
}