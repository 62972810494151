import {useCallback, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Prompt} from '../../types/Prompt'
import {usePromptsContext} from '../../context/PromptsContext'
import {useFeedbackContext} from '../../context/FeedbackContext'
import {PromptDetailsProps} from '../../types/PromptDetailsProps'
import {useSearchContext} from '../../context/SearchContext'
import {usePromptCreationContext} from '../../context/PromptCreationContext'
import Spinner from '../../components/spinner/Spinner'
import {Box} from '@mui/material'
import './PromptDetail.scss'
import {AiChat} from '../../components/aiChat/AiChat'
import {AIModels} from '../../types/AiModel'
import {PROMPT_TEMPLATES} from '../../constants/PromptTemplates'
import {isAiModel} from '../../helpers/AiModelHelper'

export const PromptDetail = ({copy, runAiPrompt}: PromptDetailsProps) => {

    const navigate = useNavigate()
    const {promptId, models} = useParams()
    const {prompts, getPromptById} = usePromptsContext()
    const {searchedPrompts} = useSearchContext()
    const {showFeedback} = useFeedbackContext()
    const {fillPromptFields, modelIds, setModelIds, setPromptChats, setUserPrompt, setAiPrompt, setPromptId} = usePromptCreationContext()

    const [selectedPrompt, setSelectedPrompt] = useState<Prompt | undefined>()

    const displayNoPromptFound = useCallback(() => {
        showFeedback('Error', 'Can\'t find that prompt!', 'error')
        navigate('/')
    }, [showFeedback, navigate])

    const loadPromptData = useCallback((prompt: Prompt) => {
        fillPromptFields(prompt)

        const modelsFromPath = (models ?? '').split(',').filter(isAiModel)
        const modelsToExecute = modelIds.length ?
            modelIds : modelsFromPath.length ?
                modelsFromPath : prompt.modelId ?
                    [AIModels[prompt.modelId!].id] : []
        if (!modelIds) {
            setModelIds(modelsToExecute)
        }

        if (!modelsToExecute.length) {
            return navigate(-1)
        }

        if (runAiPrompt && !prompt.isTemplate) {
            setPromptChats(modelsToExecute.map(modelId => ({modelId, messages: [{sender: 'user', text: prompt.aiPrompt, optimized: true}], compilationSummary: { summary: ''}})))
            setUserPrompt('')
        } else {
            setPromptChats(modelsToExecute.map(modelId => ({modelId, messages: [], compilationSummary: { summary: ''}})))
            setUserPrompt(prompt.aiPrompt || prompt.userPrompt)
        }
        setAiPrompt('')
    }, [fillPromptFields, runAiPrompt, setAiPrompt, setModelIds, setPromptChats, setUserPrompt, modelIds, models, navigate])

    useEffect(() => {
        if (promptId && (prompts.length > 0 || searchedPrompts.length > 0) && selectedPrompt === undefined) {
            const selectedPrompt = prompts.find(prompt => prompt.id === promptId) || searchedPrompts.find(prompt => prompt.id === promptId) || PROMPT_TEMPLATES.find(prompt => prompt.id === promptId)

            if (selectedPrompt) {
                const prompt = copy ? {...selectedPrompt, id: undefined} : selectedPrompt
                setSelectedPrompt(prompt)
                setPromptId(copy ? undefined : prompt.id)
                loadPromptData(prompt)
            } else {
                getPromptById(promptId).then(prompt => {
                        if (!prompt) return displayNoPromptFound()
                        loadPromptData(prompt)
                        setPromptId(copy ? undefined : prompt.id)
                    })
                    .catch(() => displayNoPromptFound())
            }
        }
    }, [prompts, navigate, promptId, selectedPrompt, showFeedback, copy, displayNoPromptFound, getPromptById, searchedPrompts, fillPromptFields, setAiPrompt, setModelIds, setPromptChats, setUserPrompt, loadPromptData, setPromptId])

    return selectedPrompt !== undefined ? <AiChat selectedPrompt={selectedPrompt}/> : <Box className='centralSpinnerContainer'><Spinner/></Box>
}
