import {FC} from 'react'
import {AppMenuItem} from '../../types/AppMenu'
import {ListItem, ListItemButton, ListItemIcon, ListItemText} from '@mui/material'
import {AppMenuIcon} from './AppMenuIcon'
import {useAppMenuContext} from '../../context/AppMenuContext'
import {isAppMenuItemSelected} from '../../utils/appMenuUtils'
import {useLocation} from 'react-router-dom'
import {AppMenuTooltipWrapper} from './AppMenuTooltipWrapper'
import './AppMenuMainItem.scss'

type Props = {
    item: AppMenuItem
}

export const AppMenuMainItem: FC<Props> = ({item}) => {

    const {pathname} = useLocation()
    const {selectMenuItem} = useAppMenuContext()
    const {icon, title, listItemChildren, buttonChildren} = item
    const selectedSuffix = isAppMenuItemSelected({pathname})(item) ? ' selected' : ''

    return (
        <AppMenuTooltipWrapper item={item}>
            <ListItem className={`appMenuMainItemContainer${selectedSuffix}`}>
                <ListItemButton onClick={() => selectMenuItem(item)}>
                    <ListItemIcon>
                        <AppMenuIcon icon={icon}/>
                    </ListItemIcon>
                    <ListItemText>{title}</ListItemText>
                    {buttonChildren}
                </ListItemButton>
                {listItemChildren}
            </ListItem>
        </AppMenuTooltipWrapper>
    )
}