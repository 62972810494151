import React, {ChangeEvent, useState} from 'react'
import {Box, IconButton, Menu, MenuItem, Typography} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {GroupConfirmationDialog} from './GroupConfirmationDialog'
import {Group} from '../../../../types/Group'
import './EditGroupMenu.scss'

interface EditGroupMenuProps {
    group: Group | undefined
}

export type GroupAction = 'RENAME' | 'REMOVE'

export const EditGroupMenu = ({group}: EditGroupMenuProps) => {

    const {deleteGroup, editGroup} = useGroupsContext()

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [groupDialog, setGroupDialog] = useState<{
        action?: GroupAction,
        open: boolean
    }>({open: false})
    const [groupName, setGroupName] = useState<string>()
    const [hasError, setHasError] = useState<boolean>(false)
    const openMoreMenu = Boolean(anchorEl)

    const teamThreeDotMenuHandler = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const groupDialogConfirmationHandler = (action: GroupAction) => {
        setAnchorEl(null)
        setGroupDialog({action, open: true})
    }

    const groupActionHandler = async (action: GroupAction) => {
        if (action === 'REMOVE') {
            await deleteGroup(group?.hashKey!)
        } else {
            if (!groupName) {
                setHasError(true)
                return
            }
            await editGroup(group?.hashKey!, groupName)
        }
        setGroupDialog({open: false})
    }

    const changeGroupNameHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setHasError(false)
        setGroupName(event.target.value)
    }

    return <>
        <Box>
            <IconButton aria-label='more'
                        id='demo-more-button'
                        aria-controls={openMoreMenu ? 'demo-more-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={openMoreMenu ? 'true' : undefined}
                        onClick={teamThreeDotMenuHandler}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
                open={openMoreMenu}
                onClose={() => setAnchorEl(null)}
                className='teamTableHeaderMoreMenu'
                id='demo-more-menu'
                MenuListProps={{'aria-labelledby': 'demo-more-button'}}
            >
                <MenuItem onClick={() => groupDialogConfirmationHandler('REMOVE')} disableRipple>
                    <DeleteIcon className='teamMenuItemIcon'/><Typography
                    className='teamMenuItemText'>Delete Team</Typography>
                </MenuItem>
                <MenuItem onClick={() => groupDialogConfirmationHandler('RENAME')} disableRipple>
                    <EditIcon className='teamMenuItemIcon'/><Typography
                    className='teamMenuItemText'>Rename</Typography>
                </MenuItem>
            </Menu>
        </Box>
        <GroupConfirmationDialog onCloseDialog={() => setGroupDialog({open: false})}
                                 open={groupDialog.open}
                                 hasError={hasError}
                                 onConfirmAction={groupActionHandler}
                                 groupName={group?.name!}
                                 action={groupDialog.action}
                                 onChange={changeGroupNameHandler}/>
    </>
}