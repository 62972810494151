import {createContext, ReactNode, useContext} from 'react'
import {useTrial} from '../hooks/useTrial'

interface TrialContextProviderProps {
    children: ReactNode
}

export interface TrialContextValue {
    isMarketingEventSent: boolean
    trackMarketingEvent: () => Promise<void>
}

const DEFAULT_TRIAL_CONTEXT: TrialContextValue = {
    isMarketingEventSent: false,
    trackMarketingEvent: () => Promise.resolve()
}

export const TrialContext = createContext<TrialContextValue>(DEFAULT_TRIAL_CONTEXT)

export const useTrialContext = () => useContext(TrialContext)

export const TrialContextProvider = ({children}: TrialContextProviderProps) => {
    const value = useTrial()

    return <TrialContext.Provider value={value}>{children}</TrialContext.Provider>
}