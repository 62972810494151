import {useCallback, useEffect, useRef, useState} from 'react'
import {UserButton} from '@clerk/clerk-react'
import {ListItem, ListItemButton, Typography} from '@mui/material'
import {useAppMenuContext} from '../../context/AppMenuContext'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {CLERK_DEFAULT_USER_BUTTON_APPEARANCE} from '../../constants/ClerkConstants'
import './AppMenuProfileButton.scss'

export const AppMenuProfileButton = () => {
    const context = useAppMenuContext()
    const [isClerkOpen, setIsClerkOpen] = useState(false)
    const isAppMenuOpen = context.isOpen
    const buttonRef = useRef<HTMLDivElement>(null)
    const avatarRef = useRef<HTMLDivElement>(null)

    const appMenuSuffix = isAppMenuOpen ? 'open' : 'closed' 
    const clerkSuffix = isClerkOpen ? 'open' : 'closed'

    const handleClick = useCallback((event) => {
        setIsClerkOpen(prev => {
            if (!prev && avatarRef.current && !avatarRef.current.contains(event.target)) {
                const userAvatar = document.getElementsByClassName('cl-userButtonAvatarImage')[0] as HTMLElement
                userAvatar.click()
            }
            return !prev
        })
    }, [])

    useEffect(() => {
        function handleClickOutside(event) {
            if (buttonRef.current && !buttonRef.current.contains(event.target)) {
                setIsClerkOpen(false)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [])

    return (
        <ListItem className='appMenuProfileButtonContainer'>
            <ListItemButton ref={buttonRef} className={`buttonContainer ${appMenuSuffix}`} onClick={handleClick}>
                <div ref={avatarRef} >
                    <UserButton appearance={CLERK_DEFAULT_USER_BUTTON_APPEARANCE}/>
                </div>
                <Typography className={`buttonText ${appMenuSuffix}`}>Your profile</Typography>
                <KeyboardArrowDownIcon className={`buttonIcon ${clerkSuffix}`}/>
            </ListItemButton>
        </ListItem>
    )
}