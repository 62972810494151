import {createContext, Dispatch, ReactNode, SetStateAction, useContext} from 'react'
import {AIModelID} from '../types/AiModel'
import {usePromptCreation} from '../hooks/usePromptCreation'
import {Prompt, UserPromptTemplate, PromptProperties} from '../types/Prompt'
import {PromptExecutionResult} from '../types/PromptExecutionResult'
import {PromptChatDetail} from '../types/PromptChatDetail'
import { WithOptional } from '../types/Generic'

interface PromptCreationContextProviderProps {
	children: ReactNode
}

export interface PromptCreationContextValue {
	loading: boolean
	setLoading: Dispatch<SetStateAction<boolean>>
	aiPromptLoading: boolean
	setAiPromptLoading: Dispatch<SetStateAction<boolean>>
	aiOutputLoading: boolean
	setAiOutputLoading: Dispatch<SetStateAction<boolean>>
	aiPromptCompilationLoading: boolean
	setAiPromptCompilationLoading: Dispatch<SetStateAction<boolean>>
	userPrompt: string
	setUserPrompt: Dispatch<SetStateAction<string>>
	audience: string
	setAudience: Dispatch<SetStateAction<string>>
	tone: string
	setTone: Dispatch<SetStateAction<string>>
	format: string
	setFormat: Dispatch<SetStateAction<string>>
	modelId: AIModelID | undefined
	setModelId: Dispatch<SetStateAction<AIModelID | undefined>>
	modelIds: AIModelID[]
	setModelIds: Dispatch<SetStateAction<AIModelID[]>>
	aiPrompt: string
	setAiPrompt: Dispatch<SetStateAction<string>>
	aiOutput: string,
	setAiOutput: Dispatch<SetStateAction<string>>
	title: string
	setTitle: Dispatch<SetStateAction<string>>
	description: string
	setDescription: Dispatch<SetStateAction<string>>
	language: string
	setLanguage: Dispatch<SetStateAction<string>>
	frequencyPenalty: string
	setFrequencyPenalty: Dispatch<SetStateAction<string>>
	temperature: string
	setTemperature: Dispatch<SetStateAction<string>>
	promptLabels: string[]
	setPromptLabels: Dispatch<SetStateAction<string[]>>
	promptTeams: string[]
	isPublic: boolean
	setIsPublic: Dispatch<SetStateAction<boolean>>
	unsavedChanges: boolean
	setUnsavedChanges: Dispatch<SetStateAction<boolean>>
	promptChats: PromptChatDetail[]
	setPromptChats: Dispatch<SetStateAction<PromptChatDetail[]>>
	setPromptTeams: Dispatch<SetStateAction<string[]>>
	promptId: string | undefined
	setPromptId: Dispatch<SetStateAction<string | undefined>>
	amazonQConversationId: string | undefined
	amazonQSystemMessageId: string | undefined
	createPrompt: (promptTemplate: UserPromptTemplate) => Promise<Prompt | undefined>
	getAiGeneratedPrompt: (promptTemplateData: UserPromptTemplate, signal: AbortSignal, promptId?: string) => Promise<PromptExecutionResult>
	getAiOutput: (aiGeneratedPrompt: UserPromptTemplate, signal: AbortSignal, isRegeneratedMessage: boolean, promptId?: string, previousMessages?: string[]) => Promise<PromptExecutionResult>
	getPromptTemplate: () => WithOptional<UserPromptTemplate, 'modelId'>
	clearPromptFields: () => void
	fillPromptFields: (prompt: Prompt) => void
	setValueHandler: <T,>(set: Dispatch<SetStateAction<T>> | Dispatch<SetStateAction<T | undefined>>, value: T, field: PromptProperties) => void
	getPromptCompilation: (promptData: UserPromptTemplate, signal: AbortSignal, messages: string[], promptId?: string) => Promise<PromptExecutionResult | void>
	isOptimizedPrompt: () => boolean
	clearPromptOptimizationFields: () => void
}

const DEFAULT_PROMPT_CREATION_CONTEXT: PromptCreationContextValue = {
	loading: false,
	setLoading: () => {},
	aiPromptLoading: false,
	setAiPromptLoading: () => {},
	aiOutputLoading: false,
	setAiOutputLoading: () => {},
	aiPromptCompilationLoading: false,
	setAiPromptCompilationLoading: () => {},
	userPrompt: '',
	setUserPrompt: () => {},
	audience: '',
	setAudience: () => {},
	tone: '',
	setTone: () => {},
	format: '',
	setFormat: () => {},
	modelId: undefined,
	setModelId: () => {},
	modelIds: [],
	setModelIds: () => {},
	aiPrompt: '',
	setAiPrompt: () => {},
	aiOutput: '',
	setAiOutput: () => {},
	title: '',
	setTitle: () => {},
	description: '',
	setDescription: () => {},
	language: '',
	setLanguage: () => {},
	frequencyPenalty: '',
	setFrequencyPenalty: () => {},
	temperature: '',
	setTemperature: () => {},
	promptLabels: [],
	setPromptLabels: () => {},
	promptTeams: [],
	setPromptTeams: () => {},
	isPublic: false,
	setIsPublic: () => {},
	getPromptCompilation: () => Promise.resolve(),
	unsavedChanges: false,
	setUnsavedChanges: () => {},
	promptChats: [],
	setPromptChats: () => {},
	promptId: undefined,
	setPromptId: () => {},
	amazonQConversationId: undefined,
	amazonQSystemMessageId: undefined,
	createPrompt: () => Promise.resolve(undefined),
	getAiGeneratedPrompt: () => Promise.resolve({aiOutput: '', tokenUsage: 0}),
	getAiOutput: () => Promise.resolve({aiOutput: '', tokenUsage: 0}),
	clearPromptFields: () => {},
	fillPromptFields: () => {},
	setValueHandler: () => {},
	getPromptTemplate: () => ({
		title: '',
		description: '',
		userPrompt: '',
		modelId: AIModelID.GPT_3_5,
		audience: '',
		tone: '',
		format: '',
		frequencyPenalty: '',
		language: '',
		temperature: '',
		aiPrompt: '',
		labels: [],
		teams: [],
		isPublic: false
	}),
	isOptimizedPrompt: () => false,
	clearPromptOptimizationFields: () => {}
}

export const PromptCreationContext = createContext<PromptCreationContextValue>(DEFAULT_PROMPT_CREATION_CONTEXT)

export const usePromptCreationContext = () => useContext(PromptCreationContext)

export const PromptCreationContextProvider = ({children}: PromptCreationContextProviderProps) => {
	const value = usePromptCreation()

	return <PromptCreationContext.Provider value={value}>
		{children}
	</PromptCreationContext.Provider>
}