import { Alert, Button } from "@mui/material"
import {useBudgetsContext} from '../../context/BudgetContext'
import { useNavigate } from "react-router-dom"
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'

export const NoBudgetAdminAlert = () => {

	const {user} = useUser()
	const navigate = useNavigate()
	const {isOverallBudgetFinished} = useBudgetsContext()

	if(!isOverallBudgetFinished) return <></>

	const allocateMoreBudgetHandler = () => {
		TrackActionEvent('Budget', user?.id, {action: 'allocate_more_budget_admin', type: 'overall'})
		navigate('/admin/configuration/budget')
	}

	return <Alert severity="error" className='noBudgetAdminBanner' action={
		<Button color="inherit" size="small" onClick={allocateMoreBudgetHandler}>
			ALLOCATE MORE BUDGET
		</Button>
	}>The organization has spent all of its allotted budget; allocate more budget so it can continue to send prompts.</Alert>
}