import {Box, Button, Typography} from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { FC } from 'react'
import { StepIndex } from '../../types/AccountCreation'
import './AccountCreationError.scss'
import { RoundIcon } from '../common/roundIcon/RoundIcon'

type AccountCreationErrorProps = {
    index: StepIndex
}

export const AccountCreationError: FC<AccountCreationErrorProps> = ({
    index
}) => {
    const { resetStepState } = useAccountCreationContext()

    const handleButtonClicked = () => {
        resetStepState(index)
    }

	return <Box className='AccountCreationError'>
        <RoundIcon className='AccountCreationError_Icon' icon={ErrorIcon} size={100} iconSize={67} />
        <Box className='AccountCreationError_Content'>
            <Typography variant='h3' className='AccountCreationError_Title'>Sorry, we were unable to synchronize the data.</Typography>
            <Typography variant='h5' className='AccountCreationError_Subtitle'>We had a problem connecting to the database, please reload the page and enter the data again.</Typography>
            <Button variant='contained' onClick={handleButtonClicked}>Reload</Button>
        </Box>
    </Box>
}