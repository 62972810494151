import React, {FC, ReactElement} from 'react'
import {Box, Button, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import './EmptySection.scss'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'

type EmptySectionProps = {
    icon?: ReactElement
    title: string
    description: string
    isTeamsSection?: boolean
}

export const EmptySection: FC<EmptySectionProps> = ({
    icon,
    title,
    description,
    isTeamsSection
}) => {
    const navigate = useNavigate()
    const {toggleDrawer} = useToggleDrawerContext()

    const getSectionClassNames = (stringModifier: string) =>
        isTeamsSection ? `EmptyTeams_${stringModifier}` : `EmptySection_${stringModifier}`

    const emptySectionClickHandler = (event: React.MouseEvent) =>
        isTeamsSection ? toggleDrawer(true, 'CREATE_GROUP_DRAWER')(event) : navigate('/')

    return <Box className={getSectionClassNames('Container')}>
        { icon ?? <></> }
        <Box className='EmptySection_Text'>
            <Typography className={getSectionClassNames('Title')}>{title}</Typography>
            <Typography className={getSectionClassNames('Description')}>{description}</Typography>
        </Box>
        <Button variant='contained' onClick={emptySectionClickHandler}>{isTeamsSection ? 'Create a new team' : 'Start a new chat'}</Button>
    </Box>
}