import {Box, Button, ThemeProvider, Typography} from '@mui/material'
import {useNavigate} from 'react-router-dom'
import {CUSTOM_THEME} from '../themes/CustomThemes'
import {RoundIcon} from '../components/common/roundIcon/RoundIcon'
import ErrorIcon from '@mui/icons-material/InsertPageBreak'
import './RouterErrorPage.scss'

export const RouterErrorPage = () => {
    const navigate = useNavigate()
    
    return (
        <ThemeProvider theme={CUSTOM_THEME}>
            <Box className='routerErrorPageContainer'>
                <Box className='routerErrorPageContent'>
                    <RoundIcon className='routerErrorPageIcon' icon={ErrorIcon} size={200} iconSize={100}/>
                    <Box className='routerErrorPageText'>
                        <Typography className='routerErrorPageTitle'>
                            Oops! page not found
                        </Typography>
                        <Typography className='routerErrorPageDescription'>
                            We're sorry we couldn't find the page you were looking for. You can go back to the home page and search from there.
                        </Typography>
                    </Box>
                    <Button variant='contained' onClick={() => navigate('/')}>
                        Go to home
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    )
}