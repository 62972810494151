import {Box, Typography} from '@mui/material'
import {Topics} from './Topics'
import {Words} from './Words'
import './Warnings.scss'
import {Format} from './Format'
import './Safeguard.scss'

export const Safeguard = () => {
  return (
	<Box className='safeguardContainer'>
		<Typography className='title'>Safeguard</Typography>
		<Typography className='subtitle'>Identify what content trigger a warning. These warnings are shown in your Reports.</Typography>
		<Typography className='sectionTitle'>Data Types</Typography>
		<Topics/>
		<Words/>
		<Typography className='sectionTitle'>Format</Typography>
		<Format/>
	</Box>
  )
}