import * as React from 'react'
import {AIModels} from '../../types/AiModel'
import {Avatar, Box, Typography} from '@mui/material'
import {useEffect, useState} from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import {MessageLoadingProps} from '../../types/MessageLoadingProps'

export const MessageLoading = ({message, modelId, isUserMessage}: MessageLoadingProps) => {
    const model = AIModels[modelId]

    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 25))
        }, 800)
        return () => clearInterval(timer)
    }, [])


    return <Box className={`messageWrapper ${isUserMessage ? 'userMessage' : 'botMessage'}`}>
        <Box className={`messageContainer ${isUserMessage ? 'userMessage' : 'botMessage'}`}>
            <Avatar variant='rounded' sx={{bgcolor: 'rgba(0, 109, 122, 0.12)'}} >
                <CircularProgress variant="determinate" value={progress} size={30} thickness={5}/>
            </Avatar>

            <Box display='flex' flexDirection='column' flexGrow={1} alignItems={isUserMessage ? 'end' : 'start'}>
                <Box className='botNameContainer'>
                    <Typography className='messageAuthorName'>{model.name}</Typography>
                </Box>
                <Typography variant='body1' className='loadingText'>{message.text}</Typography>
            </Box>
        </Box>
    </Box>
}