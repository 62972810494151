import {Outlet} from 'react-router-dom'
import { UserContextProvider } from '../../../context/UserContext'
import React from 'react'
import {PaymentContextProvider} from '../../../context/PaymentContext'

export const PaymentWrapper = () => (
	<UserContextProvider>
		<PaymentContextProvider>
            <Outlet/>
		</PaymentContextProvider>
	</UserContextProvider>
)