import {createContext, ReactNode, useContext, useMemo, useState} from 'react'


interface FeedbackContextProviderProps {
	children: ReactNode
}

interface FeedbackContextValue {
	feedbackTitle: string
	setFeedbackTitle: (feedbackMessage: string) => void
	feedbackMessage: string
	setFeedbackMessage: (feedbackMessage: string) => void
	feedbackMessageType: 'error' | 'success' | 'info' | 'warning'
	setFeedbackMessageType: (feedbackMessageType: 'error' | 'success' | 'info' | 'warning') => void
	displayFeedback: boolean
	setDisplayFeedback: (displayFeedback: boolean) => void,
	showFeedback: (title: string, message: string, type: 'error' | 'success' | 'info' | 'warning', closeDelay?: number) => void
}

const DEFAULT_FEEDBACK_CONTEXT: FeedbackContextValue = {
	feedbackTitle: '',
	setFeedbackTitle: (feedbackTitle: string) => {},
	feedbackMessage: '',
	setFeedbackMessage: (feedbackMessage: string) => {},
	feedbackMessageType: 'success',
	setFeedbackMessageType: (feedbackMessageType: 'error' | 'success' | 'info' | 'warning') => {},
	displayFeedback: false,
	setDisplayFeedback: (displayFeedback: boolean) => {},
	showFeedback: (title: string, message: string, type: 'error' | 'success' | 'info' | 'warning', closeDelay?: number) => {}
}

export const FeedbackContext = createContext<FeedbackContextValue>(DEFAULT_FEEDBACK_CONTEXT)

export const useFeedbackContext = () => useContext(FeedbackContext)

export const FeedbackContextProvider = ({children}: FeedbackContextProviderProps)=> {
	const [feedbackTitle, setFeedbackTitle] = useState<string>('')
	const [feedbackMessage, setFeedbackMessage] = useState<string>('')
	const [feedbackMessageType, setFeedbackMessageType] = useState<'error' | 'success' | 'info' | 'warning'>('success')
	const [displayFeedback, setDisplayFeedback] = useState<boolean>(false)

	const showFeedback = useMemo(() => (title: string, message: string, type: 'error' | 'success' | 'info' | 'warning', closeDelay: number = 0) => {
		setFeedbackTitle(title)
		setFeedbackMessage(message)
		setFeedbackMessageType(type)
		setDisplayFeedback(true)

		if(closeDelay > 0) setTimeout( () => setDisplayFeedback(false), closeDelay * 1000)
	}, [setFeedbackMessage, setFeedbackMessageType , setDisplayFeedback])

	const value: FeedbackContextValue = useMemo(() => ({
		feedbackTitle, setFeedbackTitle, feedbackMessage, setFeedbackMessage, feedbackMessageType, setFeedbackMessageType, displayFeedback, setDisplayFeedback, showFeedback
	}), [feedbackTitle, setFeedbackTitle, feedbackMessage, setFeedbackMessage, feedbackMessageType, setFeedbackMessageType, displayFeedback, setDisplayFeedback, showFeedback])

	return <FeedbackContext.Provider value={value}>
		{children}
	</FeedbackContext.Provider>
}