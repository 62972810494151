import { useUser } from '@clerk/clerk-react'
import { useAccountCreationContext } from '../../context/AccountCreationContext'
import { useState } from 'react'
import { Button, Grid, TextField, Typography } from '@mui/material'
import { AccountDomain } from '../admin/configuration/settings/AccountDomain'
import { areCompanyEmailsValid, getCompanyEmail, isCompanyEmailValid } from '../../utils/accountCreationHelpers'
import { TrackActionEvent } from '../../service/SegmentService'

export const AccountCreationFormCompanyEmails = () => {
	const {form, error, changeFormValue, changeFormValueValid} = useAccountCreationContext()
	const {user} = useUser()
	const {companyEmails, companyName} = form

	const [companyEmailValidError, setCompanyEmailValidError] = useState(false)
	const [validError, setValidError] = useState('')
	const [newCompanyEmail, setNewCompanyEmail] = useState('')

	const showError = companyEmailValidError || !!error.companyEmails
	const helperText = showError ? (validError || error.companyEmails || 'Email has to follow format name@company.com') : ''

	const handleAddCompanyEmail = () => {
        const companyEmail = getCompanyEmail(newCompanyEmail)
		if (!isCompanyEmailValid(companyEmail)) {
			setCompanyEmailValidError(true)
			setValidError('Your input doesn\'t match an email format. Try a format like name@yourcompany.com')
			return
		}

		if (companyEmails.includes(companyEmail)) {
			setCompanyEmailValidError(true)
			setValidError('That email already exists. Try adding a different one')
			return
		}

		setCompanyEmailValidError(false)
		const updatedEmails = [...companyEmails, companyEmail]
		changeFormValue('companyEmails', updatedEmails)
		changeFormValueValid('companyEmails', areCompanyEmailsValid(updatedEmails))
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_emails: updatedEmails,
			action: 'add_domain_email'
		})
		setNewCompanyEmail('')
	}

	const handleDeleteEmailClicked = (email: string) => {
		const updatedCompanyEmails = companyEmails.filter(companyEmail => companyEmail !== email)
		changeFormValue('companyEmails', updatedCompanyEmails)
		changeFormValueValid('companyEmails', areCompanyEmailsValid(updatedCompanyEmails))
		TrackActionEvent('Create Organization', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_emails: updatedCompanyEmails,
			action: 'remove_domain_email'
		})
	}

	return (
		<>
			<Typography variant='h6' className='AccountCreationForm_ControlSubheader'>
				Add by email
			</Typography>
			<Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
				Add users by entering their email addresses. You can include several users at the same time separated by commas.
			</Typography>
			<TextField variant='outlined' 
                className='newUserInput' 
                label='User email'
                placeholder='name@yourcompany.com'
                fullWidth 
                value={newCompanyEmail}
                error={showError}
                helperText={helperText}
                onChange={(event) => setNewCompanyEmail(event.target.value)} 
                InputProps={{endAdornment: <Button color='primary' className='addUserButton' onClick={handleAddCompanyEmail}>Add user</Button>}}/>
			{companyEmails.length ?
				<Grid container className='sectionContainer'>
					{companyEmails.map((email, index) =>
						<AccountDomain key={`allowed-email${index}`}
                            domain={email}
                            deleteEventName='domainEmailDeletion' 
                            isEditAllowed={false}
                            showConfirmDelete={false}
                            deleteDisabled={false}
                            handleDeleteClicked={handleDeleteEmailClicked}/>) }
				</Grid> : <></>}
		</>
	)
}