import {Box, Divider, IconButton, List, Typography} from '@mui/material'
import KeyBoardTabIcon from '@mui/icons-material/KeyboardTab'
import {useAppMenuContext} from '../../context/AppMenuContext'
import {ReactComponent as NarusLogoIcon} from '../../images/narus-logo.svg'
import {useUserInfoContext} from '../../context/UserInfoContext'
import {AppMenuMainItem} from './AppMenuMainItem'
import {ADMIN_CONFIG_ITEMS, ADMIN_ITEMS, HOME_ITEMS, USER_ITEMS} from '../../constants/AppMenuConstants'
import {AppMenuItem} from '../../types/AppMenu'
import {AppMenuProfileButton} from './AppMenuProfileButton'
import './AppMenuMainItems.scss'

export const AppMenuMainItems = () => {
    const { isOpen, toggle } = useAppMenuContext()
    const { userInfo } = useUserInfoContext()

    const isAdmin = userInfo?.isAdmin
    const menuSuffix = isOpen ? 'open' : 'closed'

    const renderListItems = (items: AppMenuItem[]) =>
        items.map((item, index) => <AppMenuMainItem key={`${item.title}${index}`} item={item}/>) 

    return (
        <Box className='appMenuMainItemsContainer'>
            <Box  className={`titleContainer ${menuSuffix}`}>
                <NarusLogoIcon className={`titleLogo ${menuSuffix}`}/>
                <IconButton className={`titleToggle ${menuSuffix}`} onClick={toggle}>
                    <KeyBoardTabIcon/>
                </IconButton>
            </Box>
            { isAdmin ? <>
                <List disablePadding>
                    { renderListItems(ADMIN_ITEMS) }
                </List>
                <Divider/>
                <Typography className={`listHeader ${menuSuffix}`}>Admin</Typography>
                <List disablePadding>
                { renderListItems(ADMIN_CONFIG_ITEMS) }
                </List>
                <Divider/>
                <Typography className={`listHeader ${menuSuffix}`}>Knowledge Worker</Typography>
            </> : <></> }
            <List disablePadding>
                { renderListItems(HOME_ITEMS) }
            </List>
            <Divider/>
            <List disablePadding>
                { renderListItems(USER_ITEMS) }
            </List>
            <Box className='footerContainer'>
                <Divider/>
                <List disablePadding>
                    <AppMenuProfileButton/>
                </List>
            </Box>
        </Box>
    )
}