import {ChangeEvent, FC, useCallback, useState} from 'react'
import {Box, Button, Grid, TextField, Typography} from '@mui/material'
import {useAiModelsContext} from '../../context/AIModelsContext'
import {getAiModelsList, getAiModelsListForEnterpriseCreation} from '../../helpers/AiModelHelper'
import CreatableSelect from 'react-select/creatable'
import {SingleValue} from 'react-select'
import {SelectOption} from '../../types/SelectOption'
import {AIModel, AIModelID, AIModels, OPEN_SOURCE_MODELS} from '../../types/AiModel'
import {AiModelIcon} from '../icons/AiModelIcon'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import Switch from '@mui/material/Switch'
import './AccountCreationFormModels.scss'
import {TrackActionEvent} from '../../service/SegmentService'
import {useUser} from '@clerk/clerk-react'
import {useAccountCreationContext} from '../../context/AccountCreationContext'

export const AccountCreationFormModels: FC = () => {
	const {aiModels} = useAiModelsContext()
	const {form: {companyName}} = useAccountCreationContext()
	const {user} = useUser()
	const [newModelVisible, setNewModelVisible] = useState(false)
	const [editModelVisible, setEditModelVisible] = useState(false)
	const [editModelTarget, setEditModelTarget] = useState<AIModel>()

	const availableModels = getAiModelsList(aiModels)
	const defaultModels = aiModels.filter(aiModel => [AIModelID.LLAMA_3_2, AIModelID.MISTRAL].includes(aiModel.id))
	const creatableModels = aiModels.filter(aiModel => ![AIModelID.LLAMA_3_2, AIModelID.MISTRAL].includes(aiModel.id))
	const buttonAddNewModelVisible = !!availableModels.length && !newModelVisible && !editModelVisible
	const modelActionsDisabled = editModelVisible || newModelVisible

	const handleAddModelClicked = useCallback(() => {
		setNewModelVisible(true)
		TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
			company_name: companyName,
			company_ai_models: availableModels.map(({value}) => value),
			action: 'add_enterprise_ai'
		})
	}, [user, companyName, availableModels])

	const handleModelEdit = (model: AIModel) => {
		setEditModelVisible(true)
		setEditModelTarget(model)
	}

	const handleNewModelSubmitted = () => {
		setNewModelVisible(false)
	}

	const handleEditModelSubmitted = () => {
		setEditModelVisible(false)
	}

	return <Box className='AccountCreationForm_Control'>
		<Typography variant='h4' className='AccountCreationForm_ControlHeader'>
			Enterprise and private AI
		</Typography>
		<Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>
			Connect your business AI or custom AI to the API for your account. You can add the models later
		</Typography>
		{creatableModels.length ? <Box className='AccountCreationForm_ModelsContainer'>
			{creatableModels.map(model => <AccountCreationFormModel key={model.id} model={model} disabled={modelActionsDisabled} onEdit={handleModelEdit}
			                                                        actionsDisabled={false}/>)}
		</Box> : <></>}
		{editModelVisible && editModelTarget ? <AccountCreationFormEditModel model={editModelTarget} onSubmit={handleEditModelSubmitted}/> : <></>}
		{newModelVisible ? <AccountCreationFormNewModel onSubmit={handleNewModelSubmitted}/> : <></>}
		{buttonAddNewModelVisible ? <Box>
			<Button onClick={handleAddModelClicked}>+ Add enterprise AI</Button>
		</Box> : <></>}

		<Typography variant='h4' className='AccountCreationForm_ControlHeader'>Included GenAI Models</Typography>
		<Typography variant='subtitle2' className='AccountCreationForm_ControlSubtitle'>Narus includes two secured GenAI Models you can enable or disable if you want
			to.</Typography>
		{defaultModels.map(model =>
			<AccountCreationFormModel key={model.id} model={model} disabled={false} onEdit={handleModelEdit} actionsDisabled={true}/>)
		}
	</Box>
}

const AccountCreationFormModel: FC<{ model: AIModel, disabled: boolean, onEdit: (model: AIModel) => void, actionsDisabled: boolean }> = ({
	                                                                                                                                         model,
	                                                                                                                                         disabled,
	                                                                                                                                         onEdit,
	                                                                                                                                         actionsDisabled
                                                                                                                                         }) => {
	const {deleteAiModel, updateAiModelEnablement} = useAiModelsContext()
	const {form: {companyName}} = useAccountCreationContext()
	const {user} = useUser()
	const {id, name, isEnabled} = model
	const isDisabledClassName = disabled ? ' --disabled' : ''

	const handleEditIconClicked = () => {
		if (!disabled) {
			onEdit(model)
		}
	}

	const handleDeleteIconClicked = useCallback(() => {
		if (!disabled) {
			deleteAiModel(id).then(() => {
				TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
					company_name: companyName,
					company_model_id: id,
					company_model_name: name,
					action: 'delete_model'
				})
			})
		}
	}, [user?.id, user?.externalId, companyName, id, name, deleteAiModel, disabled])

	const handleEnableSwitchClicked = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		if (!disabled) {
			updateAiModelEnablement(event.target.checked, model).then(() => {
				TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
					company_name: companyName,
					company_model_id: id,
					company_model_name: name,
					action: event.target.checked ? 'enable_model' : 'disable_model'
				})
			})
		}
	}, [user?.id, user?.externalId, companyName, disabled, id, name, updateAiModelEnablement, model])


	return <Box className='AccountCreationForm_ModelsItem'>
		<Box className='AccountCreationForm_ModelsName'>
			<AiModelIcon modelId={id}/>
			<Typography>{name}</Typography>
		</Box>
		<Box className='AccountCreationForm_ModelsActions'>
			{actionsDisabled ? <></> : <>
				<SettingsIcon className={`AccountCreationForm_ModelsIcon${isDisabledClassName}`} onClick={handleEditIconClicked}/>
				<DeleteIcon className={`AccountCreationForm_ModelsIcon${isDisabledClassName}`} onClick={handleDeleteIconClicked}/>
			</>}
			<Switch className={`AccountCreationForm_ModelsSwitch ${actionsDisabled ? '' : 'multipleActions'}`} disabled={disabled} checked={isEnabled}
			        onChange={handleEnableSwitchClicked}/>
		</Box>
	</Box>
}

const AccountCreationFormEditModel: FC<{ model: AIModel, onSubmit: () => void }> = ({
	                                                                                    model,
	                                                                                    onSubmit
                                                                                    }) => {
	const [name, setName] = useState(model.name)
	const [apiKey, setApiKey] = useState('')
	const [inProgress, setInProgress] = useState(false)

	const {updateAiModelConfig} = useAiModelsContext()

	const buttonCancelDisabled = inProgress
	const buttonSubmitDisabled = !name || inProgress

	const handleModelNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
	}

	const handleModelApiKeyChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setApiKey(event.target.value)
	}

	const handleSubmit = () => {
		if (!buttonSubmitDisabled) {
			setInProgress(true)
			updateAiModelConfig(apiKey, name, true, model.id)
				.finally(() => {
					onSubmit()
				})
		}
	}

	const handleCancel = () => {
		if (!buttonCancelDisabled) {
			onSubmit()
		}
	}

	return <>
		<Grid container spacing='10px'>
			<Grid item xs={4}>
				<CreatableSelect required className='AccountCreationForm_NewModelCreatable' isDisabled={true} placeholder='Select AI Model'
				                 value={{label: model.id, value: model.id}}/>
			</Grid>
			<Grid item xs={4}>
				<TextField required fullWidth label='Name' variant='outlined' placeholder='Name' value={name} onChange={handleModelNameChanged}/>
			</Grid>
			<Grid item xs={4}>
				<TextField required fullWidth label='API KEY' variant='outlined' placeholder='Api Key' value={apiKey} onChange={handleModelApiKeyChanged}/>
			</Grid>
		</Grid>
		<Box className='AccountCreationForm_NewModelSubmitWrapper'>
			<Button variant='outlined' disabled={buttonCancelDisabled} onClick={handleCancel}>Cancel</Button>
			<Button variant='contained' disabled={buttonSubmitDisabled} onClick={handleSubmit}>Update</Button>
		</Box>
	</>
}

const AccountCreationFormNewModel: FC<{ onSubmit: () => void }> = ({
	                                                                   onSubmit
                                                                   }) => {
	const [id, setId] = useState<string>()
	const [name, setName] = useState('')
	const [apiKey, setApiKey] = useState('')
	const [inProgress, setInProgress] = useState(false)

	const {aiModels, addAiModel} = useAiModelsContext()
	const {form} = useAccountCreationContext()
	const {user} = useUser()

	const isOpenSourceSelectedModel = id && OPEN_SOURCE_MODELS.map(model => model.id).includes(AIModels[id].id)

	const buttonCancelDisabled = inProgress
	const buttonSubmitDisabled = !(id && name && (apiKey || isOpenSourceSelectedModel)) || inProgress
	const buttonText = inProgress ? 'In progress' : 'Connect IA'

	const availableModels = getAiModelsListForEnterpriseCreation(aiModels)

	const handleModelIdChanged = (event: SingleValue<SelectOption>) => {
		if (event) {
			setId(event.value)
		}
	}

	const handleModelNameChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setName(event.target.value)
	}

	const handleModelApiKeyChanged = (event: ChangeEvent<HTMLInputElement>) => {
		setApiKey(event.target.value)
	}

	const handleSubmit = () => {
		if (!buttonSubmitDisabled) {
			setInProgress(true)
			addAiModel(id, apiKey, name)
				.then(() => {
					TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
						company_name: form.companyName,
						company_model_id: id,
						company_model_name: name,
						action: 'add_model'
					})
				})
				.finally(() => {
					setInProgress(false)
					onSubmit()
				})
		}
	}

	const handleCancel = () => {
		if (!buttonCancelDisabled) {
			TrackActionEvent('AI Models', user?.externalId ?? user?.id, {
				company_name: form.companyName,
				company_ai_models: availableModels.map(({value}) => value),
				action: 'cancel'
			})
			onSubmit()
		}
	}

	return <>
		<Grid container spacing='10px'>
			<Grid item xs={4}>
				<CreatableSelect required className='AccountCreationForm_NewModelCreatable' placeholder='Select AI Model' options={availableModels}
				                 onChange={handleModelIdChanged}/>
			</Grid>
			<Grid item xs={isOpenSourceSelectedModel ? 8 : 4}>
				<TextField required fullWidth label='Name' variant='outlined' placeholder='Name' value={name} onChange={handleModelNameChanged}/>
			</Grid>
			{!isOpenSourceSelectedModel && <Grid item xs={4}>
				<TextField required fullWidth label='API KEY' variant='outlined' placeholder='Api Key' value={apiKey} onChange={handleModelApiKeyChanged}/>
			</Grid>}
		</Grid>
		<Box className='AccountCreationForm_NewModelSubmitWrapper'>
			<Button variant='outlined' disabled={buttonCancelDisabled} onClick={handleCancel}>Cancel</Button>
			<Button variant='contained' disabled={buttonSubmitDisabled} onClick={handleSubmit}>{buttonText}</Button>
		</Box>
	</>
}
