import {Box, Typography} from '@mui/material'
import './TrialBanner.scss'
import {useStripeSubscriptionsContext} from '../../context/StripeSubscriptionsContext'
import {useLocation} from 'react-router-dom'

export const TrialBanner = () => {

    const {isTrial, trialDaysLeft} = useStripeSubscriptionsContext()
    const location = useLocation()

    if (!isTrial) return <></>

    const adminSuffix = location.pathname.startsWith('/admin') ? 'admin' : ''
    const endingSuffix = trialDaysLeft! <= 5 ? 'ending' : ''

    return <Box className={`trialBanner ${endingSuffix} ${adminSuffix}`}>
        <Typography className='text' variant='h6'>You're in a free trial! Days remaining:</Typography>
        <Typography className='daysLeft' variant='h6'>{trialDaysLeft}</Typography>
    </Box>
}