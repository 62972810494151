import {Box, LinearProgress, Typography} from '@mui/material'
import {clamp, roundTwoDecimals} from '../../../helpers/NumberHelpers'
import {Budget} from '../../../types/Budget'
import {formatDateToLocaleDate} from '../../../helpers/DateHelpers'
import './BudgetStatistics.scss'
import { getClosestBudgetPeriodEndDate, getClosestBudgetPeriodStartDate } from '../../../utils/budgetUtils'

type BudgetStatisticsProps = {
	budget: Budget
}

export const BudgetStatistics = ({budget}: BudgetStatisticsProps) => {
	const percentageSpent = budget.spent / budget.amount * 100
	const progressValue = clamp(percentageSpent, 0, 100)
	const startDate = getClosestBudgetPeriodStartDate(budget)
	const endDate = getClosestBudgetPeriodEndDate(budget)

	return (
		<Box className='budgetStatisticsContainer'>
			<Typography className='budgetStatisticsTitle'>Current budget</Typography>
			<Typography className='budgetStatisticsAmount'>${roundTwoDecimals(budget.amount)}</Typography>
			<Typography className='budgetStatisticsPeriod'>Budget duration: {formatDateToLocaleDate(startDate)} - {formatDateToLocaleDate(endDate)}</Typography>
			<Box className='budgetStatisticsRow'>
				<Typography className='budgetStatisticsDescription'>{roundTwoDecimals(percentageSpent)}% (${roundTwoDecimals(budget.spent)})</Typography>
				<Typography className='budgetStatisticsDescription'>${budget.amount}</Typography>
			</Box>
			<LinearProgress variant="determinate" value={progressValue} className={`budgetProgressBar ${percentageSpent > 100 ? 'goneBudget' : percentageSpent > 85 ? 'mostlySpentBudget' : ''}`}/>
		</Box>
	)
}