import {Box, Button, MenuItem, Select, SelectChangeEvent, Typography} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import React, {useState} from 'react'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {FormatWarning, WarningLevel} from '../../../../types/Warning'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {useWarningsContext} from '../../../../context/WarningsContext'
import {useUser} from '@clerk/clerk-react'
import {useFeedbackContext} from '../../../../context/FeedbackContext'

interface EditWordWarningProps {
	formatWarningToEdit: FormatWarning
}

export const EditFormatWarning = ({formatWarningToEdit}: EditWordWarningProps) => {

	const {user} = useUser()
	const {showFeedback} = useFeedbackContext()
	const {toggleDrawer} = useToggleDrawerContext()
	const {editFormatWarning} = useWarningsContext()

	const [updatedWarningLevel, setUpdatedWarningLevel] = useState<WarningLevel>(formatWarningToEdit.level)

	const handleUpdateWordWarningClicked = async (event: React.MouseEvent<HTMLButtonElement>) => {
		const error = await editFormatWarning({...formatWarningToEdit, level: updatedWarningLevel})
		if (error) {
			showFeedback('Error updating the format', 'Something failed updating the warning level for this format', 'error', 5)
			return
		}
		showFeedback('Format updated', '', 'success', 5)
		TrackActionEvent('Warnings', user?.externalId ?? user?.id, {action: 'edit', type: 'format'})
		return toggleDrawer(false, 'EDIT_FORMAT_WARNING_DRAWER')(event)
	}

	return <Box className='editWarningContainer'>
		<Box className='editWarningHeader'>
			<Typography variant='h3'>{formatWarningToEdit.format} configuration</Typography>
			<CloseIcon onClick={toggleDrawer(false, 'EDIT_FORMAT_WARNING_DRAWER')}/>
		</Box>
		<Typography variant='body2'>Use the following options to define this data type.</Typography>
		<Select label="Warning level" onChange={(event: SelectChangeEvent) => setUpdatedWarningLevel(event.target.value as WarningLevel)} value={updatedWarningLevel}
		        className='newWarningTextfield warningLevelSelect edit' fullWidth>
			<MenuItem value='low'>Low</MenuItem>
			<MenuItem value='medium'>Medium</MenuItem>
			<MenuItem value='high'>High</MenuItem>
		</Select>

		<Box className='saveWarningButtonContainer'>
			<Button onClick={handleUpdateWordWarningClicked}>Save</Button>
		</Box>
	</Box>
}