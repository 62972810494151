import {NavigationConfigBase} from '../types/AdminNavigation'

export const ADMIN_NAVIGATION_TAB_MAP: { [key in NavigationConfigBase]: number} = {
    overview: 0,
    logs: 1,
    configuration: 2
}

export const ADMIN_NAVIGATION_BASE_ROUTER_MAP: { [key in NavigationConfigBase]: string } = {
    overview: '',
    logs: '/logs',
    configuration: '/configuration'
}
