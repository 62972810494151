import React from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import './TaskBar.scss'
import {Divider, IconButton, Tooltip} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined'
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined'
import {PromptChatDetail} from '../../types/PromptChatDetail'
import {useToggleDrawerContext} from '../../context/ToggleDrawerContext'

interface CustomTaskBarProps {
    promptChatDetails: PromptChatDetail
    isPromptOwnedByOther: boolean
    onOpenAdvancedSettings: () => void
}

export const CustomTaskBar = ({promptChatDetails, isPromptOwnedByOther, onOpenAdvancedSettings}: CustomTaskBarProps) => {

    const {toggleDrawer} = useToggleDrawerContext()
    const saveButtonTooltipText = isPromptOwnedByOther ? 'You can\'t edit fields in a prompt that you are not the owner of' : ''

    return (
        <AppBar position='static' className='taskBarContainer'>
            <Toolbar className='taskBar'>
                <Tooltip title={saveButtonTooltipText}>
								<span>
                                    <Button startIcon={<BoltOutlinedIcon/>}
                                            disabled={!promptChatDetails.messages.length || isPromptOwnedByOther}
                                            className='taskBarButton'
                                            onClick={toggleDrawer(true, 'SAVE_CHAT_COMPILATION_DRAWER')}>Save summarised prompt</Button>
								</span>
                </Tooltip>
                <Button startIcon={<SaveOutlinedIcon/>} className='taskBarButton'
                            onClick={toggleDrawer(true, 'SAVE_CHAT_HISTORY_DRAWER')} disabled={!promptChatDetails.messages.length}>Save chat</Button>
                <Divider orientation='vertical' flexItem/>
                <IconButton onClick={onOpenAdvancedSettings} disabled={!promptChatDetails.messages.length}
                            className='taskBarButton'><SettingsIcon/></IconButton>
            </Toolbar>
        </AppBar>
    )
}
