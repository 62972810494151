import {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext} from 'react'
import {useSearch} from '../hooks/useSearch'
import {Prompt, PromptVisibility} from '../types/Prompt'
import {AIModelID, DEFAULT_SELECTED_MODELS} from '../types/AiModel'

export interface SearchContextValue {
	searchLoading: boolean,
	setSearchLoading: Dispatch<SetStateAction<boolean>>
	searchText: string
	setSearchText: Dispatch<SetStateAction<string>>
	archivedPromptsChecked: boolean
	currentPromptsChecked: boolean
	setCurrentPromptsChecked: Dispatch<SetStateAction<boolean>>
	setArchivedPromptsChecked: Dispatch<SetStateAction<boolean>>
	searchLabels: string[]
	setSearchLabels: Dispatch<SetStateAction<string[]>>
	searchTeams: string[]
	setSearchTeams: Dispatch<SetStateAction<string[]>>
	searchedPrompts: Prompt[]
	setSearchedPrompts: Dispatch<SetStateAction<Prompt[]>>
	modelsSelected: Record<AIModelID, boolean>
	setModelsSelected: Dispatch<SetStateAction<Record<AIModelID, boolean>>>
	lastEvaluatedKeySearch: string | undefined
	isDefaultSearch: boolean
	searchUserPrompts: (newSearch: boolean) => void
	clearSearch: () => void
	isLongTextSearch: boolean
	setIsLongTextSearch: Dispatch<SetStateAction<boolean>>
	setDefaultSelectedModels: () => void
	searchAIModelIDs: AIModelID[]
	setSearchAIModelIDs: Dispatch<SetStateAction<AIModelID[]>>
	searchVisibility: PromptVisibility[]
	setSearchVisibility: Dispatch<SetStateAction<PromptVisibility[]>>
}

const DEFAULT_SEARCH_CONTEXT: SearchContextValue = {
	searchLoading: false,
	setSearchLoading: () => {},
	searchText: '',
	setSearchText: () => {},
	archivedPromptsChecked: false,
	currentPromptsChecked: true,
	setCurrentPromptsChecked: () => {},
	setArchivedPromptsChecked: () => {},
	searchLabels: [],
	setSearchLabels: () => {},
	modelsSelected: DEFAULT_SELECTED_MODELS,
	setModelsSelected: () => {},
	searchTeams: [],
	setSearchTeams: () => {},
	searchedPrompts: [],
	setSearchedPrompts: () => {},
	lastEvaluatedKeySearch: undefined,
	isDefaultSearch: true,
	searchUserPrompts: () => {},
	clearSearch: () => {},
	isLongTextSearch: false,
	setIsLongTextSearch: () => {},
	setDefaultSelectedModels: () => {},
	searchAIModelIDs: [],
	setSearchAIModelIDs: () => {},
	searchVisibility: [],
	setSearchVisibility: () => {}
}

export const SearchContext = createContext<SearchContextValue>(DEFAULT_SEARCH_CONTEXT)

export const useSearchContext = () => useContext(SearchContext)

export const SearchContextProvider: FC<PropsWithChildren> = ({children}) => {
	const value = useSearch()

	return <SearchContext.Provider value={value}>
		{children}
	</SearchContext.Provider>
}
