import { FC, PropsWithChildren, createContext, useContext } from 'react'
import { DEFAULT_ACCOUNT_CREATION_CONTEXT_VALUE } from '../constants/AccountCreationConstants'
import { useAccountCreation } from '../hooks/useAccountCreation'
import { ContextValue } from '../types/AccountCreation'

const AccountCreationContext = createContext<ContextValue>(DEFAULT_ACCOUNT_CREATION_CONTEXT_VALUE)

export const useAccountCreationContext = () => useContext(AccountCreationContext)

export const AccountCreationContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const value = useAccountCreation()

    return <AccountCreationContext.Provider value={value}>
        { children }
    </AccountCreationContext.Provider>
}