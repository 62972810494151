import {AccountDomainProps} from '../../../../types/AccountDomainProps'
import {useUser} from '@clerk/clerk-react'
import React, {useState} from 'react'
import {emailMatchesDomain} from '../../../../utils/accountCreationHelpers'
import {Box, Grid, IconButton, Tooltip} from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings'
import DeleteIcon from '@mui/icons-material/Delete'
import ConfirmationDialog from '../../../promptForm/confirmationDialog/ConfirmationDialog'

export const AccountDomain = ({domain, deleteDisabled, isEditAllowed, handleDeleteClicked, handleEditClicked, deleteEventName, showConfirmDelete}: AccountDomainProps) => {

	const {user} = useUser()
	const [showDeleteDomainDialog, setShowDeleteDomainDialog] = useState<boolean>(false)

	const disabledCurrentDomainAction = user?.emailAddresses.length ? emailMatchesDomain(user?.emailAddresses[0].emailAddress)(domain) : false


	const handleDelete = () => {
		setShowDeleteDomainDialog(false)
		handleDeleteClicked(domain)
	}

	return <Grid item xs={12}>
		<Box className='accountDomainRow'>
			<Box className='accountDomainName'>{domain}</Box>
			{isEditAllowed && handleEditClicked? <Tooltip title={disabledCurrentDomainAction ? 'You can\'t edit the domain you belong to' : ''}>
				<span>
					<IconButton
						onClick={(event) => handleEditClicked(event, domain)}
						onMouseDown={(event) => handleEditClicked(event, domain)}
						disabled={disabledCurrentDomainAction}
						edge='end'
						className='domainActionIcon'
					>
						<SettingsIcon/>
					</IconButton>
				</span>
			</Tooltip> : ''}
			<Tooltip title={disabledCurrentDomainAction ? 'You can\'t delete the domain you belong to' : ''}>
				<span>
					<IconButton
						onClick={() => showConfirmDelete ? setShowDeleteDomainDialog(true) : handleDelete()}
						onMouseDown={() => showConfirmDelete ? setShowDeleteDomainDialog(true) : handleDelete()}
						edge='end'
						disabled={disabledCurrentDomainAction || deleteDisabled}
						className={`domainActionIcon ${deleteDisabled ? 'disabled' : ''}`}
					>
						<DeleteIcon/>
					</IconButton>
				</span>
			</Tooltip>
			<ConfirmationDialog handleClose={() => setShowDeleteDomainDialog(false)} open={showDeleteDomainDialog}
			                    handleDelete={handleDelete}
			                    currentEvent={deleteEventName}/>
		</Box>
	</Grid>
}