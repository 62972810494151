import {Outlet} from 'react-router'
import {CommonProviders} from './providers/CommonProviders'
import {AdminProviders} from './providers/AdminProviders'
import {UserProviders} from './providers/UserProviders'
import {CommonConsumers} from './providers/CommonConsumers'

export const AppPrivateWrapper = () => (
    <CommonProviders>
        <AdminProviders>
            <UserProviders>
                <CommonConsumers>
                    <Outlet/>
                </CommonConsumers>
            </UserProviders>
        </AdminProviders>
    </CommonProviders>
)
