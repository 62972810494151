import {Grid, Tab, Tabs, Typography} from '@mui/material'
import React, {useState} from 'react'
import './GlobalSettings.scss'
import {useUser} from '@clerk/clerk-react'
import {Admins} from './Admins'
import {UserAccess} from './UserAccess'
import {CustomTabPanel} from '../../../homeTabs/HomeTabs'
import {OrganizationDetails} from './OrganizationDetails'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {LicenseSettings} from './LicenseSettings'

export const GlobalSettings = () => {

	const {user} = useUser()

	const [selectedTab, setSelectedTab] = useState(0)

	const onTabChange = (_, newValue) => {
		const tabNames = ['workspace', 'userAccess', 'licenseBilling']
		TrackActionEvent('Organization settings', user?.externalId ?? user?.id, {
			action: 'changeTab',
			tab: tabNames[newValue]
		})
		setSelectedTab(newValue)
	}

	return <Grid container className='globalSettings'>
		<Grid item xs={12}>
			<Typography variant='h3' className='title'>Global settings</Typography>
			<Tabs value={selectedTab} className='settingsTabContainer'
			      onChange={onTabChange}>
				<Tab label='WORKSPACE'/>
				<Tab label='USER ACCESS'/>
				<Tab label='LICENSE & BILLING'/>
			</Tabs>
			<CustomTabPanel value={selectedTab} index={0}>
				<OrganizationDetails/>
				<Admins/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTab} index={1}>
				<UserAccess/>
			</CustomTabPanel>
			<CustomTabPanel value={selectedTab} index={2}>
				<LicenseSettings/>
			</CustomTabPanel>
		</Grid>
	</Grid>
}