import {Box, Button, FormControl, FormControlLabel, InputAdornment, Radio, RadioGroup, TextField, Typography} from '@mui/material'
import {DatePicker} from 'rsuite'
import EventIcon from '@mui/icons-material/Event'
import React, {ChangeEvent, useState} from 'react'
import {TrackActionEvent} from '../../../../service/SegmentService'
import {Budget} from '../../../../types/Budget'
import {useUser} from '@clerk/clerk-react'
import {useBudgetsContext} from '../../../../context/BudgetContext'
import {useToggleDrawerContext} from '../../../../context/ToggleDrawerContext'
import {endOfDay} from 'rsuite/cjs/utils/dateUtils'

export const BudgetForm = () => {
	const {toggleDrawer} = useToggleDrawerContext()
	const {user} = useUser()
	const {saveBudget} = useBudgetsContext()

	const [budgetNeverEnds, setBudgetNeverEnds] = useState<boolean>(true)
	const [startDate, setStartDate] = useState<Date>()
	const [endDate, setEndDate] = useState<Date>()
	const [amount, setAmount] = useState<string>()
	const [periodicity, setPeriodicity] = useState<'Monthly' | 'Quarterly'>('Monthly')
	const [endDateValidationError, setEndDateValidationError] = useState<boolean>(false)
	const [startDateValidationError, setStartDateValidationError] = useState<boolean>(false)
	const [amountValidationError, setAmountValidationError] = useState<boolean>(false)

	const onOpenCalendarHandler = () => {
		TrackActionEvent('Budget calendar', user?.id, {action: 'open'})
	}

	const onCleanHandler = () => {
		TrackActionEvent('Budget calendar', user?.id, {action: 'clean'})
	}

	const onStartDateHandler = (date: Date | null) => {
		TrackActionEvent('Budget', user?.id, {action: 'set_start_date'})
		setStartDateValidationError(false)
		setStartDate(date ?? undefined)
		setEndDate(undefined)
	}

	const onEndDateHandler = (date: Date | null) => {
		TrackActionEvent('Budget', user?.id, {action: 'set_end_date'})
		if (date) setEndDate(date)
	}

	const onEndDateOptionHandler = (_, value: string) => {
		const isBudgetNeverEnds = value === 'never'
		if (isBudgetNeverEnds) setEndDate(undefined)
		setBudgetNeverEnds(isBudgetNeverEnds)
	}

	const onSaveBudgetHandler = async (event: React.MouseEvent) => {
		const invalidEndDate = (!endDate && !budgetNeverEnds)
		if (!startDate) setStartDateValidationError(true)
		if (invalidEndDate) setEndDateValidationError(true)
		const parsedAmount = parseFloat(amount ?? '')
		const isAmountInvalid = isNaN(parsedAmount) || parsedAmount <= 0
		if (isAmountInvalid) setAmountValidationError(true)
		if (!startDate || invalidEndDate || isAmountInvalid) return

		const budget: Partial<Budget> = {
			periodicity,
			amount: parsedAmount,
			startDate: startDate?.getTime(),
			periodStartDate: startDate?.getTime(),
			endDate: endDate?.getTime() ?? new Date('9999-12-31').getTime()
		}
		const createdBudget = await saveBudget(budget)
		if (createdBudget) {
			TrackActionEvent('Budget', user?.id, {action: 'save', periodicity})
			toggleDrawer(false, 'EDIT_OVERALL_BUDGET')(event)
		}
	}

	const onPeriodicityHandler = (_, value: string) =>
		setPeriodicity(value === 'monthly' ? 'Monthly' : 'Quarterly')

	const onAmountHandler = (event: ChangeEvent<HTMLInputElement>) => {
		setAmountValidationError(false)
		setAmount(event.target.value)
	}


	return <>
		<Box>
			<Typography>Set a spending limit for the organization. You can split this between teams.</Typography>
		</Box>
		<Box className='recurrenceContainer'>
			<FormControl>
				<Typography>Budget recurrence</Typography>
				<RadioGroup defaultValue="monthly" onChange={onPeriodicityHandler}>
					<FormControlLabel value="monthly" control={<Radio/>} label="Monthly"/>
					<FormControlLabel value="quarterly" control={<Radio/>} label="Quarterly"/>
				</RadioGroup>
			</FormControl>
		</Box>
		<Box className='dateRangePickerContainer'>
			<Typography>Start</Typography>
			<DatePicker
				oneTap
				className='budgetDatePicker'
				placeholder="YYYY/MM/DD"
				caretAs={EventIcon}
				format='yyyy/MM/dd'
				shouldDisableDate={(date: Date) => date < new Date()}
				onChange={onStartDateHandler}
				onOpen={onOpenCalendarHandler}
				onClean={onCleanHandler}
				placement='bottomEnd'
			/>
			{startDateValidationError ? <Typography className='errorHint'>Start date must be a valid date after today</Typography>: <></>}
		</Box>
		<Box className='dateRangePickerContainer'>
			<Typography>End</Typography>
			<FormControl>
				<RadioGroup defaultValue="never" name="radio-buttons-group" onChange={onEndDateOptionHandler}>
					<FormControlLabel value="never" control={<Radio/>} label="Never"/>
					<FormControlLabel value="on" control={<Radio/>} label="Select end date"/>
				</RadioGroup>
			</FormControl>
			{budgetNeverEnds ? <></> :
				<>
				<DatePicker
					oneTap
					className='budgetDatePicker'
					value={endDate}
					placeholder="YYYY/MM/DD"
					caretAs={EventIcon}
					disabled={!startDate}
					format='yyyy/MM/dd'
					shouldDisableDate={(date: Date) => date <= (endOfDay(startDate || new Date()))}
					onChange={onEndDateHandler}
					onOpen={onOpenCalendarHandler}
					onClean={onCleanHandler}
					placement='topEnd'
				/>
					{endDateValidationError ? <Typography className='errorHint'>End date must be a valid date after start date</Typography>: <></>}
				</>
			}
		</Box>
		<Box>
			<TextField label="Amount" required fullWidth
			           InputProps={{startAdornment: <InputAdornment position='start'>$</InputAdornment>}}
			           onChange={onAmountHandler}
			           error={amountValidationError}
			           helperText={amountValidationError ? 'Amount must be a number greater than 0' : ''}
			/>
		</Box>
		<Box className='buttonsContainer'>
			<Button variant='outlined' onClick={toggleDrawer(false, 'EDIT_OVERALL_BUDGET')}>Cancel</Button>
			<Button variant='contained' onClick={onSaveBudgetHandler}>Save</Button>
		</Box>
	</>
}