import React from 'react'
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material'
import './ConfirmationDialog.scss'
import CloseIcon from '@mui/icons-material/Close'
import {ConfirmationDialogProps} from '../../../types/ConfirmationDialogProps'


const ConfirmationDialog = ({
                                handleClose,
                                open,
                                handleDelete,
                                handleConfirmAction,
                                currentEvent,
                                children
                            }: ConfirmationDialogProps) => {

    const dialogsMap = {
        promptDeletion: {
            title: 'Are you sure?',
            text: 'Deleting the prompt will permanently remove it.',
            secondaryAction: handleClose,
            secondaryText: 'CANCEL',
            primaryAction: handleDelete,
            primaryText: 'DELETE',
        },
        aiModelDeletion: {
            title: 'Are you sure you want to continue?',
            text: 'Warning: This action will remove this AI model permanently.',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Remove AI model'
        },
        executeSavedPrompt: {
            title: 'Send prompt',
            text: 'Please select the AI models to which you want to send the prompt.',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleConfirmAction,
            primaryText: 'Send prompt'
        },
        chatDeletion: {
            title: 'This action removes the chat permanently',
            text: 'If you remove this chat permanently you will not be able to use it again.',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Remove chat',
        },
        domainDeletion: {
            title: 'Are you sure you want to remove this domain?',
            text: 'This action will remove the domain permanently and you will block access of the users in it.',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Remove',
        },
        domainEmailDeletion: {
            title: 'Are you sure you want to remove this user?',
            text: 'This action will remove the user permanently and their access to Narus will be blocked.',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Remove',
        },
        adminEmailDeletion: {
            title: 'Are you sure you want to remove this admin?',
            text: 'This action will remove the admin permanently.',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Remove',
        },
        topicWarningDeletion: {
            title: 'Are you sure you want to delete this warning topic?',
            text: 'If you remove this topic, it will stop showing in the warning monitoring section of Narus',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Delete',
        },
        wordWarningDeletion: {
            title: 'Are you sure you want to delete this warning word?',
            text: 'If you remove this word, it will stop showing in the warning monitoring section of Narus',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Delete',
        },
        overallBudgetDeletion: {
            title: 'Are you sure you want to remove the overall budget?',
            text: 'This will reset the budget to unlimited.',
            secondaryAction: handleClose,
            secondaryText: 'Cancel',
            primaryAction: handleDelete,
            primaryText: 'Remove overall budget',
        },
        unableSendPromptBudgetWarning: {
            title: 'We were unable to send your prompt',
            text: 'Prompts cannot be sent as your budget has been used up. Contact your admin to continue.',
            secondaryAction: undefined,
            secondaryText: '',
            primaryAction: handleClose,
            primaryText: 'Close',
        }
    }

    const {title, text, secondaryAction, primaryAction, primaryText, secondaryText} = dialogsMap[currentEvent]
    return <Dialog onClose={handleClose} open={open} className='dialogContainer'>
        <DialogTitle className='dialogTitle'>
            <span>{title}</span>
            <CloseIcon className='dialogCloseIcon' onClick={handleClose}/>
        </DialogTitle>
        <DialogContent className='dialogContent'>
            <DialogContentText>{text}</DialogContentText>
            {children}
        </DialogContent>
        <DialogActions className='dialogActionsContainer'>
            {secondaryAction ? <Button
                variant='outlined'
                className={['promptDeletion', 'aiModelDeletion'].includes(currentEvent) ? 'deleteButton' : ''}
                onClick={secondaryAction}>{secondaryText}</Button> : <></>}
            <Button variant='contained'
                    onClick={primaryAction}>{primaryText}</Button>
        </DialogActions>
    </Dialog>
}

export default ConfirmationDialog