import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import './HoverMenuOutput.scss'
import RetryIcon from '@atlaskit/icon/glyph/retry'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import {Grid, IconButton} from '@mui/material'
import {HoverMenuOutputProps} from '../../types/HoverMenuOutputProps'

export const HoverMenuOutput = ({onRegenerateOutput, regeneratedOutputs, onCopyOutput, isLastMessage, isBot}: HoverMenuOutputProps) => {

	return <Grid>
		<Tooltip title='Copy' placement='top' className='hoverMenuTooltip'>
			<IconButton onClick={onCopyOutput} className='copyIcon'><CopyIcon label='copy output'/></IconButton>
		</Tooltip>
		{isLastMessage && isBot ? <Tooltip title='Regenerate' placement='top' className='hoverMenuTooltip'>
                    <span>
                    <IconButton disabled={regeneratedOutputs ? regeneratedOutputs.length >= 4 : false}
                                onClick={onRegenerateOutput}><RetryIcon label='regenerate output'/></IconButton>
                </span>
		</Tooltip> : <></>}
	</Grid>
}