import {AuditWarning} from '../../cdk/src/types/AuditPromptInfoStored'
import {AUDIT_WARNING_PRIORITY_MAP} from '../constants/AuditConstants'
import {AuditLogWarningLevel, UserAuditLog, UserPromptAudit} from '../types/UserAuditLog'
import {WarningElement} from '../types/Warning'
import {isEqual} from './genericUtils'

export const hasViewPending = ({ isViewed, warnings }: UserAuditLog): boolean => !isViewed && !!warnings.length

export const isAuditLogChanged = (a: UserAuditLog) => (b: UserAuditLog): boolean =>
    a.promptId === b.promptId && a.aiModelId === b.aiModelId && !isEqual(a, b)

export const isAuditLogWarningLevelHigher = (a: AuditLogWarningLevel, b: AuditLogWarningLevel): boolean =>
    AUDIT_WARNING_PRIORITY_MAP[b] > AUDIT_WARNING_PRIORITY_MAP[a]

export const auditLogWarningGridValueGetter = (_: never, auditLog: UserAuditLog | UserPromptAudit): AuditLogWarningLevel =>
	getAuditLogHighestWarningLevel(auditLog)

export const getAuditLogHighestWarningLevel = (auditLog: UserAuditLog | UserPromptAudit): AuditLogWarningLevel =>
    auditLog.warnings.reduce<AuditLogWarningLevel>((prev, cur) => isAuditLogWarningLevelHigher(prev, cur.level) ? cur.level : prev, 'none')

export const auditWarningComparator = (a: AuditWarning, b: AuditWarning): number =>
    a.level === b.level ? 0 :
    isAuditLogWarningLevelHigher(a.level, b.level) ? 1 : -1

export const getAuditWarningTrailDescription = ({ name, type }: AuditWarning): string =>
    type === 'word' ? `The user sent a prompt that contains the word: ${name}` :
    type === 'topic' ? `The user sent a prompt with content that appears to be in the topic: ${name}` :
    type === 'format' ? `The user sent a prompt that appears to contain data in the format: ${name}` : 'No specific reason'

export const userPromptAuditDateComparator = (a: UserPromptAudit, b: UserPromptAudit): number =>
    a.date - b.date

export const isAuditWarningContainedBy = <T extends WarningElement>(array: T[]) => (auditWarning: AuditWarning): boolean =>
    array.some(warning => warning.hashKey === auditWarning.hashKey)

export const isAuditWarningEdited = <T extends WarningElement>(array: T[]) => (auditWarning: AuditWarning): boolean =>
    array
        .filter(warning => warning.hashKey === auditWarning.hashKey)
        .some(warning => warning.level !== auditWarning.level)

export const isAuditWarningRemovedOrEdited = <T extends WarningElement>(array: T[]) => (auditWarning: AuditWarning): boolean =>
    isAuditWarningEdited(array)(auditWarning) || !isAuditWarningContainedBy(array)(auditWarning)