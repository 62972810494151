import {Avatar, Divider, MenuItem, MenuList, Tooltip} from '@mui/material'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'
import './GroupsMenuList.scss'
import PersonIcon from '@mui/icons-material/Person'
import {stringAvatar} from '../../../../helpers/AvatarHelpers'
import {useUsersAccessContext} from '../../../../context/UsersAccessContext'
import {useGroupsContext} from '../../../../context/GroupsContext'
import {Group} from '../../../../types/Group'
import {getSortedGroupNames} from '../../../../utils/groupUtils'

interface GroupsMenuListProps {
	groups: Group[]
}

export const GroupsListMenu = ({groups}: GroupsMenuListProps) => {

	const {usersInfo} = useUsersAccessContext()
	const {
		allGroupsMembers,
		selectedGroup,
		setSelectedGroup
	} = useGroupsContext()

	const groupMembersIds: string[] = Array.from(new Set(
			allGroupsMembers
				.filter(groupMember => groupMember.groupMembers.length)
				.flatMap(({groupMembers}) => groupMembers.map(member => member.userId))
		)
	)

	return <MenuList className='groupsListContainer'>
		{ getSortedGroupNames(groups).map((group) => <MenuItem className='groupItem' key={group.hashKey}
				sx={{
					bgcolor: selectedGroup?.hashKey === group.hashKey ? '#f0f6f7' : 'inherit'
				}}
				onClick={() => setSelectedGroup(group)}>
			<Tooltip title={group.name} placement='top'>
				<ListItemIcon className='groupAvatarIconContainer'>
					<Avatar className='groupAvatarIcon' {...stringAvatar(group.name)}/>
				</ListItemIcon>
			</Tooltip>
			<Tooltip title={group.name} placement='top'>
				<ListItemText className='groupText'>{group.name}</ListItemText>
			</Tooltip>
			<Chip className='groupMembersCount'
			      label={allGroupsMembers.find(groupMembers => groupMembers.groupId === group.hashKey)?.groupMembers.length ?? 0}/>
		</MenuItem>) }
		<Divider className='groupDivider'/>
		<MenuItem className='groupItem noGroup'>
			<ListItemIcon className='groupAvatarIconContainer'>
				<PersonIcon fontSize='small'/>
			</ListItemIcon>
			<ListItemText className='groupText'>Unassigned users</ListItemText>
			<Chip className='groupMembersCount' label={usersInfo.length - groupMembersIds.length}/>
		</MenuItem>
	</MenuList>
}