import {createContext, Dispatch, FC, ReactNode, SetStateAction, useCallback, useContext, useEffect, useMemo, useState} from 'react'
import {loadStripe, Stripe} from '@stripe/stripe-js'
import {createSubscription, getPaymentTaxes} from '../service/persistenceService'
import {useUserContext} from './UserContext'
import {CustomerData} from '../types/CustomerData'
import {getPlanPrice} from '../utils/licenseUtils'
import {SubscriptionCreationResult} from '../types/SubscriptionCreationResult'
import {STRIPE_PUBLISHABLE_KEY} from '../constants/StripeConstants'

interface PaymentContextProviderProps {
	children: ReactNode
}

export interface PaymentContextValue {
	planId: string | undefined
	setPlanId: Dispatch<SetStateAction<string | undefined>>
	seats: number
	setSeats: Dispatch<SetStateAction<number>>
	periodSelected: 'annual' | 'monthly'
	setPeriodSelected: Dispatch<SetStateAction<'annual' | 'monthly'>>
	selectedPlan: 'growth' | 'scale' | undefined
	setSelectedPlan: Dispatch<SetStateAction<'growth' | 'scale' | undefined>>
	stripe: Stripe | null
	clientSecret: string | undefined
	taxes?: TaxesInfo
	setTaxes: Dispatch<SetStateAction<TaxesInfo | undefined>>
	customerDetails?: CustomerData
	setCustomerDetails: Dispatch<SetStateAction<CustomerData | undefined>>
	getTaxes: (updatedSeats?: number, updateAmount?: number) => void
	updateSeats: (seats: number) => void
	subscriptionId: string | undefined
	createStripeSubscription: () => Promise<SubscriptionCreationResult | undefined>
	amount: number
	setAmount: Dispatch<SetStateAction<number>>
	taxesLoading: boolean
}

const DEFAULT_PAYMENT_CONTEXT: PaymentContextValue = {
	planId: '',
	setPlanId: () => {},
	seats: 1,
	setSeats: () => {},
	periodSelected: 'annual',
	setPeriodSelected: () => {},
	selectedPlan: 'growth',
	setSelectedPlan: () => {},
	stripe: null,
	clientSecret: undefined,
	taxes: undefined,
	setTaxes: () => {},
	customerDetails: undefined,
	setCustomerDetails: () => {},
	getTaxes: () => {},
	updateSeats: () => {},
	subscriptionId: undefined,
	createStripeSubscription: () => Promise.resolve(undefined),
	amount: 0,
	setAmount: () => {},
	taxesLoading: false
}

export type TaxesInfo = {
	id?: string
	amount_total: number
	currency: string
	tax_amount_exclusive: number
	tax_amount_inclusive: number
	tax_breakdown: TaxBreakdown[]
}

export type TaxBreakdown = {
	amount: number,
	inclusive: boolean,
	tax_rate_details: {
		country: string,
		flat_amount: null,
		percentage_decimal: number,
		rate_type: 'percentage' | 'flat_amount',
		state: null,
		tax_type: string
	},
	taxability_reason: string,
	taxable_amount: number
}

export const PaymentContext = createContext<PaymentContextValue>(DEFAULT_PAYMENT_CONTEXT)

export const usePaymentContext = () => useContext(PaymentContext)

export const PaymentContextProvider: FC<PaymentContextProviderProps> = ({children}) => {

	const {token} = useUserContext()

	const [planId, setPlanId] = useState<string>()
	const [seats, setSeats] = useState<number>(1)
	const [periodSelected, setPeriodSelected] = useState<'annual' | 'monthly'>('annual')
	const [selectedPlan, setSelectedPlan] = useState<'growth' | 'scale' | undefined>(undefined)
	const [stripe, setStripe] = useState<Stripe | null>(null)
	const [clientSecret, setClientSecret] = useState<string>()
	const [taxes, setTaxes] = useState<TaxesInfo>()
	const [customerDetails, setCustomerDetails] = useState<CustomerData>()
	const [subscriptionId, setSubscriptionId] = useState<string>()
	const [amount, setAmount] = useState<number>(0)
	const [taxesLoading, setTaxesLoading] = useState<boolean>(false)

	const createStripeSubscription = useCallback(async () => {
		if (!planId || !seats) return
		const subscription = await createSubscription(token, planId, seats)
		setSubscriptionId(subscription.subscriptionId)
		setClientSecret(subscription.clientSecret)
		return subscription
	}, [token, planId, seats])

	const getTaxes = useCallback((updatedSeats?: number, updatedAmount?: number) => {
		const subscriptionAmount = updatedAmount ?? amount
		if (!customerDetails?.address.country || !customerDetails?.address.postal_code || !planId || !subscriptionAmount) return
		setTaxesLoading(true)
		getPaymentTaxes(token, subscriptionAmount * 100, updatedSeats ?? seats, planId, customerDetails.address)
			.then(taxes => {
				setTaxes(taxes)
				if (!subscriptionId) createStripeSubscription()
			})
			.finally(() => setTaxesLoading(false))
	}, [customerDetails, token, seats, amount, planId, createStripeSubscription, subscriptionId])

	const updateSeats = useCallback((seats: number) => {
		const amount = seats * getPlanPrice(selectedPlan, periodSelected, seats)
		setSeats(seats)
		setAmount(amount)
		getTaxes(seats, amount)
	}, [periodSelected, selectedPlan, getTaxes])

	useEffect(() => {
		if (!STRIPE_PUBLISHABLE_KEY || !planId || !selectedPlan) return
		if (!stripe) loadStripe(STRIPE_PUBLISHABLE_KEY).then(stripe => setStripe(stripe))
	}, [stripe, planId, selectedPlan])

	const value: PaymentContextValue = useMemo(() => ({
			planId, setPlanId,
			seats, setSeats,
			periodSelected, setPeriodSelected,
			selectedPlan, setSelectedPlan,
			stripe,
			clientSecret,
			taxes, setTaxes,
			customerDetails, setCustomerDetails,
			getTaxes,
			updateSeats,
			subscriptionId,
			createStripeSubscription,
			amount,
			setAmount,
			taxesLoading
		}),
		[planId, seats, periodSelected, selectedPlan, stripe, clientSecret, taxes, customerDetails, getTaxes, subscriptionId, createStripeSubscription, updateSeats, taxesLoading, amount])

	return <PaymentContext.Provider value={value}>
		{children}
	</PaymentContext.Provider>
}