import {createContext, Dispatch, FC, PropsWithChildren, SetStateAction, useContext} from 'react'
import {useGroups} from '../hooks/useGroups'
import {Group} from '../types/Group'
import {GroupMember} from '../types/GroupMember'
import {AIModelID} from '../types/AiModel'

export interface GroupsContextValue {
	saveGroup: (groupName: string, usersToAddToGroup: string[]) => Promise<{status: number, groupId: string}>
	loading: boolean
	group: Group | undefined
	groups: Group[]
	retrieveGroupMembers: (groupId: string) => Promise<GroupMember[] | void>
	groupMembers: GroupMember[],
	allGroupsMembers: {groupId: string, groupMembers: GroupMember[]}[]
	setGroup: Dispatch<SetStateAction<Group | undefined>>
	deleteGroup: (groupId: string) => Promise<void>
	selectedGroup: Group | undefined
	setSelectedGroup: Dispatch<SetStateAction<Group | undefined>>
	editGroup: (groupId: string, groupName: string, disabledAIModels?: AIModelID[]) => Promise<void>
	editGroupMembers: (groupId: string, userIds: string[]) => Promise<void>
	editingGroupMembers: boolean
}

const DEFAULT_GROUPS_CONTEXT: GroupsContextValue = {
	saveGroup: () => Promise.resolve({status: 201, groupId: ''}),
	loading: false,
	groups: [],
	group: {hashKey: '', name: '', disabledAIModels: []},
	retrieveGroupMembers: () => Promise.resolve([]),
	groupMembers: [],
	allGroupsMembers: [],
	setGroup: () => {},
	deleteGroup: () => Promise.resolve(),
	selectedGroup: {hashKey: '', name: '', disabledAIModels: []},
	setSelectedGroup: () => {},
	editGroup: () => Promise.resolve(),
	editGroupMembers: () => Promise.resolve(),
	editingGroupMembers: false
}

export const GroupsContext = createContext<GroupsContextValue>(DEFAULT_GROUPS_CONTEXT)

export const useGroupsContext = () => useContext(GroupsContext)

export const GroupsContextProvider: FC<PropsWithChildren> = ({children}) => {
	const value = useGroups()

	return <GroupsContext.Provider value={value}>
		{children}
	</GroupsContext.Provider>
}