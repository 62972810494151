import {RedirectToSignIn} from '@clerk/clerk-react'
import {FallbackProps} from 'react-error-boundary'
import {Link} from '@mui/material'
import {captureException} from '@sentry/core'
import {FC, useEffect} from 'react'
import './ErrorSection.scss'

type ErrorType = {
	message?: string
	errors?: Array<{message?: string}>
	clerkError?: string
	cause?: number
}

type Props = {
	error?: ErrorType | string
}

export const ErrorSection: FC<FallbackProps & Props> = ({
	error,
	resetErrorBoundary
}) => {

	const isStringError = error !== undefined && typeof error === 'string'

	const authError =  !isStringError ? error?.clerkError || error?.cause === 401 || error?.message === 'Request failed with status code 401' : undefined
	
	useEffect(() => {
		if (!authError) captureException(error)
	}, [error, authError])



	if (authError) {
		console.error('Auth error: ', {error})
		return <RedirectToSignIn/>
	}

	const message = isStringError ? error : error?.message || error?.errors?.[0]?.message

	return <>
		<p className='error'>Oops, something went wrong :(</p>
		<p className='errorMessage'>{message}</p>
		<Link className='errorLink' onClick={resetErrorBoundary}>Try again</Link>
	</>
}
