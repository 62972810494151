import {styled, tooltipClasses} from '@mui/material'
import Tooltip, {TooltipProps} from '@mui/material/Tooltip'
import React from 'react'

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#F9F9FB',
		color: 'rgba(0, 0, 0, 0.87)',
		boxShadow: theme.shadows[1],
		fontSize: 11,
		whiteSpace: 'pre-line',
		borderRadius: '7px',
		border: '1px solid rgba(68, 84, 111, 0.20)'
	},
}))