import {Box as MuiBox, BoxProps as MuiBoxProps, styled} from '@mui/material'
import {FC, PropsWithChildren} from 'react'
import {useAppMenuContext} from '../../context/AppMenuContext'

interface BoxProps extends MuiBoxProps {
    open?: boolean
}

const Box = styled(MuiBox, { shouldForwardProp: (prop) => prop !== 'open' })<BoxProps>(({ theme }) => ({
    transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'hidden',
    variants: [
        {
            props: ({ open }) => open,
            style: {
                transition: theme.transitions.create(['width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            }
        }
    ]
}))

export const AppMenuContent: FC<PropsWithChildren> = ({
    children
}) => {
    const { isOpen } = useAppMenuContext()
    
    return <Box open={isOpen}>
        {children}
    </Box>
}