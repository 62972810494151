import {useUserContext} from '../context/UserContext'
import {useCallback, useEffect, useState} from 'react'
import {getOrganizationByUser, updateOrganization} from '../service/persistenceService'
import {useFeedbackContext} from '../context/FeedbackContext'
import {Organization} from '../types/Organization'

export type OrganizationContextValue = {
	loading: boolean
	organization: Organization | undefined
	editOrganization: (organizationName: string, allowedDomains: string[]) => Promise<string | undefined>
}

export const useOrganization = (): OrganizationContextValue => {

	const {token} = useUserContext()
	const {showFeedback} = useFeedbackContext()

	const [loading, setLoading] = useState<boolean>(true)
	const [organization, setOrganization] = useState<Organization>()

	const getOrganization = useCallback(() => {
		setLoading(true)
		getOrganizationByUser(token)
			.then(setOrganization)
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to load data. Try again reloading the page', 'error')
				setOrganization(undefined)
			})
			.finally(() => setLoading(false))
	}, [token, showFeedback])

	const editOrganization = async (organizationName: string, allowedDomains: string[]): Promise<string | undefined> => {
		return updateOrganization(token, organizationName, allowedDomains)
			.then(setOrganization)
			.then(() => showFeedback('Organization updated', '', 'success'))
			.catch(error => {
				showFeedback('Error', error.cause === 403 ?
					'It seems like you don’t have permissions to access this page. Contact your admin to know more.' :
					'Something went wrong to update the organization. Try again reloading the page', 'error')
				return error.message
			})
	}


	useEffect(() => {
		getOrganization()
	}, [getOrganization])

	return {
		loading,
		organization,
		editOrganization
	}
}