export const hasId = <T>(id: T) => (item: { id: T }): boolean =>
    item.id === id

export const hasProp = <T, U>(propName: keyof U, propValue: T) => (item: U): boolean =>
    item[propName] === propValue

export const isContained = <T, K>(array: T[], idGetter: (item: T) => K) => (item: T): boolean =>
    array.some(arrayItem => idGetter(arrayItem) === idGetter(item))

export const not = <T extends (...args: never[]) => boolean>(callback: T) => (...args: Parameters<T>): boolean =>
    !callback(...args)

const areObjectsOrArrays = <T>(...args: T[]): boolean =>
    args.every(arg => typeof arg === 'object' && arg !== null)

const areSizesEqual = <T extends object>(...args: T[]): boolean => {
    const areLengthsEqual = args.map(arg => Object.keys(arg).length).every((length, _, array) => length === array[0])
    const areAllArrays = args.every(arg => Array.isArray(arg))
    const areAllNotArrays = args.every(arg => !Array.isArray(arg))

    return areLengthsEqual && (areAllArrays || areAllNotArrays)
}

export const isEqual = <T>(objA: T, objB: T): boolean => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const stack: [any, any][] = [[objA, objB]]
    let result = true

    while (stack.length) {
        const [a, b] = stack.pop()!

        // Object and array comparations
        if (areObjectsOrArrays(a,b)) {
            const keysA = Object.keys(a)
            const keysB = Object.keys(b)

            if (!areSizesEqual(a,b)) {
                result = false
                break
            }

            for (const key of keysA) {
                if (!keysB.includes(key)) {
                    result = false
                    break
                } else {
                    stack.push([a[key]!, b[key]!])
                }
            }
        // Primitive comparations
        } else if (a !== b) {
            result = false
            break
        }
    }

    return result
}

/**
 * Iterable callback which updates an array, only if there is an existing element, and there have been real changes on it
 * @param element the updated element.
 * @param isElementChanged callback function to determine if an element corresponds to another one, and if it has changed.
 * @returns new array with updated element, or unchanged given one.
*/
export const update = <T>(element: T, isElementChanged: (a:T) => (b:T) => boolean) => (array: T[]): T[] =>
    array.some(isElementChanged(element))
        ? array.map(e => isElementChanged(element)(e) ? element : e)
        : array


/**
 * Iterable callback which updates an array, only if there is an existing element, and there have been real changes on it
 * @param composeElement function that composes the element to be added, as a dependency of another element
 * @param isElementChanged callback function to determine if an element correspond to another one, and if it has changed
 * @returns new array with updated composed element, or unchanged given one
 */
export const updateWithComposition = <T>(composeElement: (a: T) => T, isElementChanged: (a: T) => (b: T) => boolean) => (array: T[]): T[] =>
    array.some(e => isElementChanged(composeElement(e))(e))
        ? array.map(e => isElementChanged(composeElement(e))(e) ? composeElement(e) : e)
        : array

/**
 * Iterable callback which removes element from an array, where there is a met condition, returning a new array
 * only if there were real changes. 
 * @param removeCondition the condition that an element needs to meet to be removed
 * @returns new array with removed elements, or unchanged given one
 */
export const remove = <T>(removeCondition: (a: T) => boolean) => (array: T[]): T[] =>
    array.some(removeCondition)
        ? array.filter(not(removeCondition))
        : array

/**
 * Iterable callback which add an element to an array, only if that element does not exist by comparation given a matching function
 * @param element the element to be added
 * @param areElementsMatch the comparation function that checks if 2 elements are a match
 * @returns new array with added element, or unchanged given one
 */
export const add = <T>(element: T, areElementsMatch: (a: T) => (b: T) => boolean) => (array: T[]): T[] =>
    array.some(areElementsMatch(element))
        ? array
        : [...array, element]

/**
 * Iterable callback which given an element, updates or adds an array with it. It will only update the array when
 * element has real changes on it.
 * @param element the updated or added element.
 * @param isElement callback function to determine if an element corresponds to another.
 * @param isElementChanged callback function to determine if an element corresponds to another one, and if it has changed.
 * @returns new array with added or updated element, or unchanged given one.
 */
export const put = <T>(element: T, isElement: (a: T) => (b: T) => boolean, isElementChanged: (a: T) => (b: T) => boolean) => (array: T[]): T[] =>
    array.some(isElement(element))
        ? array.some(isElementChanged(element))
            ? array.map(e => isElementChanged(element)(e) ? element : e)
            : array
        : [...array, element]

/**
 * Iterable callback which given an array of elements, updates or adds an array with them. It will only update the array when
 * any of the elements have real changes on it.
 * @param elements the updated or added elements
 * @param isElement callback function to determine if an element corresponds to another.
 * @param isElementChanged allback function to determine if an element corresponds to another one, and if it has changed.
 * @returns new array with added or updated elements, or unchanged given one.
 */
export const putArray = <T>(elements: T[], isElement: (a: T) => (b: T) => boolean, isElementChanged: (a: T) => (b: T) => boolean) => (array: T[]): T[] =>
    elements.reduce((prev, curr) => put(curr, isElement, isElementChanged)(prev), array)

/**
 * Extracts unique values of a specified property from an array of objects, filtering out undefined values.
 *
 * @template T The type of objects in the input array.
 * @template K The type of the property key (must be a key of T).
 *
 * @param {T[]} elements - The input elements of objects.
 * @param {K} key - The key of the property to extract unique values from.
 *
 * @returns An array of unique, non-null values of the specified property.
 */
export const getUniqueValues = <T, K extends keyof T>(elements: T[], key: K): Array<NonNullable<T[K]> extends any[] ? NonNullable<T[K]>[number] : NonNullable<T[K]>> =>
    [...new Set(
        elements.flatMap(item => {
            const value = item[key]
            if (Array.isArray(value)) return value
            else if (value != null) return [value]
            else return []
        })
    )] as Array<NonNullable<T[K]> extends any[] ? NonNullable<T[K]>[number] : NonNullable<T[K]>>
